import React, { useState } from 'react';

import './TagsFilter.scss';
import { TagsField } from 'v5/byModel/tags/TagsField';
import { myServices } from 'v5/services/services.connected.app';
import arrayMove from 'array-move';
import { Checkbox } from '../../../../../../v5/design-sytem/Checkbox.js';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../../../../../v5/design-sytem/Select.js';

const TagsFilter = ({ param, index, filter, filterChanged, removeFilter }) => {
  const [selectorVisible, setSelectorVisible] = useState(
    !!(filter?.any || filter?.match)
  );

  const handleRangeClicked = () => {
    setSelectorVisible(!selectorVisible);
    if (selectorVisible) {
      removeFilter();
    }
  };

  const tagNames = filter?.any ?? filter?.match ?? [];
  const condition = filter?.match ? 'match' : 'any';

  function applyTags(tags) {
    if (tags.length === 0) {
      removeFilter();
      return;
    }
    filterChanged({
      ...param,
      condition,
      value: tags
    });
  }

  return (
    <div className="ResourceFilter">
      <div
        key={index}
        className="DropdownButton-option"
        onClick={handleRangeClicked}
      >
        <Checkbox
          variant="primaryAlt"
          onClick={handleRangeClicked}
          checked={selectorVisible}
        />
        <span className="DropdownButton-option-label">{param.label}</span>
      </div>
      {selectorVisible && (
        <div className="TagsFilter-content tailwind-root">
          <TagsField
            createButtonOnNewLine
            draggable={false}
            className="w-80"
            allowCreate={false}
            services={myServices}
            tagType="shortlist"
            mode="edit"
            data={filter?.any ?? filter?.match ?? []}
            onAdd={newTag => {
              applyTags([...tagNames, newTag.tag]);
            }}
            onRemove={({ tag }) => {
              applyTags(tagNames.filter(t => t !== tag));
            }}
            onMove={(from, to) => {
              const tags = arrayMove(tagNames, from, to);
              applyTags(tags);
            }}
          />
          <Select
            value={condition}
            onValueChange={value =>
              filterChanged({
                ...param,
                condition: value,
                value: [...tagNames] // when we don't recreate the array, changes are not detected :(
              })
            }
          >
            <SelectTrigger className="mt-4" size="sm">
              <SelectValue />
            </SelectTrigger>
            <SelectContent align="start">
              <SelectItem value="match">Match all</SelectItem>
              <SelectItem value="any">Match any</SelectItem>
            </SelectContent>
          </Select>
        </div>
      )}
    </div>
  );
};

export default TagsFilter;
