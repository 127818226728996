import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment-timezone';
import './DateRangeInput.scss';

import { SingleDateInput } from 'v1/components/shared';
import { rd } from '@passionware/monads';
import { getV5Props } from '../../text/GetV5Props.js';
import { cn } from 'v5/platform/dom/cn';
import { inputVariants } from 'v5/design-sytem/Input.js';
import { DatePicker } from 'v5/design-sytem/DatePicker.js';
import { InlineField } from 'v5/platform/react/InlineField.js';
import { format, isSameMinute } from 'date-fns';
import { myServices } from '../../../../../../v5/services/services.connected.app.js';
import { ArrowRight } from 'lucide-react';

const DateRangeInput = ({
  className,
  appearance,
  size,
  hideSpacer,
  inputClassname,
  labels,
  disabled,
  dateFormat,
  startDate,
  endDate,
  children,
  onChange,
  onValueChange,
  minDate,
  maxDate,
  placeholder,
  placeholderStart,
  placeholderEnd,
  changeOnDebounce = false,
  ...rest
}) => {
  const onInputChange = (type, value) => onChange?.(type, value.date);
  const setMinimumDate = moment(startDate).toDate();

  const finalInputClassName = cn(
    inputVariants(getV5Props({ size, appearance })),
    {
      XS: 'w-[100px]',
      S: 'w-[100px]',
      M: 'w-[100px]',
      L: 'w-[110px]',
      XL: 'w-[115px]'
    }[size],
    inputClassname
  );
  function preprocessDate(date) {
    if (!date) {
      return null;
    }
    return typeof date === 'string' ? new Date(date) : date;
  }
  function postprocessDate(date) {
    if (!date) {
      return null;
    }
    return typeof date === 'string' ? date : format(date, 'yyyy-MM-dd');
  }

  const feature = myServices.featureService.useFeature('date-picker');

  const version = rd.getOrElse(feature, 'old');

  return (
    <div className="tailwind-root">
      {version === 'new' && (
        <div className="">
          {(!!labels.start || !!labels.end) && (
            <label className="form-label flex! flex-row! items-center gap-2">
              {labels.start ? <div>{labels.start}</div> : null}
              {labels.start && labels.end && <ArrowRight className="size-3" />}
              {labels.end ? <div>{labels.end}</div> : null}
            </label>
          )}
          <InlineField
            tmp_canAcceptChange={value => {
              return value.from && value.to;
            }}
            changeOnDebounce={changeOnDebounce}
            value={
              startDate || endDate
                ? {
                    from: preprocessDate(startDate),
                    to: preprocessDate(endDate)
                  }
                : null
            }
            onChange={async (range, source) => {
              const startDateBefore = preprocessDate(startDate);
              const endDateBefore = preprocessDate(endDate);

              if (!isSameMinute(range.from, startDateBefore)) {
                onInputChange('start', { date: postprocessDate(range.from) });
              }

              if (!isSameMinute(range.to, endDateBefore)) {
                onInputChange('end', { date: postprocessDate(range.to) });
              }

              // v5 api
              onValueChange?.({
                from: postprocessDate(range.from),
                to: postprocessDate(range.to)
              });

              // todo - fix usages to accept both dates at once to avoid race conditions
            }}
          >
            <DatePicker
              startLabel={labels ? labels.start : null}
              endLabel={labels ? labels.end : null}
              mode="range"
              {...getV5Props({ size, appearance })}
            />
          </InlineField>
        </div>
      )}

      {version === 'old' && (
        <div
          className={classnames([
            'flex flex-row items-center [&_label]:text-fg-muted!',
            className
          ])}
        >
          <SingleDateInput
            label={labels ? labels.start : null}
            size={size}
            dateFormat={dateFormat}
            date={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={value => {
              onInputChange('start', value);
              onValueChange?.({
                from: postprocessDate(value),
                to: postprocessDate(preprocessDate(endDate))
              });
            }}
            selectsStart
            disabled={disabled}
            className={cn(
              finalInputClassName,
              size === 'XS' ? 'rounded-r-none -mr-px' : ''
            )}
            minDate={minDate}
            maxDate={maxDate}
            placeholder={placeholderStart || placeholder}
            {...rest}
          >
            {children}
          </SingleDateInput>
          {size !== 'XS' && (
            <div
              className={cn(
                'h-px bg-border-default self-end',
                {
                  S: 'mb-4 mx-2 w-6',
                  M: 'mb-6 mx-2 w-8',
                  L: 'mb-7.5 mx-4 w-10',
                  XL: 'mb-8.5 mx-4 w-10'
                }[size]
              )}
            ></div>
          )}
          <SingleDateInput
            label={labels ? labels.end : null}
            size={size}
            dateFormat={dateFormat}
            date={endDate}
            startDate={startDate}
            endDate={endDate}
            disabled={disabled}
            onChange={value => {
              onInputChange('end', value);
              onValueChange?.({
                from: postprocessDate(preprocessDate(startDate)),
                to: postprocessDate(value)
              });
            }}
            selectsEnd
            className={cn(
              finalInputClassName,
              size === 'XS' ? 'rounded-l-none' : ''
            )}
            minDate={setMinimumDate || minDate}
            maxDate={maxDate}
            placeholder={placeholderEnd || placeholder}
            {...rest}
          >
            {children}
          </SingleDateInput>
        </div>
      )}
    </div>
  );
};

DateRangeInput.propTypes = {
  className: PropTypes.string,
  inputClassname: PropTypes.string,
  appearance: PropTypes.oneOf(['outline', 'underline', 'silent']),
  size: PropTypes.oneOf(['XS', 'S', 'M']),
  labels: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string
  }),
  disabled: PropTypes.bool,
  showTimeSelect: PropTypes.bool,
  dateFormat: PropTypes.string.isRequired, // E.g. 'MM-dd-yyyy'
  startDate: PropTypes.any, // TODO: type
  endDate: PropTypes.any, // TODO: type
  children: PropTypes.any,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func
};

DateRangeInput.defaultProps = {
  appearance: 'outline',
  size: 'M',
  dateFormat: 'MM-dd-yyyy'
};

export default DateRangeInput;

// todo nagrac filmmk pokaujacy shortlist filter resource type i status selector
// dodac stories do comboboxa / command
// nagrac tez storybook ze wszystkimi elementami stworzonymi do tej pory (shadcn + custom wariand work)
