'use client';

import { type DialogProps } from '@radix-ui/react-dialog';
import { cva, VariantProps } from 'class-variance-authority';
import { Command as CommandPrimitive } from 'cmdk';
import { Search, X } from 'lucide-react';
import * as React from 'react';
import { ReactNode } from 'react';
import { cn } from 'v5/platform/dom/cn';
import { Dialog, DialogContent } from './Dialog';
import { Skeleton } from './Skeleton';

const commandVariants = cva(
  'flex h-full w-full flex-col overflow-hidden rounded-md bg-bg-default text-fg-default',
  {
    variants: {
      size: {
        xs: [
          '[&_[cmdk-group]]:p-1',
          '[&_[cmdk-item]]:text-base',
          '[&_[cmdk-input]]:text-base [&_[cmdk-input-wrapper]]:pr-1'
        ],
        sm: ['[&_[cmdk-group]]:p-2', '[&_[cmdk-item]]:text-lg']
      }
    },
    defaultVariants: {
      size: 'sm'
    }
  }
);

const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive> &
    VariantProps<typeof commandVariants>
>(({ className, size, ...props }, ref) => (
  <CommandPrimitive
    ref={ref}
    className={cn(commandVariants({ size }), className)}
    {...props}
  />
));
Command.displayName = CommandPrimitive.displayName;

const CommandDialog = ({ children, ...props }: DialogProps) => {
  return (
    <Dialog {...props}>
      <DialogContent
        closeButton={
          <button className="border-none bg-transparent absolute right-2 top-2 rounded-sm opacity-70 ring-offset-bg-default transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-bg-emphasis data-[state=open]:text-fg-muted">
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </button>
        }
        className="min-w-[500px] overflow-hidden p-0 shadow-md top-[30%] translate-y-[0]"
      >
        <Command
          shouldFilter={false}
          className="[&_[cmdk-group-heading]]:px-3 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-fg-muted [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-5 [&_[cmdk-input-wrapper]_svg]:w-5 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:h-5 [&_[cmdk-item]_svg]:w-5"
        >
          {children}
        </Command>
      </DialogContent>
    </Dialog>
  );
};

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> & {
    extra?: ReactNode;
  }
>(({ className, extra, ...props }, ref) => (
  <div
    cmdk-input-wrapper=""
    className={cn(
      'flex items-center border-b border-border-default px-4',
      className
    )}
  >
    <Search className="mr-3 h-5 w-5" />
    <CommandPrimitive.Input
      ref={ref}
      className={cn(
        'border-none flex h-11 w-full rounded-md bg-transparent py-3 text-lg outline-none focus:ring-0 placeholder:text-fg-muted disabled:cursor-not-allowed disabled:opacity-50'
      )}
      {...props}
    />
    {extra}
  </div>
));

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn('max-h-[400px] overflow-y-auto overflow-x-hidden', className)}
    {...props}
  />
));

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>(({ className, ...rest }, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className={cn('py-6 text-center', className)}
    {...rest}
  />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      'overflow-hidden text-fg-default [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-base [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-fg-muted',
      className
    )}
    {...props}
  />
));

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 h-px bg-border-default', className)}
    {...props}
  />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      'flex cursor-default gap-2 select-none items-center rounded-sm px-2 py-1.5 text-lg outline-none data-[disabled=true]:pointer-events-none data-[selected=true]:bg-bg-subtle data-[selected=true]:text-accent-foreground data-[disabled=true]:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0',
      className
    )}
    {...props}
  />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

const CommandShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => (
  <span
    className={cn(
      'ml-auto text-base tracking-widest text-muted-foreground',
      className
    )}
    {...props}
  />
);
CommandShortcut.displayName = 'CommandShortcut';

const CommandLoading = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Loading>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Loading>
>((props, ref) => (
  <CommandPrimitive.Loading ref={ref} {...props}>
    <div className="p-1 space-y-1 *:w-full *:h-8 *:rounded-sm">
      <Skeleton />
      <Skeleton />
      <Skeleton />
      <Skeleton />
    </div>
  </CommandPrimitive.Loading>
));
CommandLoading.displayName = CommandPrimitive.Loading.displayName;

export {
  Command,
  CommandDialog,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandShortcut,
  CommandSeparator,
  CommandLoading
};
