import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment-timezone';
import './EventDateRangeInput.scss';

import { DateRangeInput, Checkbox } from 'v1/components/shared';
import EventHoursInput, {
  TypeEventHours
} from '../EventHoursInput/EventHoursInput';

const DEFAULT_ESTIMATED_DAILY_HOURS = 8;
const DEFAULT_ESTIMATED_DAILY_MINUTES = 0;
const DEFAULT_INCLUSIVE_START_TIME = 9;
const DEFAULT_INCLUSIVE_END_TIME = 17;
const DEFAULT_ESTIMATED_START_TIME = '09:00:00';
const DEFAULT_ESTIMATED_END_TIME = '17:00:00';

const EventDateRangeInput = ({
  className,
  appearance,
  size,
  dateFormat,
  isReadOnly,
  event,
  onChange,
  onRemove,
  labelStart,
  labelEnd,
  limits,
  filterDate,
  highlightDates
}) => {
  const [key, setKey] = useState(1);
  // NOTE: keys are a workaround for toggling showTimeSelect in react-datepicker
  // react-datepicker doesn't re-render the component with times included on change, so we have to force it
  const handleIncludeHours = (field, value, previous) => {
    setKey(key === 1 ? 2 : 1);
    handleChange(field, value, previous);
  };

  const handleChange = (field, value, previous) => {
    const timezone = moment().tz();
    let updatedDates = {};

    if (field === 'date_type') {
      switch (value) {
        case 'INCLUSIVE_HOURS':
          updatedDates = {
            start_timestamp: moment
              .tz(event.start_date, timezone)
              .hours(DEFAULT_INCLUSIVE_START_TIME)
              .format(),
            end_timestamp: moment
              .tz(event.end_date, timezone)
              .hours(DEFAULT_INCLUSIVE_END_TIME)
              .format()
          };
          break;
        case 'RECURRING_HOURS':
          updatedDates = {
            estimated_daily_hours: DEFAULT_ESTIMATED_DAILY_HOURS,
            estimated_daily_minutes: DEFAULT_ESTIMATED_DAILY_MINUTES
          };
          break;
        case 'RECURRING_SPECIFIC_HOURS':
          updatedDates = {
            estimated_start_time: DEFAULT_ESTIMATED_START_TIME,
            estimated_end_time: DEFAULT_ESTIMATED_END_TIME
          };
          break;
        default:
          if (previous === 'INCLUSIVE_HOURS') {
            updatedDates = {
              start_date: moment
                .tz(event.start_timestamp, timezone)
                .format('YYYY-MM-DD'),
              end_date: moment
                .tz(event.end_timestamp, timezone)
                .format('YYYY-MM-DD')
            };
          }
      }
    }

    onChange({ ...event, ...updatedDates, [field]: value });
  };

  const handleDateRangeChange = (date_type, date) => {
    date_type === 'start'
      ? handleChange(
          event.date_type === 'INCLUSIVE_HOURS'
            ? 'start_timestamp'
            : 'start_date',
          date
        )
      : handleChange(
          event.date_type === 'INCLUSIVE_HOURS' ? 'end_timestamp' : 'end_date',
          date
        );
  };

  return (
    <div
      className={classnames([
        'EventDateRangeInput',
        {
          [`EventDateRangeInput-${size}`]: size
        },
        className
      ])}
    >
      <div className={classnames('EventDateRangeInput-inner items-start')}>
        <DateRangeInput
          key={key}
          size={size}
          appearance={appearance}
          labelClassName="form-label"
          labels={{ start: labelStart, end: labelEnd }}
          dateFormat={
            event.date_type === 'INCLUSIVE_HOURS'
              ? 'eee dd MMM h:mmaa'
              : dateFormat
          }
          disabled={isReadOnly}
          showTimeSelect={event.date_type === 'INCLUSIVE_HOURS'}
          startDate={
            event.date_type === 'INCLUSIVE_HOURS'
              ? event.start_timestamp
              : event.start_date
          }
          endDate={
            event.date_type === 'INCLUSIVE_HOURS'
              ? event.end_timestamp
              : event.end_date
          }
          minDate={limits.minDate}
          maxDate={limits.maxDate}
          filterDate={filterDate}
          onChange={handleDateRangeChange}
          highlightDates={highlightDates}
        >
          <Checkbox
            name="include_hours"
            label="Include hours"
            checked={event.date_type === 'INCLUSIVE_HOURS' || false}
            onChange={({ target }) =>
              handleIncludeHours(
                'date_type',
                target.checked ? 'INCLUSIVE_HOURS' : 'RECURRING_DEFAULT',
                event.date_type
              )
            }
          />
        </DateRangeInput>

        {event.date_type !== 'INCLUSIVE_HOURS' && (
          <EventHoursInput
            size={size}
            appearance={appearance}
            date_type={event.date_type}
            estimated_daily_hours={event.estimated_daily_hours}
            estimated_daily_minutes={event.estimated_daily_minutes}
            estimated_start_time={event.estimated_start_time}
            estimated_end_time={event.estimated_end_time}
            disabled={isReadOnly}
            onChange={handleChange}
          />
        )}

        {onRemove && !isReadOnly && (
          <span className="EventDateRangeInput-remove" onClick={onRemove}>
            <img src="/images/icon_delete.svg" alt="" />
            {/* TODO: icon */}
          </span>
        )}
      </div>

      {(!isReadOnly || event.include_weekends) && (
        <Checkbox
          className={classnames([
            'EventDateRangeInput-weekends',
            {
              'EventDateRangeInput-weekends-show':
                event.include_weekends && event.date_type !== 'INCLUSIVE_HOURS'
            },
            {
              'EventDateRangeInput-weekends-hide':
                event.date_type === 'INCLUSIVE_HOURS' || isReadOnly
            }
          ])}
          name="include_weekends"
          label="Include weekends"
          size="S"
          checked={event.include_weekends || false}
          onChange={({ target }) =>
            handleChange('include_weekends', target.checked)
          }
        />
      )}
    </div>
  );
};

export const TypeEvent = PropTypes.shape({
  start_date: PropTypes.any, // '2019-09-23'
  end_date: PropTypes.any, // '2019-09-25'
  start_timestamp: PropTypes.any, // '2019-09-04T00:00:00.000Z' / Only for INCLUSIVE_HOURS
  end_timestamp: PropTypes.any, // '2019-09-06T22:30:00.000Z' / Only for INCLUSIVE_HOURS
  include_weekends: PropTypes.bool, // Only for RECURRING_DEFAULT, RECURRING_HOURS, RECURRING_SPECIFIC_HOURS
  ...TypeEventHours
});

EventDateRangeInput.propTypes = {
  className: PropTypes.string,
  dateFormat: PropTypes.string, // E.g. 'dd-MM-yyyy'
  size: PropTypes.oneOf(['XS', 'S', 'M']),
  isReadOnly: PropTypes.bool,
  event: TypeEvent,
  onChange: PropTypes.func,
  onRemove: PropTypes.func,
  limits: PropTypes.shape({
    minDate: PropTypes.object,
    maxDate: PropTypes.object
  }),
  highlightDates: PropTypes.array
};

EventDateRangeInput.defaultProps = {
  appearance: 'silent',
  dateFormat: 'eee dd MMM',
  event: {
    date_type: 'RECURRING_DEFAULT'
  },
  limits: {},
  filterDate: null
};

export default EventDateRangeInput;
