import { maybe } from '@passionware/monads';
import { cva, VariantProps } from 'class-variance-authority';
import { ComponentProps, ReactNode } from 'react';
import { cn } from '../platform/dom/cn';

export interface InputOverlayProps
  extends ComponentProps<'div'>,
    VariantProps<typeof overlayVariants> {
  label: ReactNode;
  tooltip?: string;
  action?: string | (() => void) | null;
}

const overlayVariants = cva(['truncate hover:underline'], {
  variants: {
    size: {
      xs: 'text-base pl-3 font-semibold',
      sm: 'text-base pl-3 font-semibold',
      md: 'text-base pl-3 font-semibold',
      lg: 'text-lg pl-3 font-semibold',
      xl: 'text-xl pl-3 font-semibold'
    },
    variant: {
      regular: '',
      inline: 'pl-0',
      plain: 'pl-0'
    }
  },
  defaultVariants: {
    size: 'md',
    variant: 'regular'
  }
});

export const InputOverlay = ({
  label,
  action,
  tooltip,
  className,
  children,
  size,
  variant,
  ...rest
}: InputOverlayProps) => {
  return (
    <div
      className={cn(
        'w-full relative group [&_input]:transition-none not-focus-within:[&_input]:text-transparent',
        '[[data-bar]>&:not(:first-child)&>*]:rounded-l-none',
        '[[data-bar]>&:not(:last-child)&>*]:rounded-r-none',
        className
      )}
      {...rest}
    >
      <div className="absolute flex group-focus-within:hidden top-1/2 left-0 max-w-full transform -translate-y-1/2">
        <a
          tabIndex={-1}
          className={cn(overlayVariants({ size, variant }))}
          onClick={() => typeof action === 'function' && action()}
          href={
            typeof action !== 'function'
              ? maybe.getOrUndefined(action)
              : undefined
          }
          target={typeof action !== 'function' ? '_blank' : undefined}
          rel={typeof action !== 'function' ? 'noopener noreferrer' : undefined}
          title={tooltip}
          data-tip={tooltip}
        >
          {label}
        </a>
      </div>
      {children}
    </div>
  );
};
