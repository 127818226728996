import React from 'react';
import PropTypes from 'prop-types';

import { Status } from 'v1/components/shared';
import get from 'lodash/get';

import './StatusSelector.scss';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue
} from 'v5/design-sytem/Select.js';
import { cn } from 'v5/platform/dom/cn';
import { getV5Props } from '../../../byType/text/GetV5Props.js';

const StatusSelector = ({
  className,
  selectedStatus,
  onSelect,
  statusGroups,
  appearance = 'default',
  size
}) => {
  return (
    <div className="contents tailwind-root">
      <Select
        value={selectedStatus.id}
        onValueChange={id =>
          onSelect(
            statusGroups
              .flatMap(group => group.statuses)
              .find(status => status.id === id)
          )
        }
      >
        <SelectTrigger
          aria-label="Select status"
          {...getV5Props({ appearance, size })}
          className={cn('[.value-container]:w-full', className)}
        >
          <SelectValue placeholder="Select status" />
        </SelectTrigger>
        <SelectContent className="max-h-(--radix-select-content-available-height)">
          {statusGroups.map((statusGroup, i) => {
            return (
              <SelectGroup key={`${statusGroup.type}-${i}`}>
                <SelectLabel>{statusGroup.label}</SelectLabel>
                {statusGroup.statuses.map(status => (
                  <SelectItem
                    renderCheckmark={false}
                    size="lg"
                    key={status.id}
                    value={status.id}
                    className="*:flex-1"
                    disabled={statusGroup.type !== 'USER'}
                  >
                    <div className="w-full flex items-center justify-between gap-2 [[data-item-text]_&]:gap-4">
                      <div className="mr-auto">
                        <div className="[[data-item-text]_&]:hidden text-sm text-fg-muted font-bold">
                          STATUS
                        </div>
                        <div className="group-[.is-trigger]:hidden text-sm text-fg-muted font-bold">
                          {statusGroup.descriptions ? (
                            <span className="text-11-700-eggplant">
                              {get(
                                statusGroup.descriptions,
                                status.status_type
                              )}
                            </span>
                          ) : status.id === selectedStatus.id ? (
                            'SELECTED'
                          ) : (
                            'SET TO'
                          )}
                        </div>
                      </div>
                      <Status statusId={status.id} />
                    </div>
                  </SelectItem>
                ))}
              </SelectGroup>
            );
          })}
        </SelectContent>
      </Select>
    </div>
  );
};

StatusSelector.propTypes = {
  className: PropTypes.string,
  statusGroups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.oneOf(['USER', 'AUTOMATED']).isRequired, // 'USER': Clickable statuses that a user can set. 'AUTOMATED': Statuses automatically set by the system.
      statuses: PropTypes.array.isRequired, // Filtered statuses associated to that group
      description: PropTypes.object // If descriptions exists it replaces the label alongside each status
    })
  ).isRequired,
  selectedStatus: PropTypes.object, // [Status] - the selected status
  onSelect: PropTypes.func.isRequired,
  appearance: PropTypes.oneOf(['silent', 'default']),
  size: PropTypes.oneOf(['S', 'M', 'L'])
};

StatusSelector.defaultProps = {
  selectedStatus: {},
  statuses: [],
  size: 'M'
};

export default StatusSelector;
