import { ComponentProps } from 'react';
import { cn } from '../platform/dom/cn';

export function Kbd({ className, ...props }: ComponentProps<'kbd'>) {
  return (
    <kbd
      className={cn(
        'flex gap-1 bg-black/20 py-1.5 px-2 items-center rounded uppercase',
        className
      )}
      {...props}
    />
  );
}
