import { forcedFeatureFlags } from '../../../features';
import { myLocalStorageFeatureFlagSource } from '../FeatureService/_private/localStorageFeatureFlagSource';
import { myServerFeatureFlagSource } from '../FeatureService/_private/serverFeatureFlagSource.connected';
import { myToastService } from '../ToastService/ToastService.connected';
import { createFeatureManagementService } from './FeatureManagementService.impl';

export const myFeatureManagementService = createFeatureManagementService({
  services: { toastService: myToastService },
  serverSource: myServerFeatureFlagSource,
  localStorageSource: myLocalStorageFeatureFlagSource,
  enforcedVersions: forcedFeatureFlags
});
