import { featureFlags, forcedFeatureFlags } from '../../../features';

import { createFeatureSourceChain } from '../../../platform/feature-management/sources/chain';
import { createEnforcedFeatureFlagSource } from '../../../platform/feature-management/sources/enforced';
import { withDefaultValues } from '../../../platform/feature-management/sources/withDefault';
import { myLocalStorageFeatureFlagSource } from './_private/localStorageFeatureFlagSource';
import { myServerFeatureFlagSource } from './_private/serverFeatureFlagSource.connected';
import { createFeatureService } from './FeatureService.impl';

export const myFeatureService = createFeatureService(
  withDefaultValues(featureFlags)(
    createFeatureSourceChain([
      myServerFeatureFlagSource,
      /* domainBasedSource, // just an example of how we can extend this system */
      myLocalStorageFeatureFlagSource,
      createEnforcedFeatureFlagSource(forcedFeatureFlags)
    ])
  )
);
