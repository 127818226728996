import { injectConfig } from '@passionware/platform-react';
import { ResourceType } from '__types__/index';
import { chain } from 'lodash';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from 'store/reducer';
import { getResourceTypes } from 'store/v1/contacts/contacts.selectors';
import { getGroups } from 'store/v1/groups/groups.actions';
import {
  ResourceFilters,
  ResourceFiltersConfig
} from 'v1/components/shared/search/ResourceFilters/ResourceFilters';
import { orderReducer } from 'v1/helpers/misc';
import { TodoType } from 'v4/entities/common/TodoType';

export const resourceFiltersConfig: ResourceFiltersConfig = {
  useResourceTypes: resourceTypesModel => {
    const resourceTypesList = useSelector(state =>
      // @ts-expect-error todo migrate getResourceTypes to TS
      getResourceTypes(state, { model: resourceTypesModel || null })
    );
    return resourceTypesList;
  },
  useResourceType: id => {
    const resourceTypes = useSelector(
      (state: RootStore) => state.resource_types
    ) as TodoType;
    return resourceTypes.data[id];
  }
};

export default injectConfig(ResourceFilters).static(resourceFiltersConfig);
