import { Maybe, maybe } from '@passionware/monads';
import {
  ArrowRight,
  Calendar as CalendarIcon,
  CornerDownLeft
} from 'lucide-react';
import {
  ComponentPropsWithRef,
  FocusEvent,
  KeyboardEvent,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { DateRange } from 'react-day-picker';
import { cn } from 'v5/platform/dom/cn';
import { Overwrite } from 'v5/platform/typescript';
import { DateInput, DateInputProps } from './_common/DateInput';
import { Button } from './Button';
import { Calendar } from './Calendar';
import {
  InputGroup,
  InputGroupButton,
  InputGroupSize,
  InputGroupSlot,
  InputGroupVariant
} from './InputGroup';
import { Kbd } from './Kbd';
import { Popover, PopoverContent, PopoverTrigger } from './Popover';

export type DatePickerProps = Overwrite<
  ComponentPropsWithRef<'div'>,
  {
    variant?: InputGroupVariant;
    size?: InputGroupSize;
    leftSlot?: ReactNode;
    placeholder?: string;
    granularity?: DateInputProps['granularity'];
    onBlur?: (e: FocusEvent) => void;
    onFocus?: (e: FocusEvent) => void;
  } & (
    | {
        mode: 'range';
        value: Maybe<DateRange>;
        onChange: (value: Maybe<DateRange>) => void;
      }
    | {
        mode: 'single';
        value: Maybe<Date>;
        onChange: (value: Maybe<Date>) => void;
      }
  )
>;

export function DatePicker({
  className,
  placeholder,
  leftSlot,
  value,
  onChange,
  variant,
  size,
  mode,
  granularity = 'day',
  ...rest
}: DatePickerProps) {
  const [open, setOpen] = useState(false);
  const [internalValue, setInternalValue] = useState(value);
  useLayoutEffect(() => {
    setInternalValue(value);
  }, [value]);

  const popoverContentRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLInputElement>(null);
  const focusedBeforeOpenRef = useRef<HTMLElement | null>(null);
  function handleOpen() {
    const focuedElement = document.activeElement;
    if (focuedElement instanceof HTMLElement) {
      if (triggerRef.current?.contains(focuedElement)) {
        focusedBeforeOpenRef.current = focuedElement;
      } else {
        focusedBeforeOpenRef.current = null;
      }
    }
    setOpen(true);
  }

  function handleClose() {
    const element = triggerRef.current?.firstElementChild;
    if (element instanceof HTMLElement) {
      setTimeout(() => {
        setOpen(false);

        if (popoverContentRef.current?.contains(document.activeElement)) {
          // we focus back only if the focus is still within the popover
          (focusedBeforeOpenRef.current ?? element).focus();
        }
      }, 150); // allow selection transitions to finish to avoid flicker
    }
  }

  function handleValueChange(value: Maybe<Date | DateRange>) {
    setInternalValue(value);
    onChange(value as any);
  }

  const inputProps = {
    granularity,
    size,
    className: 'flex-1 h-fit',
    'data-element': 'input-group-input',
    onKeyDown: (e: KeyboardEvent) => {
      if (e.code === 'Backspace' && value) {
        e.preventDefault();
        setInternalValue(null);
        handleClose();
        onChange(null);
      }
      if (e.code === 'Space') {
        // open the calendar
        handleOpen();
        e.preventDefault();
      }
    }
  } as const;

  return (
    <Popover
      open={open}
      onOpenChange={o => {
        if (!o) {
          handleClose();
        } else {
          setInternalValue(value);
        }
      }}
    >
      <PopoverTrigger asChild>
        <InputGroup
          variant={variant}
          size={size}
          className={cn(
            'overflow-hidden justify-between w-fit', // for the border-radius to work
            className
          )}
          ref={triggerRef}
          {...rest}
          onCopy={e => {
            // set clipboard with well formatted date
            const valueToCopy =
              mode === 'single'
                ? value?.toLocaleDateString()
                : `${value?.from?.toLocaleDateString()} - ${value?.to?.toLocaleDateString()}`;
            if (valueToCopy) {
              e.clipboardData.clearData();
              e.clipboardData.setData('text/plain', valueToCopy);
              e.preventDefault();
              e.stopPropagation();
            }
          }}
        >
          {leftSlot}
          <>
            {mode === 'single' ? (
              <DateInput
                value={value}
                {...inputProps}
                onValueChange={value => {
                  handleValueChange(value);
                }}
                placeholder={placeholder}
              />
            ) : (
              <>
                <DateInput
                  {...inputProps}
                  value={value?.from}
                  onValueChange={fromValue => {
                    handleValueChange({
                      ...value,
                      from: maybe.getOrUndefined(fromValue)
                    });
                  }}
                  placeholder={placeholder}
                />
                <InputGroupSlot>
                  <ArrowRight className="text-fg-muted" />
                </InputGroupSlot>
                <DateInput
                  {...inputProps}
                  value={value?.to}
                  onValueChange={toDate => {
                    handleValueChange({
                      from: maybe.getOrElse(
                        value?.from,
                        maybe.getOrUndefined(toDate)
                      ),
                      to: maybe.getOrUndefined(toDate)
                    });
                  }}
                  placeholder={placeholder}
                />
              </>
            )}
          </>
          <InputGroupButton
            aria-expanded={open}
            onClick={handleOpen}
            tabIndex={-1} // use "space" to open the calendar
          >
            <CalendarIcon />
          </InputGroupButton>
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent
        align="end"
        className="p-0 max-w-fit min-w-min"
        ref={popoverContentRef}
      >
        <Calendar
          required={false}
          autoFocus
          selected={maybe.getOrUndefined(internalValue) as DateRange}
          onSelect={newValue => {
            // todo: do not reset time if only date is selected
            if (mode === 'range') {
              setInternalValue(newValue);
            } else {
              setInternalValue(newValue);
              onChange(newValue);
              handleClose();
            }
          }}
          mode={mode as 'range'}
          defaultMonth={
            internalValue instanceof Date ? internalValue : internalValue?.from
          }
          numberOfMonths={mode === 'range' ? 2 : 1}
          onDayKeyDown={(day, modifiers, e) => {
            if (mode === 'range') {
              if (e.key === 'Enter') {
                handleClose();
                onChange(internalValue as DateRange);
                e.preventDefault();
              }
            }
          }}
        />
        {mode === 'range' && (
          <div className="p-2 flex flex-row justify-end">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => {
                handleClose();
                onChange(internalValue as DateRange);
              }}
              className="self-end"
            >
              <Kbd className="bg-bg-subtle">
                <CornerDownLeft />
              </Kbd>
              Apply
            </Button>
          </div>
        )}
      </PopoverContent>
    </Popover>
  );
}
