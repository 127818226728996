import { cva, VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from 'v5/platform/dom/cn';
import { focusRings } from './_common/focusRing';

export const inputVariants = cva(
  [
    'flex h-10 w-full py-2',
    'file:border-0 file:bg-transparent file:text-base file:font-medium file:text-fg-default',
    'placeholder:text-fg-muted',
    'disabled:cursor-not-allowed disabled:opacity-50'
  ],
  {
    variants: {
      size: {
        xs: 'text-base h-[20px] py-1',
        sm: 'text-base h-9 py-1.5',
        md: 'text-base h-12 py-2',
        lg: 'text-lg h-15 py-3 font-semibold',
        xl: 'text-xl h-17 py-4 font-semibold'
      },
      variant: {
        regular: [
          'rounded',
          'border border-border-default bg-bg-default text-fg-default',
          'px-3',
          'hocus:border-bg-inset',
          focusRings.focus.normal
        ],
        inline: [
          'transition-all',
          'bg-transparent text-fg-default',
          'focus:shadow-[inset_0_-2px_0_0_]',
          'shadow-[inset_0_-1px_0_0_]',
          'shadow-border-default',
          'hover:shadow-bg-inset',
          'focus:shadow-brand-300',
          'hover:focus:shadow-brand-400'
        ],
        plain: [
          'transition-all',
          'bg-transparent text-fg-default',
          'focus:shadow-[inset_0_-2px_0_0_]',
          'shadow-[inset_0_-1px_0_0_]',
          'shadow-transparent',
          'hover:shadow-bg-inset',
          'focus:shadow-brand-300',
          'hover:focus:shadow-brand-400'
        ]
      }
    },
    defaultVariants: {
      variant: 'regular',
      size: 'md'
    },
    compoundVariants: [
      {
        variant: 'regular',
        size: 'sm',
        className: 'rounded'
      }
    ]
  }
);

export type InputProps = Omit<React.ComponentProps<'input'>, 'size'> &
  VariantProps<typeof inputVariants> & { highlightOnFocus?: boolean };

export type InputSize = InputProps['size'];
export type InputVariant = InputProps['variant'];

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    { className, type, highlightOnFocus, onFocus, variant, size, ...props },
    ref
  ) => {
    return (
      <input
        type={type}
        className={cn(inputVariants({ variant, size }), className)}
        ref={ref}
        {...props}
        onFocus={e => {
          if (highlightOnFocus) {
            e.target.select();
          }
          onFocus?.(e);
        }}
      />
    );
  }
);
Input.displayName = 'Input';

export { Input };
