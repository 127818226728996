import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Pagination from 'v1/components/shared/layout/Pagination/Pagination';
import SearchSorts from 'v1/components/shared/search/SearchSorts/SearchSorts';
import DateBetweenFilter from './DateBetweenFilter/DateBetweenFilter';

import { debounce, find, get, isEmpty, omit, set } from 'lodash';
import classnames from 'classnames';

import { CONDITIONS } from 'v1/helpers/searchHelper';

import './GenericSearch.scss';
import ResourceFilter from './ResourceFilter/ResourceFilter';
import TextInput from 'shared/byModel/Text/TextInput/TextInput';
import GenericSearchFilterItem from './GenericSearchFilterItem/GenericSearchFilterItem';
import TagsFilter from './TagsFilter/TagsFilter';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'v5/design-sytem/Popover';
import { Button } from 'v5/design-sytem/Button';
import { FunnelIcon } from '@heroicons/react/16/solid/index.js';
import { Checkbox } from 'v5/design-sytem/Checkbox.js';

class GenericSearch extends Component {
  constructor(props) {
    super(props);

    const savedSearch = localStorage[`Stitch.Query.${props.searchStore}`];
    const query = savedSearch
      ? JSON.parse(savedSearch)
      : get(props.params, 'sorts', []).find(
            opt => opt.field === this.DEFAULT_SEARCH.order_by.field
          )
        ? this.DEFAULT_SEARCH
        : {
            ...this.DEFAULT_SEARCH,
            order_by: {
              ...this.DEFAULT_SEARCH.order_by,
              field: get(props.params, 'sorts[0].field')
            }
          };

    this.state = {
      query
    };
  }

  DEFAULT_SEARCH = {
    filters: {},
    order_by: { field: 'created_at', direction: 'desc' }
  };

  componentDidMount() {
    if (this.props.params) {
      this.props.searchOnLoad && this.onChange(false, 'LIGHT_RESET');
    } else {
      this.props.searchOnLoad && this.onChange(false, 'FULL_RESET');
    }
  }

  componentDidUpdate(nextProps) {
    if (this.props.archived !== nextProps.archived) this.onSearch();
  }

  onSearch = () => {
    if (this.props.archived)
      this.props.onChange({ ...this.state.query, archived: true });
    else this.props.onChange(this.state.query);
  };
  debounceSearch = debounce(this.onSearch, 300);

  onChange = (debounce, resetMode) => {
    let query = this.state.query;
    if (resetMode) {
      switch (resetMode) {
        case 'LIGHT_RESET':
          query = omit(query, ['p']);
          break;
        case 'FULL_RESET':
          query = this.DEFAULT_SEARCH;
          break;
        default:
          break;
      }
      this.setState({ query }, () => {
        debounce ? this.debounceSearch() : this.onSearch();
      });
    } else {
      debounce ? this.debounceSearch() : this.onSearch();
    }
    if (this.props.searchStore) {
      localStorage[`Stitch.Query.${this.props.searchStore}`] =
        JSON.stringify(query);
    }
  };
  onPageChange = p => {
    this.setState(
      prev => ({ query: { ...prev.query, p } }),
      () => this.onChange()
    );
  };
  onQueryChange = query => {
    this.setState(
      prev => ({ query: { ...prev.query, query } }),
      () => this.onChange(true, 'LIGHT_RESET')
    );
  };
  onFilterChange = param => {
    const filterAdded =
      this.state.query.filters[param.field] &&
      this.state.query.filters[param.field].id === param.id;

    if (
      filterAdded &&
      param.condition !== CONDITIONS.between &&
      param.field !== 'contact_id' &&
      param.field !== 'tags'
    ) {
      this.removeFilter(param);
    } else {
      const value = (() => {
        switch (param.condition) {
          case CONDITIONS.between:
            return {
              gte: param.value.start,
              lte: param.value.end,
              type: param.type,
              id: param.id
            };
          default:
            return {
              [param.condition]: param.value,
              type: param.type,
              id: param.id
            };
        }
      })();

      this.setState(
        prev => {
          const filters = set(prev.query.filters, param.field, value);

          return { query: { ...prev.query, filters } };
        },
        () => this.onChange(false, 'LIGHT_RESET')
      );
    }
  };

  removeFilter = param => {
    const filterAdded =
      this.state.query.filters[param.field] &&
      this.state.query.filters[param.field].id === param.id;

    if (filterAdded) {
      this.setState(
        prev => {
          return {
            query: {
              ...prev.query,
              filters: omit(prev.query.filters, param.field)
            }
          };
        },
        () => this.onChange(false, 'LIGHT_RESET')
      );
    }
  };

  onSortChange = param => {
    this.setState(
      prev => {
        const direction =
          prev.query.order_by.field === param.field
            ? this.oppositeDirection(prev.query.order_by.direction)
            : 'desc';
        const order_by = { field: param.field, direction };
        return { query: { ...prev.query, order_by } };
      },
      () => this.onChange()
    );
  };

  oppositeDirection = dir => (dir === 'asc' ? 'desc' : 'asc');

  renderFilterType = (param, index) => {
    switch (param.type) {
      case 'contact':
        return (
          <ResourceFilter
            key={index}
            param={param}
            index={index}
            filter={this.state.query.filters[param.field]}
            filterChanged={this.onFilterChange}
            removeFilter={() => this.removeFilter(param)}
            label="By resource"
          />
        );
      case 'tags':
        return (
          <TagsFilter
            key={index}
            param={param}
            index={index}
            filter={this.state.query.filters[param.field]}
            filterChanged={this.onFilterChange}
            removeFilter={() => this.removeFilter(param)}
            label="By tags"
          />
        );
      case 'date_between':
        return (
          <DateBetweenFilter
            key={index}
            param={param}
            index={index}
            removeFilter={() => this.removeFilter(param)}
            filterChanged={value => {
              this.onFilterChange({
                ...param,
                value
              });
            }}
            filter={this.state.query.filters[param.field]}
          />
        );
      case 'number':
      case 'boolean':
      case 'date':
      case 'archived':
      default:
        return (
          <a
            role="button"
            key={index}
            className="DropdownButton-option"
            onClick={e => {
              this.onFilterChange(param);
            }}
          >
            <Checkbox
              variant="primaryAlt"
              onCheckedChange={() => this.onFilterChange(param)}
              checked={
                this.state.query.filters[param.field] &&
                this.state.query.filters[param.field].id === param.id
              }
            />
            <span className="DropdownButton-option-label">{param.label}</span>
          </a>
        );
    }
  };
  renderParams = () => {
    return (
      <div className="GenericSearch-options tailwind-root">
        {this.props.params.sorts && (
          <SearchSorts
            orderBy={get(this.state.query, 'order_by')}
            sorts={this.props.params.sorts}
            onSortChange={this.onSortChange}
          />
        )}
        {this.props.params.filters && (
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="ghost" size="sm">
                <FunnelIcon />
                Filter
              </Button>
            </PopoverTrigger>
            <PopoverContent align="start" className="p-0">
              {this.props.params.filters.map((param, index) =>
                this.renderFilterType(param, index)
              )}
            </PopoverContent>
          </Popover>
        )}
      </div>
    );
  };
  renderPagination = () => {
    const { paging } = this.props;
    return (
      <Pagination
        paging={paging}
        className="GenericSearch-pagination"
        onPageChange={page => {
          this.onPageChange(page);
        }}
      />
    );
  };
  renderActiveFilters = () => {
    const filters = omit(
      get(this.state, 'query.filters'),
      this.props.ignoredFilterFields
    );

    if (isEmpty(filters)) return;

    return (
      <div className="PageSearch-activeFilters">
        {Object.keys(filters).map(key => (
          <GenericSearchFilterItem
            key={key}
            filterType={key}
            filter={filters[key]}
            params={find(
              get(this.props, 'params.filters'),
              s => s.id === filters[key].id
            )}
            handleRemoveFilter={this.removeFilter}
          />
        ))}
      </div>
    );
  };
  render() {
    const { params, options = {} } = this.props;

    return (
      <div className={classnames(['GenericSearch', this.props.className])}>
        {!options.hideSearch && (
          <div className="GenericSearch-searchInput">
            <TextInput
              placeholder={this.props.placeholder}
              size="s"
              value={get(this.state.query, 'query')}
              onChange={this.onQueryChange}
              autoComplete="off"
            />
          </div>
        )}
        {params && this.renderParams()}
        {this.renderActiveFilters()}
        {this.renderPagination()}
      </div>
    );
  }
}

GenericSearch.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired, // TODO: arguments
  searchStore: PropTypes.string,
  searchOnLoad: PropTypes.bool,
  paging: PropTypes.any, // TODO: passed to Pagination
  // Params are generally generated from helper functions in "helpers/searchHelper"
  params: PropTypes.shape({
    sorts: PropTypes.any, // TODO: type
    filters: PropTypes.any // TODO: type
  })
};

export default GenericSearch;
