import mergeRefs from 'merge-refs';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { isNil } from 'lodash';

import { TextInputPolyfills } from 'v1/components/shared';
import { TextInputProps } from './__types__/TextInput';

import styles from './TextInput.module.scss';

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      id,
      name,
      value,
      type,
      appearance,
      size,
      align,
      validity,
      disabled,
      removeAutocomplete,
      initialFocus,
      onFocus,
      highlightOnFocus,
      overlayLabel,
      overlayTooltip,
      overlayAction,
      onBlur,
      onChange,
      usePolyfill,
      label,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const input = useRef<HTMLInputElement | null>(null);
    // Sometimes TextInput isn't controlled by a form, and only saves onBlur
    // - so we need to store the value internally
    const [currentValue, setCurrentValue] = useState(value);

    useEffect(() => {
      setCurrentValue(value);
    }, [value]);

    useEffect(() => {
      // Trigger auto focus if no value
      if (initialFocus && !value && input && input.current !== null) {
        input.current.focus();
        setIsFocused(true);
      }
    }, []);

    function onFocusHandler() {
      if (input && input.current !== null) {
        highlightOnFocus && input.current.select();
        onFocus && onFocus(input.current.value);
        setIsFocused(true);
      }
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      setCurrentValue(e.target.value);
      onChange && onChange(e);
    };

    const handleBlur = () => {
      setIsFocused(false);
      onBlur && onBlur(currentValue);
    };

    const handleKeyPress = (
      event: React.KeyboardEvent<HTMLInputElement>
    ): void => {
      if (input && input.current !== null && event.key === 'Enter') {
        input.current.blur ? input.current.blur() : handleBlur();
      }
    };

    const renderInput = () => {
      const inputProps = {
        ref: mergeRefs(input, ref),
        disabled,
        id,
        name,
        value: currentValue || '',
        type,
        className: classnames(
          styles.TextInput,
          {
            [styles[`TextInput_${appearance}`]]: appearance,
            [styles[`TextInput_${size}`]]: size,
            [`text-${align}`]: align,
            [styles[`TextInput_${validity}`]]: validity
          },
          className
        ),
        autoComplete: removeAutocomplete ? 'off' : 'on',
        onKeyPress: handleKeyPress,
        onFocus: onFocusHandler,
        onBlur: handleBlur,
        onChange: handleChange,
        ...props
      };

      return usePolyfill ? (
        <TextInputPolyfills setCurrentValue={setCurrentValue} {...inputProps} />
      ) : (
        <input {...inputProps} />
      );
    };

    const renderOverlay = () => {
      if (!overlayAction) {
        return null;
      }
      if (!isFocused) {
        return (
          <span
            className={classnames(
              styles.overlay,
              {
                [styles[`overlay_${appearance}`]]: appearance,
                [styles[`overlay_${size}`]]: size,
                [styles[`overlay_${align}`]]: align
              },
              'Parent_hoverListener'
            )}
          >
            <a
              className="trunc"
              onClick={() =>
                typeof overlayAction === 'function' && overlayAction()
              }
              href={
                typeof overlayAction !== 'function' ? overlayAction : undefined
              }
              target={
                typeof overlayAction !== 'function' ? '_blank' : undefined
              }
              rel={
                typeof overlayAction !== 'function'
                  ? 'noopener noreferrer'
                  : undefined
              }
              title={overlayLabel}
              data-tip={overlayTooltip}
            >
              {overlayLabel || value}
            </a>
            {typeof overlayAction !== 'function' &&
              overlayAction.includes('http') && (
                <>
                  {' '}
                  <a
                    className="Child_hoverListener"
                    title="Open in new window"
                    onClick={() => {
                      window.open(
                        overlayAction,
                        '_blank',
                        'location=yes,height=600,width=600,scrollbars=yes,status=yes'
                      );
                    }}
                  >
                    <img
                      src="/images/icon_new_window.svg"
                      width="10px"
                      alt=""
                    />
                  </a>
                </>
              )}
          </span>
        );
      }
    };

    if (!isNil(overlayLabel) && !isNil(overlayAction)) {
      return (
        <div
          ref={ref}
          className={classnames(styles.container, {
            // [styles[`container_${appearance}`]]: appearance,
            // [styles[`container_${size}`]]: size,
            [styles.container_unfocused]: !isFocused
          })}
        >
          {renderInput()}
          {renderOverlay()}
        </div>
      );
    }

    // Default to rendering input component without wrapper for backwards compatibility
    return renderInput();
  }
);

TextInput.defaultProps = {
  value: '',
  type: 'text',
  appearance: 'outline',
  disabled: false,
  usePolyfill: false
};

TextInput.displayName = 'TextInput';

export default TextInput;
