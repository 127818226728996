import { maybe, rd } from '@passionware/monads';
import { injectConfig } from '@passionware/platform-react';
import { createMatcher } from '@passionware/query-toolkit';
import React from 'react';
import { Trans } from 'react-i18next';
import { Group } from '../../../../../__types__';
import { CollectionField } from '../../../../../v5/common-widgets/CollectionField';
import { ColorDot } from '../../../../../v5/common-widgets/ColorDot';
import { useDefaultCreateContent } from '../../../../../v5/common-widgets/ItemSelector';

export const GroupSelect = injectConfig(CollectionField<Group>)
  .fromProps<{ suggestions: Group[]; allGroups: Group[] }>(api => ({
    // promise-like function to resolve id to the item
    useResolveById: () => {
      const props = api.useProps();

      return id =>
        maybe.getOrThrow(
          Promise.resolve(
            maybe.getOrThrow(
              props.allGroups.find(x => x.id === Number(id)),
              `Option with id ${id} not found`
            )
          )
        );
    },
    useOptions: query => {
      const props = api.useProps();
      return rd.of(
        props.suggestions
          .filter(createMatcher(query, x => x.name ?? '(no name)'))
          .map(x => ({
            id: String(x.id),
            label: x.name ?? '(no name)',
            content: (
              <div className="flex items-center gap-2 justify-center">
                <ColorDot color={x.colour} />
                <span>{x.name}</span>
              </div>
            )
          }))
      );
    },
    promptRenderer: data => (
      <Trans
        ns="v1_shared"
        i18nKey="TagInput.add"
        context={data.length ? 'many' : undefined}
      />
    ),
    useCreateContent: (query, options) =>
      useDefaultCreateContent(
        query,
        options,
        <>
          Create something: <strong>{query}</strong>
        </>
      ),
    getId: x => String(x.id),
    rowRenderer: x => (
      <div className="flex items-center gap-2 justify-center">
        <ColorDot color={x.colour} />
        <span>{x.name}</span>
      </div>
    ),
    useGetGhostItem: () => query => ({
      id: -1,
      text: query,
      archived: false,
      order: -1,
      colour: '#000000',
      user_id: '1',
      created_at: new Date('2021-01-01T00:00:00Z'),
      updated_at: new Date('2021-01-01T00:00:00Z'),
      created_mechanism: null,
      include_in_calculation: false
    }),
    useCreatePromise: () => query => Promise.reject(),
    useSelectedItems: keys => {
      const props = api.useProps();
      return rd.of(
        keys.map(key => props.allGroups.find(x => x.id === Number(key))!)
      );
    }
  }))
  .transformProps(x => x.skipFields('allGroups', 'suggestions'));
