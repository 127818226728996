import { maybe } from '@passionware/monads';
import React from 'react';
import { TextInputProps } from 'v1/components/shared/byType/text/TextInput/__types__/TextInput';
import { Input } from 'v5/design-sytem/Input';
import { InputOverlay } from 'v5/design-sytem/InputOverlay';
import { InlineField } from 'v5/platform/react/InlineField';
import { getV5Props } from '../GetV5Props';

interface PhoneInputProps extends Omit<TextInputProps, 'onChange'> {
  showOverlay?: boolean;
  onChange?(value: string | null): void;
}
const noop = () => {};
const PhoneInput = ({
  placeholder,
  value,
  showOverlay = true,
  onChange = noop,
  onBlur,
  ...props
}: PhoneInputProps) => {
  // Prevent some props being spread
  const { type, ...rest } = props;

  const label = showOverlay && value ? value : undefined;
  const overlayAction = showOverlay ? `tel:${value}` : null;
  return (
    <InlineField
      value={value}
      onChange={v => {
        onChange(v);
        onBlur?.(v);
      }}
      changeOnDebounce={false}
    >
      {({ value, onChange, onBlur, onBlurWithin }) => (
        <InputOverlay
          label={label}
          action={overlayAction}
          {...getV5Props(rest)}
        >
          <Input
            type="tel"
            placeholder={
              placeholder !== undefined ? placeholder : 'Phone number'
            }
            // overlayLabel={label}
            // overlayAction={overlayAction}
            {...getV5Props(rest)}
            value={maybe.getOrUndefined(value)}
            onChange={e => onChange(e.target.value)}
            onBlur={onBlur}
            data-order={props['data-order']}
          />
        </InputOverlay>
      )}
    </InlineField>
  );
};

export default PhoneInput;
