import { maybe } from '@passionware/monads';
import PropTypes from 'prop-types';
import React from 'react';

import { TextInputProps } from 'v1/components/shared/byType/text/TextInput/__types__/TextInput';
import TypeTextInput from 'v1/components/shared/byType/text/TextInput/__types__/TypeTextInput';
import { Input } from 'v5/design-sytem/Input';
import { InputOverlay } from 'v5/design-sytem/InputOverlay';
import { InlineField } from 'v5/platform/react/InlineField';
import { getV5Props } from '../GetV5Props';

interface EmailInputProps extends Omit<TextInputProps, 'onChange'> {
  showOverlay?: boolean;
  onChange?(value: string | null): void;
}

const EmailInput = ({
  placeholder,
  value,
  showOverlay = true,
  onChange,
  onBlur,
  ...props
}: EmailInputProps) => {
  // Prevent some props being spread
  const { type, ...rest } = props;

  const handleChange = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>): void => {
    onChange && onChange(value);
  };

  const label = showOverlay && value ? value : undefined;
  const overlayAction = showOverlay ? `mailto:${value}` : null;

  return (
    <InlineField
      value={value}
      onChange={v => {
        onChange?.(v);
        onBlur?.(v);
      }}
      changeOnDebounce={false}
    >
      {({ value, onChange, onBlur, onBlurWithin }) => (
        <InputOverlay
          label={label}
          action={overlayAction}
          {...getV5Props(rest)}
        >
          <Input
            type="email"
            placeholder={
              placeholder !== undefined ? placeholder : 'Email address'
            }
            autoComplete="false"
            {...getV5Props(rest)}
            value={maybe.getOrUndefined(value)}
            onChange={e => onChange(e.target.value)}
            onBlur={onBlur}
            data-order={props['data-order']}
          />
        </InputOverlay>
      )}
    </InlineField>
  );
};

EmailInput.propTypes = {
  ...TypeTextInput,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  showOverlay: PropTypes.bool,
  onChange: PropTypes.func
  // Inherits standard TextInput & HTML input properties
};

export default EmailInput;
