import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  PressStud,
  Dropdown,
  MenuItem,
  TextInput,
  SlotCategoryDropdown,
  Grid,
  GridCell,
  ListCell,
  RateInput
} from 'v1/components/shared';

import { deleteExpense } from 'store/v1/expenses/expenses.actions.js';
import { displayAlert, openModal } from 'store/v1/ui/ui.actions.js';
import { isTempId, friendlyStringFromConst } from 'v1/helpers/misc';
import { EntityType } from '__types__';
import get from 'lodash/get';
import find from 'lodash/find';
import {
  convertToWholeUnit,
  currencyFormatter
} from 'v1/helpers/currencyHelper';
import { maybe } from '@passionware/monads';

const BookingExpenseItem = ({
  expense: initialExpense,
  production = {},
  onUpdate,
  onCreate,
  onDelete,
  quickCreate
}) => {
  const dispatch = useDispatch();

  const { budget_locale, budget_currency } = production;
  const [expense, setExpense] = useState(initialExpense);

  const [isEditing, setIsEditing] = useState(isTempId(expense.id));

  useEffect(() => {
    setExpense(initialExpense);
  }, [initialExpense]);

  const {
    commit_rate_currency,
    commit_rate_interval,
    commit_rate_amount,
    commit_vendor,
    commit_rate_quantity,
    slot_category_id
  } = expense;
  const category = useSelector(state =>
    get(state.slot_categories.data, slot_category_id, {})
  );

  function updateExpense(field, value) {
    if (typeof field === 'object') {
      setExpense({
        ...expense,
        ...field
      });
    } else {
      setExpense({
        ...expense,
        [field]: value || null
      });
    }
  }

  function saveExpense() {
    if (
      maybe.isPresent(expense.commit_rate_amount) &&
      maybe.isPresent(expense.commit_rate_currency) &&
      maybe.isPresent(expense.commit_rate_interval) &&
      maybe.isPresent(expense.commit_rate_quantity)
    ) {
      setIsEditing(false);
      onUpdate(expense);
    } else {
      dispatch(
        displayAlert('error', 'Please complete expense details before saving')
      );
    }
  }

  // function onDelete() {
  //   onDelete()
  // }

  if (isEditing) {
    return (
      <ListCell>
        <Grid gutters="XS" className="stack-XS">
          <GridCell width="4/12">
            <SlotCategoryDropdown
              onSelect={(field, value) => {
                const budgetExpense = find(
                  production.budget_expenses,
                  b_e => b_e.slot_category_id === value
                );
                if (budgetExpense) {
                  updateExpense({
                    production_budget_expense_id: budgetExpense.id,
                    [field]: value
                  });
                } else {
                  updateExpense(field, value);
                }
              }}
              slot={expense}
              buttonClass="emulate-input emulate-input_underline"
              type="EXPENSE"
            />
          </GridCell>
          <GridCell width="5/12">
            <RateInput
              appearance="underline"
              size="XS"
              placeholder="Amount"
              defaultCurrency={budget_currency}
              entityType={EntityType.EXPENSE}
              value={{
                currency: commit_rate_currency,
                amount: commit_rate_amount,
                interval: commit_rate_interval
              }}
              onBlur={value => {
                updateExpense({
                  commit_rate_currency: value.currency,
                  commit_rate_amount: value.amount,
                  commit_rate_interval: value.interval
                });
              }}
            />
          </GridCell>
          <GridCell>
            <TextInput
              field="commit_rate_quantity"
              value={commit_rate_quantity}
              placeholder="Qty"
              type="number"
              min={0}
              onChange={({ target }) =>
                updateExpense('commit_rate_quantity', parseFloat(target.value))
              }
              appearance="underline"
              size="S"
            />
          </GridCell>
        </Grid>
        <Grid gutters="XS">
          <GridCell>
            <TextInput
              field="commit_vendor"
              value={commit_vendor}
              placeholder="Description"
              onChange={({ target }) =>
                updateExpense('commit_vendor', target.value)
              }
              appearance="underline"
            />
          </GridCell>
          <GridCell width="auto">
            <PressStud
              label="Cancel"
              size="S"
              action={() => {
                if (isTempId(expense.id)) {
                  onDelete(expense);
                } else {
                  setIsEditing(false);
                }
              }}
            />
          </GridCell>
          <GridCell width="auto">
            <PressStud
              label="Save"
              appearance="primary"
              size="S"
              action={saveExpense}
            />
          </GridCell>
        </Grid>
      </ListCell>
    );
  }
  return (
    <ListCell padding="XS">
      <Grid vcenter>
        <GridCell width="5/12">
          <div className="text-13-700 trunc">{category.name}</div>
          <span className="text-13-600-eggplant">{commit_vendor}</span>
        </GridCell>
        <GridCell className="text-13-600">
          {`${commit_rate_quantity} x ${currencyFormatter(
            budget_locale,
            commit_rate_currency
          ).format(convertToWholeUnit(commit_rate_amount))}`}{' '}
          <span className="text-13-600-eggplant">
            {friendlyStringFromConst(commit_rate_interval)}
          </span>
        </GridCell>
        <GridCell width="auto">
          <Dropdown
            buttonClass="btn btn-small btn-square btn-link"
            buttonIcon={<img src="/images/icon_options.svg" alt="" />}
          >
            <MenuItem
              onSelect={() => {
                quickCreate
                  ? setIsEditing(true)
                  : dispatch(openModal('ExpenseCreateModal', { expense }));
              }}
            >
              <span className="MenuItem-icon">
                <img src="/images/icon_edit.svg" alt="" />
              </span>
              <span className="MenuItem-label">Edit Expense</span>
            </MenuItem>
            <MenuItem
              onSelect={() =>
                quickCreate
                  ? onDelete(expense)
                  : dispatch(deleteExpense(expense.id))
              }
            >
              <span className="MenuItem-icon">
                <img src="/images/icon_delete.svg" alt="" />
              </span>
              <span className="MenuItem-label">Delete Expense</span>
            </MenuItem>
          </Dropdown>
        </GridCell>
      </Grid>
    </ListCell>
  );
};

BookingExpenseItem.propTypes = {};

export default BookingExpenseItem;
