import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  makeGetSlotCategories,
  makeGetSlotName
} from 'store/v1/slot_categories/slot_categories.selectors.js';
import useAutocomplete from 'v1/helpers/hooks/useAutocomplete';
import { filter, isEmpty, orderBy } from 'lodash';
import { SlotCategoryDropdownField } from './SlotCategoryDropdownField.tsx';
import { cn } from '~/v5/platform/dom/cn.js';

const SlotCategoryDropdown = ({
  slot,
  type,
  onSelect,
  filterCategories,
  buttonClass
}) => {
  const getSlotCategories = useMemo(makeGetSlotCategories, []);
  const slotCategoriesList = useSelector(state =>
    getSlotCategories(state, { type })
  );

  const getSlotName = useSelector(makeGetSlotName);

  const slotCategoriesListMemo = useMemo(
    () => slotCategoriesList,
    [slotCategoriesList]
  );

  const { matches } = useAutocomplete({
    data: slotCategoriesListMemo,
    keyToMatch: 'name'
  });

  let filteredMatches = filterCategories
    ? filter(matches, e => filterCategories.includes(e.id))
    : matches;

  filteredMatches = orderBy(filteredMatches, ['name', 'asc']);

  const label =
    getSlotName(slot, type) ||
    (type === 'EXPENSE' ? 'Select expense' : 'Select role');

  return (
    <div className="tailwind-root">
      <SlotCategoryDropdownField
        options={filteredMatches}
        onSelect={e => {
          onSelect('slot_category_id', Number(e));
        }}
      >
        <button
          className={cn([
            'bg-transparent w-full text-left border-0 rounded-none text-md font-semibold transition-[border-color] duration-100 hover:border-eggplant-lightest border-b border-transparent pl-0 pr-0',
            buttonClass
          ])}
        >
          {label}
        </button>
      </SlotCategoryDropdownField>
    </div>
  );
};

SlotCategoryDropdown.propTypes = {
  data: PropTypes.array,
  onSelect: PropTypes.func,
  filterCategories: PropTypes.array,
  type: PropTypes.oneOf(['RESOURCE_SLOT', 'EXPENSE']).isRequired
};

SlotCategoryDropdown.defaultProps = {
  data: [],
  onSelect: () => {}
};

export default SlotCategoryDropdown;
