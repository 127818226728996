import { rd } from '@passionware/monads';
import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { Dialog } from '../../design-sytem/Dialog';
import { WithServices } from '../../platform/ts/services';
import { WithFeatureManagementService } from '../../services/front/FeatureManagementService/FeatureManagementService';
import { FeatureFlagView } from './FeatureFlagView';

export function FeatureFlagWidget(
  props: WithServices<[WithFeatureManagementService]>
) {
  const [show, setShow] = useState(false);
  useHotkeys('ctrl+shift+l', () => {
    setShow(prev => !prev);
  });
  const { featureManagementService } = props.services;
  const view = featureManagementService.useFeatureFlagConfigView();
  const [tab, setTab] = useState<string>('');
  rd.useDataEffect(view, state => {
    if (tab === '') {
      setTab(state.featureFlagView.groups[0].name);
    }
  });

  return (
    <Dialog open={show} onOpenChange={setShow}>
      <FeatureFlagView
        tab={tab}
        onTabChange={setTab}
        view={view}
        onAction={featureManagementService.scheduleAction}
        onSave={() =>
          featureManagementService.saveConfig().then(() => setShow(false))
        }
      />
    </Dialog>
  );
}
