import classnames from 'classnames';
import React, { FC } from 'react';

import { Label } from 'v1/components/shared';
import { Input } from 'v5/design-sytem/Input';
import { cn } from '../../../../../../v5/platform/dom/cn';
import CurrencyAmountInput from '../../currency/CurrencyAmountInput/CurrencyAmountInputNew';
import NumberInput from '../../number/NumberInput/NumberInputNew';

import TimeInput from '../../time/TimeInput/TimeInputNew';
import EmailInput from '../EmailInput/EmailInputNew';
import { getV5Props, V1InputComponentProps } from '../GetV5Props';
import PhoneInput from '../PhoneInput/PhoneInputNew';
import UrlInput from '../UrlInput/UrlInputNew';
import {
  TextInputGroupPend,
  TextInputGroupPendProps
} from './TextInputGroupPend/TextInputGroupPend';

type TextInputGroupType =
  | 'currency'
  | 'email'
  | 'number'
  | 'phone'
  | 'text'
  | 'time'
  | 'url';

// TODO
type Pend = {
  icon?: string;
  text?: string;
  valueKey?: string;
  options?: string[] | { label: string; value: string }[];
  onClick?(): any;
};

export type TextInputGroupProps = {
  // Meta
  className?: string;
  type: TextInputGroupType;
  label?: string;
  disabled?: boolean;
  inputDisabled?: boolean;
  showOptional?: boolean;
  // Styling
  appearance: 'outline' | 'underline' | 'silent';
  size?: 'XS' | 'S' | 'M' | 'L';
  // Data
  valueKey: string;
  value: { [key: string]: any };
  prepend?: Pend;
  append?: Pend;
  onChange?(value: any): void;
  onBlur?(value: any, key: string): void;
  onFocus?(): void;
};

function InputAdapted(props: V1InputComponentProps) {
  return <Input {...props} {...getV5Props(props)} />;
}

const getInputComponent = (
  type: TextInputGroupType
): FC<V1InputComponentProps> => {
  switch (type) {
    case 'email':
      // @ts-expect-error skip for now
      return EmailInput;
    case 'number':
      // @ts-expect-error skip for now
      return NumberInput;
    case 'currency':
      // @ts-expect-error skip for now
      return CurrencyAmountInput;
    case 'phone':
      // @ts-expect-error skip for now
      return PhoneInput;
    case 'url':
      // @ts-expect-error skip for now
      return UrlInput;
    case 'time':
      // @ts-expect-error skip for now
      return TimeInput;
    case 'text':
    default:
      return InputAdapted;
  }
};

// TODO: tighten up types - very rough
const TextInputGroup = ({
  className,
  label,
  type,
  valueKey,
  value,
  appearance = 'outline',
  size = 'M',
  prepend,
  append,
  showOptional,
  disabled,
  inputDisabled,
  onChange,
  onFocus,
  onBlur,
  ...props
}: TextInputGroupProps) => {
  const InputComponent = getInputComponent(type);
  const inputValue = valueKey ? value[valueKey] : value;

  const handleFocus = () => {
    onFocus && onFocus();
  };

  const getNewValue = (key: string, val: any) => {
    return key ? { ...value, [key]: val } : val;
  };

  const handleChange = (key: string, val: any) => {
    onChange && onChange(getNewValue(key, val));
  };

  const handleBlur = (key: string, val: any) => {
    onBlur && onBlur(getNewValue(key, val), key);
  };

  const handlePendChange = (key: string, val: any) => {
    handleChange(key, val);
    handleBlur(key, val);
  };

  const renderPend = (
    pendType: TextInputGroupPendProps['type'],
    pend: Pend,
    order: string
  ) => (
    <TextInputGroupPend
      type={pendType}
      appearance={appearance}
      size={size}
      pend={pend}
      value={value}
      disabled={disabled}
      onChange={handlePendChange}
      className={type === 'time' ? 'w-fit' : ''}
      data-order={order}
    />
  );

  return (
    <div className={classnames(['tailwind-root', className])}>
      {label && (
        <Label>
          {label}
          {showOptional && <i>optional</i>}
        </Label>
      )}
      <div
        data-bar=""
        className={cn(
          'flex flex-row gap-0 items-center [&_a]:text-fg-default',
          appearance === 'outline' && [
            '*:focus:z-1',
            '[&_[data-order="first"]]:rounded-r-none [&_[data-order="first"]]:-mr-px',
            '[&_[data-order="last"]]:rounded-l-none [&_[data-order="last"]]:-ml-px',
            // now if no data-order
            '[&_[data-order="middle"]]:rounded-none'
          ]
          // '*:not-first:rounded-l-none! *:not-last:rounded-r-none! *:focus:z-1 *:not-last:-mr-px! *:not-first:-ml-px!'
        )}
      >
        {prepend && renderPend('prepend', prepend, 'first')}
        <InputComponent
          appearance={appearance}
          size={size}
          value={inputValue}
          disabled={disabled || inputDisabled}
          onFocus={handleFocus}
          onBlur={(val: any) => handleBlur(valueKey, val)}
          onChange={(val: any) => handleChange(valueKey, val)}
          data-order={
            prepend && append
              ? 'middle'
              : append
                ? 'first'
                : prepend
                  ? 'last'
                  : ''
          }
          {...props}
        />
        {append && renderPend('append', append, 'last')}
      </div>
    </div>
  );
};

export default TextInputGroup;
