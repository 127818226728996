import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import SearchFilterItem from './SearchFilterItem/SearchFilterItem';

const SearchFilters = ({
  slimline,
  showMeta,
  filters = {},
  onRemove,
  lockedFields
}) => {
  const custom_field_definitions = useSelector(
    state => state.auth.settings.custom_field_definitions
  );

  let { resource_type_id, ...activeFilters } = filters;
  activeFilters = showMeta ? filters : activeFilters;

  const handleRemove = key => {
    const { [key]: removed, ...newFilters } = filters;
    onRemove(newFilters);
  };

  return (
    <>
      {Object.keys(activeFilters)
        .sort((a, b) =>
          a === 'resource_type_id' ? -1 : a === 'availability' ? -1 : 1
        )
        .filter(key => key !== 'created_at') // NOTE: this is for DataExportsForm, but find something more sustainable
        .map(key => {
          const customObject = key.startsWith('custom_')
            ? custom_field_definitions.find(
                c => c.id === parseInt(key.substring(7))
              )
            : null;
          return (
            <SearchFilterItem
              key={key}
              fieldKey={key}
              slimline={slimline}
              filter={activeFilters[key]}
              onRemove={handleRemove}
              customObject={customObject}
              lockedFields={lockedFields}
            />
          );
        })}
    </>
  );
};

// TODO
SearchFilters.propTypes = {
  slimline: PropTypes.bool,
  showMeta: PropTypes.bool,
  filters: PropTypes.object.isRequired,
  onChange: PropTypes.func, // TODO: arguments
  lockedFields: PropTypes.array
};

export default SearchFilters;
