import { rd } from '@passionware/monads';
import {
  FeatureFlagSource,
  FeatureFlagValues,
  FeatureGroupDefinition
} from '../feature-management';

export function createEnforcedFeatureFlagSource<
  Definition extends FeatureGroupDefinition
>(
  forcedValues: Partial<FeatureFlagValues<Definition>>
): FeatureFlagSource<Definition> {
  return {
    useFeatureFlags: () => rd.of(forcedValues)
  };
}
