'use client';
import {
  MenuContent,
  MenuItem,
  MenuPositioner,
  MenuRoot,
  MenuTrigger,
  Portal
} from '@ark-ui/react';
import { rd } from '@passionware/monads';

import { IntercomAPI } from 'react-intercom/dist/lib/react-intercom';
import { Icon } from 'shared';
import { cn } from '../../../../v5/platform/dom/cn';
import { myServices } from '../../../../v5/services/services.connected.app';
import SidebarButton from './SidebarButton';

const feedbackPortalUrl =
  'https://atellio.atlassian.net/servicedesk/customer/portals';

const SidebarHelpMenu = () => {
  const resolveActions = ({ value }) => {
    switch (value) {
      case 'intercom':
        return IntercomAPI('show');
      case 'feedback':
        return window.open(feedbackPortalUrl, '_blank');
      default:
        return;
    }
  };

  const currentLanguage = myServices.localeService.useCurrentLocale();

  const version = rd.tryGet(
    myServices.featureService.useFeature('locale-switcher')
  );

  return (
    <MenuRoot
      closeOnSelect
      onSelect={resolveActions}
      positioning={{ gutter: 8, placement: 'right-end', sameWidth: true }}
    >
      <MenuTrigger className="rounded-md w-full flex items-center text-left p-2 hover:bg-eggplant-lighter/60 hover:text-white/100  focus:outline-none focus:ring-2 ring-eggplant-lightest/60">
        <span className="flex-0 mr-3">
          <Icon name="v4_support" />
        </span>
        <span className="flex-1 truncate">Help & Support</span>
      </MenuTrigger>
      <Portal
        container={{ current: document.getElementById('tailwind-portals')! }}
      >
        <MenuPositioner className="z-10! shadow-xl">
          <MenuContent className="bg-eggplant border-1 border-solid border-eggplant-lighter/60 text-white p-4 rounded-lg border-eggplant-light">
            <MenuItem asChild value="intercom">
              <SidebarButton icon={<Icon name="v4_comment" />}>
                Talk to our team
              </SidebarButton>
            </MenuItem>
            <MenuItem asChild value="feedback">
              <SidebarButton icon={<Icon name="v4_support" />} target="_blank">
                Send feedback
              </SidebarButton>
            </MenuItem>
            {version === 'new' && (
              <MenuItem
                asChild
                value="language"
                onClick={e => e.preventDefault()}
              >
                <div className="flex flex-row gap-2 items-center py-2">
                  {(['en', 'pl', 'zh'] as const).map(lang => (
                    <button
                      className={cn(
                        'px-2 py-1 rounded-md bg-eggplant-lighter/20 text-white hover:bg-eggplant-lighter/60 focus-visible:bg-eggplant-lighter/60 focus-visible:ring-2 focus-visible:ring-eggplant-lightest/60',
                        {
                          'bg-eggplant-lightest/60': lang === currentLanguage
                        }
                      )}
                      key={lang}
                      onClick={() =>
                        myServices.localeService.changeCurrentLocale(lang)
                      }
                    >
                      {
                        {
                          en: '🇬🇧',
                          pl: '🇵🇱',
                          zh: '🇨🇳'
                        }[lang]
                      }{' '}
                      {lang.toUpperCase()}
                    </button>
                  ))}
                </div>
              </MenuItem>
            )}
          </MenuContent>
        </MenuPositioner>
      </Portal>
    </MenuRoot>
  );
};

export default SidebarHelpMenu;
