import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DEPRECATED_TextInput from 'v1/components/legacy/deprecated/DEPRECATED_TextInput/DEPRECATED_TextInput';
import { MiniLoader } from 'v1/components/shared';
import { Button } from 'v1/components/shared';
import { isImageFromURL } from 'v1/helpers/misc';

import classnames from 'classnames';
import { isEmpty } from 'lodash';

import './LinkUploader.scss';

const youtubeRegex =
  /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-_]*)(&(amp;)?‌​[\w?‌​=]*)?/;
const vimeoRegex =
  /https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
const soundcloudRegex = /^https?:\/\/(soundcloud\.com|snd\.sc)\/(.*)$/;

class LinkUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  testProvider = link => {
    if (isEmpty(link)) {
      return null;
    }
    link = link.toLowerCase();

    if (isImageFromURL(link)) {
      return 'IMAGE';
    }
    if (youtubeRegex.test(link)) {
      return 'YOUTUBE';
    }
    if (vimeoRegex.test(link)) {
      return 'VIMEO';
    }
    if (soundcloudRegex.test(link)) {
      return 'SOUNDCLOUD';
    }
    if (!this.props.testLink) {
      return 'EXTERNAL_URL';
    }
    return null;
  };

  onLinkChange = () =>
    this.props.onChange &&
    this.props.onChange({
      provider: this.state.provider,
      files: this.state.files
    });

  onChange = (name, link) => {
    if (link === '' || link === undefined || link === null) {
      return this.setState({
        link: '',
        error: null,
        provider: null,
        files: null
      });
    }
    const provider = this.testProvider(link);

    if (!this.props.testLink) {
      return this.setState(
        { link, error: null, provider, files: [link] },
        this.onLinkChange
      );
    }
    if (provider) {
      return this.setState(
        { link, error: null, provider, files: [link] },
        this.onLinkChange
      );
    }
    return this.setState(
      {
        error:
          'Sorry this is not a valid link. We only support YouTube, Vimeo & Image links for now',
        provider: null,
        files: null,
        link
      },
      this.onLinkChange
    );
  };

  onSave = () => {
    if (this.props.testLink && !this.state.provider) return;

    this.setState({ link: '' }, () => {
      this.props.onLinkUpload({
        provider: this.state.provider || 'IMAGE',
        files: this.state.files
      });
    });
  };
  renderSave = () => {
    if (this.props.testLink && !this.state.provider) return null;
    return (
      <Button
        dataCy="pin-url"
        className="btn btn-primary"
        onClick={this.onSave}
        disabled={isEmpty(this.state.link)}
      >
        <MiniLoader isLoading={this.props.uploading} light>
          {this.props.submitText ? this.props.submitText : 'Save'}
        </MiniLoader>
      </Button>
    );
  };
  render() {
    const { className, placeholder, label } = this.props;
    const { link, error } = this.state;
    return (
      <div className={classnames(['LinkUploader', className])}>
        {label && (
          <span className="form-label" title="label">
            {label}
          </span>
        )}
        <div className="LinkUploader-input">
          <DEPRECATED_TextInput
            field="media"
            placeholder={
              placeholder
                ? placeholder
                : 'Paste an Image, Vimeo, or YouTube link here'
            }
            onChange={this.onChange}
            value={link}
            onEnter={this.onSave}
            initialFocus={true}
            removeAutocomplete
            className={this.props.inputClassName}
          />
          {this.renderSave()}
        </div>
        {error ? <span className="LinkUploader-error">{error}</span> : null}
      </div>
    );
  }
}

LinkUploader.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  submitText: PropTypes.string,
  testLink: PropTypes.bool,
  uploading: PropTypes.bool,
  onChange: PropTypes.func,
  onLinkUpload: PropTypes.func,
  onCancel: PropTypes.func // TODO: used?
};

export default LinkUploader;
