'use client';

import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import * as React from 'react';
import { cn } from 'v5/platform/dom/cn';
import { cva, type VariantProps } from 'class-variance-authority';
import { focusRings } from './_common/focusRing';

// Define the style variants using CVA
const checkboxVariants = cva(
  cn(
    focusRings.focus.visible,
    'peer size-5 shrink-0 rounded-sm border border-border-default disabled:cursor-not-allowed disabled:opacity-50',
    'hocus:border-bg-inset',
    'inset-shadow-xs'
  ),
  {
    variants: {
      variant: {
        primary:
          'data-[state=checked]:bg-accent-emphasis data-[state=checked]:text-fg-default',
        primaryAlt:
          'text-white aria-disabled:bg-brand-900/50 data-[state=checked]:bg-brand-900 data-[state=checked]:text-white data-[state=checked]:border-brand-900',
      }
    },
    defaultVariants: {
      variant: 'primary'
    }
  }
);

export interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    VariantProps<typeof checkboxVariants> {}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, variant, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(checkboxVariants({ variant }), className)}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      forceMount
      className={cn(
        'flex items-center justify-center text-current transition-all duration-100',
        'opacity-0 data-[state=checked]:opacity-100',
        'transform scale-0 data-[state=checked]:scale-100'
      )}
    >
      <Check className="size-5 stroke-3" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
