import { maybe } from '@passionware/monads';
import React from 'react';
import { TextInputProps } from 'v1/components/shared/byType/text/TextInput/__types__/TextInput';
import { htmlStringParser } from 'v1/helpers/misc';
import { Input } from 'v5/design-sytem/Input';
import { InputOverlay } from 'v5/design-sytem/InputOverlay';
import { InlineField } from 'v5/platform/react/InlineField';
import { getV5Props } from '../GetV5Props';

interface UrlInputProps extends Omit<TextInputProps, 'onChange'> {
  showOverlay?: boolean;
  onChange?(value: string | null): void;
}

const noop = () => {};

const UrlInput = ({
  placeholder,
  value,
  showOverlay = true,
  onChange = noop,
  onBlur,
  overlayAction: _overlayAction,
  overlayLabel: _overlayLabel,
  ...props
}: UrlInputProps) => {
  // Prevent some props being spread
  const { type, ...rest } = props;

  const overlayLabel =
    _overlayLabel ??
    (showOverlay && value ? htmlStringParser(value) : undefined);
  const overlayAction =
    _overlayAction ?? (showOverlay && value ? htmlStringParser(value) : null);

  return (
    <InlineField
      value={value}
      onChange={v => {
        onChange(v);
        onBlur?.(v);
      }}
      changeOnDebounce={false}
    >
      {({ value, onChange, onBlur, onBlurWithin }) => (
        <InputOverlay
          label={overlayLabel}
          action={overlayAction}
          {...getV5Props(rest)}
        >
          <Input
            type="url"
            placeholder={placeholder !== undefined ? placeholder : 'URL'}
            spellCheck={false}
            {...getV5Props(rest)}
            value={maybe.getOrUndefined(value)}
            onChange={e => onChange(e.target.value)}
            onBlur={onBlur}
            data-order={props['data-order']}
          />
        </InputOverlay>
      )}
    </InlineField>
  );
};

export default UrlInput;
