import React from 'react';
import PropTypes from 'prop-types';
import { RESOURCE_TYPE_ICONS } from 'v1/helpers/iconSets';
import { useTranslation } from 'react-i18next';
import { SquareDashed } from 'lucide-react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue
} from 'v5/design-sytem/Select.js';

const SelectResourceType = ({
  resourceTypes,
  activeResourceType,
  onSelect
}) => {
  const { t } = useTranslation('core');
  return (
    <div className="SelectResourceType stack-S tailwind-root">
      <div className="text-13-600 stack-XS">
        {t('core_fields.resource_type_id')}
      </div>
      <Select
        value={activeResourceType?.id ?? '~all'}
        onValueChange={value => {
          onSelect(
            value === '~all' ? null : resourceTypes.find(rt => rt.id === value)
          );
        }}
      >
        <SelectTrigger size="sm">
          <SelectValue
            placeholder={
              <>
                {activeResourceType ? (
                  <img
                    src={`/images/${
                      RESOURCE_TYPE_ICONS[activeResourceType.icon].black
                    }`}
                    alt=""
                  />
                ) : (
                  <img
                    src={`/images/${RESOURCE_TYPE_ICONS['default'].black}`}
                    alt=""
                  />
                )}
                <div>
                  {activeResourceType
                    ? activeResourceType.name
                    : t('core_fields.resource_type_id')}
                </div>
              </>
            }
          />
        </SelectTrigger>
        <SelectContent>
          <SelectItem value={'~all'} size="lg">
            <SquareDashed />
            {t('core_fields.all')}
          </SelectItem>
          <SelectSeparator />
          {resourceTypes.map(resourceType => (
            <SelectItem
              key={resourceType.id}
              onClick={() => {
                onSelect(resourceType);
              }}
              size="lg"
              value={resourceType.id}
            >
              <img
                src={`/images/${RESOURCE_TYPE_ICONS[resourceType.icon].black}`}
                alt=""
              />
              <div>{resourceType.name}</div>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

SelectResourceType.propTypes = {
  resourceTypes: PropTypes.array,
  activeResourceType: PropTypes.object,
  onSelect: PropTypes.func // (resourceType)
};

export default SelectResourceType;
