import { maybe } from '@passionware/monads';
import React from 'react';
import { TextInputProps } from 'v1/components/shared/byType/text/TextInput/__types__/TextInput';
import { Input } from 'v5/design-sytem/Input';
import { InlineField } from 'v5/platform/react/InlineField';
import { getV5Props } from '../../text/GetV5Props';

interface NumberInputProps extends Omit<TextInputProps, 'onChange' | 'onBlur'> {
  onChange?(value: number | null): void;
  onBlur?(value: number | null): void;
}

const NumberInput = ({
  placeholder,
  value,
  onChange,
  onBlur,
  ...props
}: NumberInputProps) => {
  // Prevent some props being spread
  const { type, ...rest } = props;

  // Convert numeric value to a display string.
  const displayValue = (num: string | number | null | undefined): string =>
    num != null ? String(num) : '';

  // Parse the display string into a number (or null)
  const parseValue = (str: string): number | null =>
    str ? parseFloat(str) : null;

  return (
    <InlineField
      value={displayValue(value)}
      onChange={(v: string) => {
        onChange?.(parseValue(v));
        onBlur?.(parseValue(v));
      }}
      changeOnDebounce={false}
    >
      {({ value, onChange, onBlur, onBlurWithin }) => (
        <Input
          type="number"
          placeholder={placeholder !== undefined ? placeholder : '0'}
          autoComplete="off"
          {...getV5Props(rest)}
          value={maybe.getOrUndefined(value)}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          data-order={props['data-order']}
        />
      )}
    </InlineField>
  );
};

export default NumberInput;
