import {
  createCalendar,
  fromDate,
  getLocalTimeZone
} from '@internationalized/date';

import { Maybe, maybe } from '@passionware/monads';
import parser from 'any-date-parser';
import { cva } from 'class-variance-authority';
import mergeRefs from 'merge-refs';
import { isMoment } from 'moment-timezone';
import React, { forwardRef, useRef } from 'react';
import { useDateField, useDateSegment } from 'react-aria';
import { useTranslation } from 'react-i18next';

import {
  DateFieldState,
  DateFieldStateOptions,
  type DateSegment,
  useDateFieldState
} from 'react-stately';
import { cn } from 'v5/platform/dom/cn';
import { InputSize, InputVariant } from '../Input';

export interface DateInputProps {
  value: Maybe<Date>;
  onValueChange: (value: Maybe<Date>) => void;
  size?: InputSize;
  variant?: InputVariant;
  className?: string;
  placeholder?: string;
  granularity?: DateFieldStateOptions['granularity'] | 'year' | 'month';
}

const inputVariants = cva(['gap-0 flex items-center self-center'], {
  variants: {
    size: {
      xs: 'xgap-px',
      sm: 'xgap-0.5',
      md: 'xgap-0.5',
      lg: 'xgap-1',
      xl: 'xgap-1'
    }
  }
});

const segmentVariants = cva(['focus:outline-none focus:bg-brand-200/50'], {
  variants: {
    size: {
      xs: 'text-sm rounded-sm px-0.5 -mx-0.5',
      sm: 'text-base py-0.5 rounded-sm px-0.5 -mx-0.5',
      md: 'text-base py-0.5 rounded-sm px-1 -mx-1',
      lg: 'text-lg py-1 rounded px-1 -mx-1',
      xl: 'text-xl py-2 rounded px-1 -mx-1'
    },
    segment: {
      separator: 'z-1 whitespace-pre pointer-events-none',
      text: 'xmx-0'
    }
  }
});

export const DateInput = forwardRef<HTMLDivElement, DateInputProps>(
  (
    {
      variant,
      size,
      value,
      onValueChange,
      className,
      granularity,
      placeholder,
      ...rest
    },
    outerRef
  ) => {
    const { i18n } = useTranslation();

    const state = useDateFieldState({
      locale: i18n.language ?? 'en',
      createCalendar,
      granularity,
      hideTimeZone: true,
      shouldForceLeadingZeros: true,
      value: maybe.map(value, x =>
        fromDate(isMoment(x) ? x.toDate() : x, getLocalTimeZone())
      ),
      onChange: val => {
        const jsDate = val?.toDate(getLocalTimeZone());
        onValueChange?.(jsDate);
      }
    } as DateFieldStateOptions<any>);

    const ref = useRef(null);
    const { fieldProps } = useDateField(
      {
        'aria-label': 'date input',
        ...rest
      },
      state,
      ref
    );

    const finalRef = mergeRefs(ref, outerRef);

    return (
      <div
        {...fieldProps}
        ref={finalRef}
        className={cn(inputVariants({ size, className }))}
        onCopy={e => {
          // set clipboard with well formatted date
          const valueToCopy = state.formatValue({});
          if (valueToCopy) {
            e.clipboardData.clearData();
            e.clipboardData.setData('text/plain', valueToCopy);
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onPaste={e => {
          const value = e.clipboardData.getData('text/plain');
          if (value) {
            try {
              state.setValue(
                fromDate(
                  parser.fromString(value, i18n.language),
                  getLocalTimeZone()
                )
              );
            } catch (e) {
              console.warn('Cannot paste value', e);
            }
          }
        }}
      >
        {state.segments.map((segment, i) => (
          <DateSegment key={i} segment={segment} state={state} size={size} />
        ))}
      </div>
    );
  }
);
function DateSegment({
  segment,
  state,
  size
}: {
  segment: DateSegment;
  state: DateFieldState;
  size: InputSize;
}) {
  const ref = React.useRef(null);
  const { segmentProps } = useDateSegment(segment, state, ref);

  return (
    <div
      {...segmentProps}
      ref={ref}
      className={cn(
        segmentVariants({
          size,
          segment: segment.type === 'literal' ? 'separator' : 'text'
        })
      )}
    >
      {segment.text}
    </div>
  );
}
