import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Button } from 'v1/components/shared';
import { cn } from 'v5/platform/dom/cn';

import classnames from 'classnames';
import './ButtonWithDropdown.scss';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from 'v5/design-sytem/DropdownMenu';
import { focusRings } from '../../../../../v5/design-sytem/_common/focusRing.js';

/**
 * Existing usages:
 * <ButtonWithDropdown
 *           className="btn btn-primary"
 *           arrowShade="light"
 *           loading={contacts.loading === 'CREATE_CONTACT'}
 *           actions={[
 *             {
 *               text: 'Save',
 *               action: submitForm
 *             },
 *             {
 *               text: 'Save and go to profile',
 *               icon: '/images/icon_arrow_top_right.svg',
 *               action: submitFormAndGoToProfile
 *             }
 *           ]}
 *         />
 */
export default class ButtonWithDropdown extends Component {
  renderDropdown = () => {
    const { actions: _actions = [], arrowShade } = this.props;
    const actions = _actions.slice(1);
    return (
      <>
        <DropdownMenu>
          <DropdownMenuTrigger
            className={cn(
              'bg-transparent shrink-0 flex items-center px-4.5 justify-center *:size-4 border-l border-l-current/30',
              'rounded-r-sm',
              focusRings.focus.visible
            )}
          >
            {arrowShade === 'dark' ? (
              <img src="/images/icon_arrow_down_gray.svg" alt="" />
            ) : (
              <img src="/images/icon_arrow_down_white.svg" alt="" />
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent side="bottom" align="end">
            {actions.map((link, index) => (
              <DropdownMenuItem size="lg" key={index} onSelect={link.action}>
                {link.icon && <img src={link.icon} alt="" />}
                <div>{link.text}</div>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
        {/*<Dropdown*/}
        {/*  buttonIcon={*/}
        {/*    arrowShade === 'dark' ? (*/}
        {/*      <img src="/images/icon_arrow_down_gray.svg" alt="" />*/}
        {/*    ) : (*/}
        {/*      <img src="/images/icon_arrow_down_white.svg" alt="" />*/}
        {/*    )*/}
        {/*  }*/}
        {/*  buttonClass="ButtonWithDropdown-dropdown"*/}
        {/*>*/}
        {/*  {actions.map((link, index) => (*/}
        {/*    <MenuItem key={`${index}-${link.text}`} onSelect={link.action}>*/}
        {/*      <span className="MenuItem-icon">*/}
        {/*        {link.icon && <img src={link.icon} alt="" />}*/}
        {/*      </span>*/}
        {/*      <span className="MenuItem-label">{link.text}</span>*/}
        {/*    </MenuItem>*/}
        {/*  ))}*/}
        {/*</Dropdown>*/}
      </>
    );
  };
  renderPrimaryButton = () => {
    const { loading, actions, disabled } = this.props;
    const primaryButton = actions[0];

    return (
      <Button
        onClick={primaryButton.action}
        className={
          'ButtonWithDropdown-primaryButton ' +
          focusRings.focus.visible +
          ' rounded-l-sm'
        }
        loading={loading}
        disabled={disabled}
        stopPropagation
      >
        {primaryButton.text}
      </Button>
    );
  };
  render() {
    const { actions, disabled, loading } = this.props;
    return (
      <div
        className={classnames([
          'ButtonWithDropdown',
          'tailwind-root',
          this.props.className,
          { disabled: disabled || loading }
        ])}
      >
        {this.renderPrimaryButton()}
        {actions.length > 1 && this.renderDropdown()}
      </div>
    );
  }
}

ButtonWithDropdown.propTypes = {
  className: PropTypes.string, // Uses button classes - 'btn btn-default' etc
  actions: PropTypes.array.isRequired,
  /* TODO: actions[0] is required, and is a primary button with shape:
			{
				action
				text
			}
		*/
  // TODO: rest is array of 'links' as passed to DropdownButton
  arrowShade: PropTypes.string, // 'dark', else defaults to white
  // TODO: the following (loading, disabled) are passed to Button, so duplicate fttb
  loading: PropTypes.any, // TODO: type - passed to button but not used in Button?
  disabled: PropTypes.bool
};
