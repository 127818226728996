import { rd } from '@passionware/monads';
import { lazy, Suspense } from 'react';
import { myServices } from '../../../../../v5/services/services.connected.app.js';
import Loading from '../../../shared/placeholders/Loading/Loading';

const NewResourcePane = lazy(
  () => import('v4/pages/resources/pane/ResourcePane')
);
const OldResourcePane = lazy(
  () =>
    import(
      'v1/components/feature/ResourceComponents/ResourcePane/ResourcePane.old'
    )
);

export default props => {
  const release = myServices.featureService.useFeature('resource-pane');

  switch (rd.tryGet(release)) {
    case 'new':
      return (
        <Suspense fallback={<Loading />}>
          <NewResourcePane {...props} />
        </Suspense>
      );
    case 'old':
      return (
        <Suspense fallback={<Loading />}>
          <OldResourcePane {...props} />
        </Suspense>
      );
    default:
      return null;
  }
};
