import { rd } from '@passionware/monads';
import {
  FeatureFlagFinalSource,
  FeatureFlagSource,
  FeatureFlagValues,
  FeatureGroupDefinition
} from '../feature-management';

export function withDefaultValues<Config extends FeatureGroupDefinition>(
  config: Config
) {
  return function createFeatureSource(
    source: FeatureFlagSource<Config>
  ): FeatureFlagFinalSource<Config> {
    return {
      useFeatureFlags: () => {
        const remoteData = source.useFeatureFlags();
        return rd.useMemoMap(
          remoteData,
          (flags, config) => ({
            ...config.groups.reduce((acc, group) => {
              group.entries.forEach(entry => {
                acc[entry.id] = entry.flagSpec.defaultValue;
              });
              return acc;
            }, {} as FeatureFlagValues<Config>),
            ...flags
          }),
          config
        );
      }
    };
  };
}
