export type V1Size = 'XS' | 'S' | 'M' | 'L' | 'XL';
export type V1Appearance = 'outline' | 'underline' | 'silent';
export type V1InputComponentProps = {
  className?: string;
  appearance?: V1Appearance;
  size?: V1Size;
  value: any;
  disabled: boolean | undefined;
  onFocus(): void;
  onBlur(value: any): void;
  onChange(value: any): void;
};

/**
 * Migration bridge for v1 input component props
 * @param props
 */
export function getV5Props({
  size,
  appearance
}: Pick<V1InputComponentProps, 'size' | 'appearance'>) {
  return {
    // we do not want to get v1 input styles through the className
    className: '',
    size:
      (
        {
          XS: 'xs',
          S: 'sm',
          M: 'md',
          L: 'lg',
          XL: 'xl'
        } as const
      )[size ?? 'L'] || 'md',
    variant:
      (
        {
          outline: 'regular',
          silent: 'plain',
          underline: 'inline'
        } as const
      )[appearance ?? 'outline'] || 'regular'
  };
}
