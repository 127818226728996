// todo: shadcn calendar, and tess picker? then use react aria for better field manual input
// also exclude segmetns from tab order
// use field group with separate button (maybe tabIndex=-1)

import { eachDayOfInterval, isSameDay } from 'date-fns';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { cn } from 'v5/platform/dom/cn';
import { buttonVariants } from './Button';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function isDateRangeInList(startDate: Date, endDate: Date, dateList: Date[]) {
  let datesInRange = eachDayOfInterval({ start: startDate, end: endDate });

  return datesInRange.some(rangeDate =>
    dateList.some(listDate => isSameDay(rangeDate, listDate))
  );
}

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        month: 'space-y-4',
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-y-0 relative',
        month_caption: 'flex justify-center pt-1 relative items-center',
        month_grid: 'w-full border-collapse space-y-1',
        caption_label: 'text-sm font-medium',
        nav: 'flex items-center justify-between absolute inset-x-0',
        button_previous: cn(
          buttonVariants({ variant: 'secondary' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 z-10'
        ),
        button_next: cn(
          buttonVariants({ variant: 'secondary' }),
          'h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100 z-10'
        ),
        weeks: 'w-full border-collapse space-y-',
        weekdays: 'flex',
        weekday:
          'text-gray-500 rounded-md w-9 font-normal text-[0.8rem] text-center',
        week: 'flex w-full mt-2',
        day_button:
          'h-9 w-9 text-center text-sm p-0 relative [&:has([aria-selected].day-outside)]:bg-brand-100 [&:has([aria-selected])]:bg-brand-200  focus-within:relative focus-within:z-20',
        day: cn(
          buttonVariants({ variant: 'ghost' }),
          'h-9 w-9 p-0 font-normal aria-selected:opacity-100'
        ),

        today: 'bg-brand-100 text-brand-900 hocus:bg-brand-200!',
        selected:
          'bg-brand-500 text-white border-none aria-selected:hocus:bg-brand-600',
        range_end:
          'rounded-none rounded-r-sm aria-selected:hocus:bg-brand-600 aria-selected:hocus:text-white',
        range_start:
          'rounded-none rounded-l-sm aria-selected:hocus:bg-brand-600 aria-selected:hocus:text-white',
        range_middle:
          'aria-selected:bg-brand-100 aria-selected:text-brand-900 aria-selected:hocus:bg-brand-200 aria-selected:hocus:text-white rounded-none',
        outside:
          'day-outside text-fg-muted opacity-50 aria-selected:bg-brand-100 aria-selected:text-fg-muted aria-selected:opacity-30',
        disabled: 'text-fg-muted opacity-50',

        hidden: 'invisible',
        ...classNames
      }}
      modifiersClassNames={{
        hoverRange: 'bg-brand-200'
      }}
      components={{
        Chevron: ({ ...props }) =>
          props.orientation === 'left' ? (
            <ChevronLeft {...props} className="h-4 w-4" />
          ) : (
            <ChevronRight {...props} className="h-4 w-4" />
          )
      }}
      {...props}
    />
  );
}
Calendar.displayName = 'Calendar';

export { Calendar };
