import { rd, RemoteData } from '@passionware/monads';
import { Check, Plus } from 'lucide-react';
import { cloneElement, ReactElement, ReactNode, useState } from 'react';
import { cn } from 'v5/platform/dom/cn';
import { DimmedContainer } from '../../v4/shared/components/forms/input/primitvies/DimmedContainer';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandLoading
} from '../design-sytem/Command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from '../design-sytem/Popover';

export interface ItemSelectorProps {
  config: {
    // use injectConfig
    useOptions: (
      query: string
    ) => RemoteData<
      { id: string; label: string; disabled?: boolean; content?: ReactNode }[]
    >; // tbd if <T> with resolvers
    useCreateContent?: (
      query: string,
      options: RemoteData<{ id: string; label: string }[]>
    ) => ReactNode;
    useEmptyContent?: (query: string) => ReactNode;
  };
  placeholder?: string;
  children: ReactElement;
  allowCreate?: boolean;
  allowSearch?: boolean;
  closeOnSelect?: boolean;
  onCreate?: (query: string) => Promise<void>;
  onSelect?: (id: string) => void;
  onBackspaceWhenEmptyQuery?: () => void;
  selectedOptions?: string[];
}

// todo hide create button when no query or same option exists

export function useDefaultCreateContent(
  query: string,
  options: RemoteData<{ label: string }[]>,
  createContent: ReactNode
) {
  if (query.trim() === '') {
    return null;
  }
  if (
    options.status === 'success' &&
    options.data.some(x => x.label === query)
  ) {
    return null;
  }
  return createContent;
}

const useData = (query: string, config: ItemSelectorProps['config']) => {
  const options = config.useOptions(query);
  const defaultCreateContent = useDefaultCreateContent(
    query,
    options,
    <>
      Create <strong>{query}</strong>
    </>
  );
  const createContent =
    config.useCreateContent?.(query, options) ?? defaultCreateContent;
  return { options, createContent };
};

export function ItemSelector(props: ItemSelectorProps) {
  const useOptions = (query: string) => useData(query, props.config);
  const allowSearch = props.allowSearch ?? true;

  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const options = useOptions(query);
  const emptyContent = props.config.useEmptyContent?.(query);
  return (
    <>
      <Popover
        open={open}
        onOpenChange={value => {
          setOpen(value);
          (window as any).atellioV1PreventNextPortalCloseOnEscape = true;
        }}
      >
        <PopoverTrigger asChild>
          {cloneElement(props.children, {
            onClick: () => {} // we want to prevent default click, as we want to open the popover
          })}
        </PopoverTrigger>
        <PopoverContent className="p-0" align="start" side="bottom">
          <Command shouldFilter={false} size="xs">
            {allowSearch && (
              <CommandInput
                placeholder={props.placeholder ?? 'Search...'}
                value={query}
                onValueChange={setQuery}
                onKeyDown={e => {
                  if (e.key === 'Escape') {
                    (window as any).atellioV1PreventNextPortalCloseOnEscape =
                      true;
                  }
                  if (e.key === 'Backspace' && query === '') {
                    props.onBackspaceWhenEmptyQuery?.();
                  }
                }}
              />
            )}
            <CommandList>
              <DimmedContainer
                shouldDim={rd.isPlaceholderData(options.options)}
              >
                {rd
                  .journey(options.options)
                  .wait(<CommandLoading />)
                  .catch(() => <div className="text-red-500">Error</div>)
                  .map(readyOptions => {
                    if (readyOptions.length === 0) {
                      return (
                        <CommandEmpty
                          className={emptyContent ? 'p-0 text-left' : ''}
                        >
                          {emptyContent ?? 'No options available.'}
                        </CommandEmpty>
                      );
                    }
                    return (
                      <CommandGroup>
                        {readyOptions.map(option => (
                          <CommandItem
                            key={option.id}
                            value={option.id}
                            disabled={option.disabled}
                            onSelect={currentValue => {
                              props.onSelect?.(currentValue);
                              if (props.closeOnSelect !== false) {
                                setOpen(false);
                                setQuery('');
                              }
                            }}
                          >
                            <Check
                              className={cn(
                                'mr-2 h-4 w-4',
                                props.selectedOptions?.includes(option.id)
                                  ? 'opacity-100'
                                  : 'opacity-0'
                              )}
                            />
                            {option.content ?? option.label}
                          </CommandItem>
                        ))}
                      </CommandGroup>
                    );
                  })}
              </DimmedContainer>
              {query && props.allowCreate && options.createContent ? (
                <>
                  <CommandGroup
                    className={rd.mapOrElse(
                      options.options,
                      o =>
                        o.length > 0
                          ? 'border-t border-border-default'
                          : undefined,
                      undefined
                    )}
                  >
                    <CommandItem
                      key="-create"
                      onSelect={() => {
                        props.onCreate?.(query);
                        if (props.closeOnSelect !== false) {
                          setOpen(false);
                        }
                        setQuery('');
                      }}
                    >
                      <Plus className={cn('mr-2 h-4 w-4')} />
                      {options.createContent}
                    </CommandItem>
                  </CommandGroup>
                </>
              ) : null}
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>

      {/*TODO allow create*/}
    </>
  );
}
