import { myFeatureManagementService } from './front/FeatureManagementService/FeatureManagementService.connected';
import { myFeatureService } from './front/FeatureService/FeatureService.connected';
import { myToastService } from './front/ToastService/ToastService.connected';
import { createUserFlowService } from './front/UserFlowService/UserFlowService.impl';
import { myGlobalSearchService } from './GlobalSearchService/GlobalSearchService.conneted';
import { createMessageService } from './internal/MessageService/MessageService.impl';
import { createMutationService } from './internal/MutationService/MutationService.impl';
import { myArrivalListService } from './io/ArrivalListService/ArrivalListService.connected';
import { myEventService } from './io/EventService/EventService.connected';
import { myFrontDeskService } from './io/FrontDeskService/FrontDeskService.connected';
import { myStudioService } from './io/StudioService/StudioService.connected';
import { myTagsService } from './io/TagsService/TagsService.connected';
import { myLocaleService } from './LocaleService/LocaleService.connected';

const mutationService = createMutationService();
const messageService = createMessageService();

/**
 * Stable reference to all services in the app.
 * This is used to inject services into components.
 * In the greenfield code, we have a root widget that receives stable services reference and pass it transparently down to all widgets.
 */
export const myServices = {
  arrivalListService: myArrivalListService,
  studioService: myStudioService,
  tagsService: myTagsService,
  frontDeskService: myFrontDeskService,
  eventService: myEventService,
  localeService: myLocaleService,
  messageService,
  toastService: myToastService,
  userFlowService: createUserFlowService({
    services: {
      toastService: myToastService,
      mutationService,
      messageService
    }
  }),
  featureService: myFeatureService,
  globalSearchService: myGlobalSearchService,
  featureManagementService: myFeatureManagementService
};
