import { rd } from '@passionware/monads';
import {
  FeatureFlagSource,
  FeatureGroupDefinition
} from '../feature-management';

export function createFeatureSourceChain<Config extends FeatureGroupDefinition>(
  sources: FeatureFlagSource<Config>[]
): FeatureFlagSource<Config> {
  return {
    useFeatureFlags: () => {
      const remoteDatas = sources.map(source => source.useFeatureFlags());
      const combined = rd.combineAll(remoteDatas);
      return rd.useMemoMap(combined, sourceValues => {
        return Object.assign({}, ...sourceValues);
      });
    }
  };
}
