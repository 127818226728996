import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { useTranslation } from 'react-i18next';

import { getBookingsStatuses } from 'store/v1/statuses/statuses.selectors.js';
import { isoToFriendlyDate } from 'v1/helpers/byType/dateHelper';
import { isAvailabilityStatus } from 'v1/helpers/byModel/StatusHelper';

import { DateRangeInput, StatusSelectorMultiple } from 'v1/components/shared';

import './SearchAvailabilityInput.scss';
import { Button } from 'v5/design-sytem/Button.js';

const SearchAvailabilityInput = ({ label, dates = {}, onChange }) => {
  const { t } = useTranslation('v1_shared');
  const statuses = useSelector(getBookingsStatuses);
  const [expanded, setExpand] = useState(false);
  const [stateDate, setDate] = useState(dates);

  const getDefaultStatusQuery = () =>
    statuses
      .filter(s => s.status_type === 'CONFIRMED' || isAvailabilityStatus(s))
      .map(s => s.id);

  const [activeStatuses, setActiveStatuses] = useState(
    dates.statuses || getDefaultStatusQuery()
  );

  const onDateChange = (type, value) => {
    const dates = {
      status: type === 'status' ? value : activeStatuses,
      from_date: type === 'start' ? value : stateDate.from_date,
      to_date: type === 'end' ? value : stateDate.to_date
    };

    setDate(dates);
    if (dates.from_date && dates.to_date) {
      onChange(dates);
    }
  };

  const clearDates = () => {
    setDate({});
    onChange(null);
    setExpand(false);
  };

  useEffect(() => {
    onDateChange('status', activeStatuses);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStatuses]);

  const renderBookingStatusDropdown = () => (
    <StatusSelectorMultiple
      statuses={statuses}
      onStatusChange={selectedStatuses => setActiveStatuses(selectedStatuses)}
      activeStatuses={activeStatuses}
    />
  );

  return (
    <div className="tailwind contents">
      <div className="flex flex-col items-stretch gap-2">
        <div className="flex flex-row justify-between">
          <div className="text-fg-default">
            {label || t('SearchAvailabilityInput.availability')}
          </div>
          {!isEmpty(stateDate.from_date) && (
            <Button
              size="sm"
              variant="ghost"
              className="underline -mb-4"
              onClick={clearDates}
            >
              {t('SearchAvailabilityInput.clear')}
            </Button>
          )}
        </div>
        {expanded ? (
          <>
            <DateRangeInput
              changeOnDebounce
              size="S"
              labels={{
                start: t('SearchAvailabilityInput.from'),
                end: t('SearchAvailabilityInput.to')
              }}
              dateFormat="eee dd MMM"
              placeholder={t('SearchAvailabilityInput.date')}
              onChange={onDateChange}
              startDate={stateDate.from_date}
              endDate={stateDate.to_date}
              appearance="outline"
            />
            {renderBookingStatusDropdown()}
          </>
        ) : (
          <Button
            size="sm"
            variant="secondary"
            className="w-auto self-start"
            onClick={() => setExpand(!expanded)}
          >
            <div className="truncate">
              {isEmpty(stateDate.from_date)
                ? t('SearchAvailabilityInput.selectDates')
                : `${isoToFriendlyDate(
                    { date1: stateDate.from_date, date2: stateDate.to_date },
                    'DEFAULT_RANGE',
                    'SHORT'
                  )}`}
            </div>
          </Button>
        )}
      </div>
    </div>
  );
};

SearchAvailabilityInput.propTypes = {
  label: PropTypes.string,
  dates: PropTypes.shape({
    from_date: PropTypes.string,
    to_date: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired // (dates)
};

export default SearchAvailabilityInput;
