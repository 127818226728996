import { maybe } from '@passionware/monads';
import classnames from 'classnames';
import React from 'react';
import { TextInputProps } from 'v1/components/shared/byType/text/TextInput/__types__/TextInput';

import './TimeInput.scss';
import { Input } from 'v5/design-sytem/Input';
import { InlineField } from 'v5/platform/react/InlineField';
import { getV5Props } from '../../text/GetV5Props';

interface TimeInputProps extends Omit<TextInputProps, 'onChange' | 'value'> {
  value?: string;
  onChange?(value: string | null): void;
}

const TimeInput = ({
  className,
  value,
  onChange,
  onBlur,
  ...props
}: TimeInputProps) => {
  // Prevent some props being spread
  const { type, ...rest } = props;

  return (
    <InlineField
      value={value}
      onChange={v => {
        onChange?.(maybe.getOrNull(v));
        onBlur?.(maybe.getOrNull(v));
      }}
      changeOnDebounce={false}
    >
      {({ value, onChange, onBlur, onBlurWithin }) => (
        <Input
          type="time"
          placeholder={
            props.placeholder !== undefined ? props.placeholder : 'Time'
          }
          autoComplete="off"
          {...getV5Props(rest)}
          className={classnames('TimeInput w-fit', className)}
          value={maybe.getOrUndefined(value)}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          data-order={props['data-order']}
        />
      )}
    </InlineField>
  );
};

export default TimeInput;
