import { Maybe } from '@passionware/monads';
import Color from 'color';
import React, { ComponentProps } from 'react';
import { cn } from '../platform/dom/cn';

export interface ColorDotProps extends Omit<ComponentProps<'div'>, 'color'> {
  color: Maybe<string>;
}

export const ColorDot = ({
  color,
  className,
  style,
  ...props
}: ColorDotProps) => {
  return (
    <div
      className={cn('rounded-full shrink-0 size-3 border', className)}
      style={{
        backgroundColor: color ?? 'white',
        borderColor:
          Color(color).contrast(Color('white')) < 2
            ? 'rgba(0, 0, 0, 0.2)'
            : 'transparent',
        ...style
      }}
      {...props}
    />
  );
};
