import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import ReactDOM from 'react-dom';

import { SingleCheckbox, DateRangeInput } from 'v1/components/shared';

import './DateBetweenFilter.scss';
import { Checkbox } from '../../../../../../v5/design-sytem/Checkbox.js';

const DEFAULT_VALUES = {
  start: '',
  end: ''
};

const DateBetweenFilter = ({
  param,
  index,
  filter,
  filterChanged,
  removeFilter
}) => {
  const containerRef = useRef(null);
  const notInitialRender = useRef(false);
  const [showRange, setShowRange] = useState(!!filter?.gte && !!filter?.lte);
  const [values, setValues] = useState({
    start: filter?.gte,
    end: filter?.lte
  });

  useEffect(() => {
    if (notInitialRender.current && values.start && values.end) {
      filterChanged(values);
    } else {
      notInitialRender.current = true;
    }
  }, [values]);

  const handleRangeClicked = () => {
    if (showRange) {
      setShowRange(false);
      setValues(DEFAULT_VALUES);
      removeFilter();
    } else {
      setShowRange(true);
    }
  };

  return (
    <div className="DateBetweenFilter" ref={containerRef}>
      <div
        key={index}
        className="DropdownButton-option"
        onClick={handleRangeClicked}
      >
        <Checkbox
          variant="primaryAlt"
          onCheckedChange={handleRangeClicked}
          checked={showRange}
        />
        <span className="DropdownButton-option-label">{param.label}</span>
      </div>
      {showRange ? (
        <div className="DateBetweenFilter-wrapper">
          <div className="form-label">Between</div>
          <DateRangeInput
            dateFormat="do MMMM"
            placeholderStart="Start date"
            placeholderEnd="End date"
            // v5 mode
            onValueChange={range => {
              const start = range.from + ' 00:00:00';
              const end = range.to + ' 23:59:59';
              setValues({
                start,
                end
              });
            }}
            startDate={
              values.start ? moment(values.start).format('YYYY-MM-DD') : ''
            }
            endDate={values.end ? moment(values.end).format('YYYY-MM-DD') : ''}
            size="S"
            changeOnDebounce
          />
        </div>
      ) : null}
    </div>
  );
};

export default DateBetweenFilter;
