import { XMarkIcon } from '@heroicons/react/24/outline/index';
import classNames from 'classnames';
import {
  ButtonHTMLAttributes,
  forwardRef,
  KeyboardEvent,
  MouseEvent,
  ReactNode
} from 'react';
import { Overwrite } from 'v5/platform/typescript';
import Icon from '../../shared/general/Icon/Icon';
import { EditableInputMode } from './types';

export type PillStyleVariant = 'default' | 'subtle' | 'highlighted';

export type PillProps = Overwrite<
  ButtonHTMLAttributes<HTMLButtonElement>,
  {
    mode: EditableInputMode;
    children: ReactNode;
    onRemove?: (event: MouseEvent | KeyboardEvent) => void;
    styleVariant?: PillStyleVariant;
    radius?: 'full' | 'sm';
    draggable?: boolean;
  }
>;

export const Pill = forwardRef<HTMLButtonElement, PillProps>(
  (
    {
      styleVariant = 'default',
      radius = 'full',
      mode,
      onRemove,
      className,
      children,
      draggable = true,
      ...rest
    },
    ref
  ) => (
    <button
      type="button"
      tabIndex={0} // focusable element
      className={classNames(
        'group/pill',
        'border-none p-0',
        'transition duration-200',
        'text-base font-sans font-semibold flex flex-row items-center ring-1',
        {
          full: 'rounded-full',
          sm: 'rounded'
        }[radius],
        {
          default: 'ring-border-default dark:ring-gray-700',
          subtle: 'ring-border-default [&_*]:text-fg-default bg-bg-subtle',
          highlighted: 'ring-border-muted dark:ring-gray-500'
        }[styleVariant],
        // focus ring
        'outline-none focus:outline-none ring-1',
        mode === 'edit'
          ? 'focus:ring-fg-default dark:focus:ring-gray-300'
          : 'focus-visible:ring-fg-default dark:focus-visible:ring-gray-300',
        // hover in display mode
        mode === 'display'
          ? 'text-fg-subtle dark:text-gray-800'
          : 'text-fg-default dark:text-gray-300',
        // background
        {
          default:
            'bg-bg-default dark:bg-gray-800 hover:bg-gray-50 active:bg-gray-100',
          subtle:
            'bg-bg-default dark:bg-gray-800 hover:bg-gray-50 active:bg-gray-100',
          highlighted:
            'bg-bg-subtle dark:bg-gray-700 hover:bg-gray-50 active:bg-gray-100'
        }[styleVariant],
        // text selection
        'select-none',
        className,
        !draggable && 'pl-2'
      )}
      ref={ref}
      {...rest}
      onKeyDown={e => {
        if (mode === 'edit' && e.key === 'Backspace') {
          onRemove?.(e);
        } else if (e.key === 'ArrowLeft') {
          const sibling = e.currentTarget.parentElement?.previousElementSibling;
          if (sibling instanceof HTMLElement) {
            if (sibling.firstChild instanceof HTMLElement) {
              sibling.firstChild.focus();
              e.stopPropagation();
            }
          }
        } else if (e.key === 'ArrowRight') {
          const sibling = e.currentTarget.parentElement?.nextElementSibling;
          if (sibling instanceof HTMLElement) {
            if (sibling.firstChild instanceof HTMLElement) {
              sibling.firstChild.focus();
              e.stopPropagation();
            }
          }
        } else {
          rest.onKeyDown?.(e);
        }
      }}
    >
      {draggable && (
        <div
          className={classNames(
            'flex items-center justify-center',
            {
              full: 'rounded-l-full',
              sm: 'rounded-l'
            }[radius],
            'text-fg-subtle dark:text-gray-300 py-sm',
            'group-focus/pill:text-fg-default dark:group-focus/pill:text-gray-300', // focus effect
            'transition-all',
            mode === 'edit' ? 'w-6 pl-lg pr-sm ' : 'opacity-0 w-0 mx-0 px-sm'
          )}
        >
          <Icon name="drag" className={classNames('w-3 h-3')} />
        </div>
      )}
      <div
        className={classNames(
          'min-w-0 px-sm py-sm whitespace-nowrap flex flex-row items-center gap-sm',
          {
            default: 'text-fg-default dark:text-gray-300',
            highlighted: 'text-fg-default dark:text-gray-300',
            subtle: 'text-fg-muted dark:text-gray-400'
          }[styleVariant]
        )}
      >
        {children}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        onClick={e => {
          e.stopPropagation();
          onRemove?.(e);
        }}
        className={classNames(
          'self-stretch flex items-center justify-center',
          {
            full: 'rounded-r-full',
            sm: 'rounded-r'
          }[radius],
          'text-fg-subtle py-sm',
          'hover:bg-bg-subtle dark:hover:bg-gray-700 hover/pill:text-gray-400 dark:hover/pill:text-gray-300', // hover effect
          'group-focus/pill:text-fg-default dark:group-focus/pill:text-gray-200', // focus effect
          mode === 'edit'
            ? ' w-6 pl-0.5 pr-2 '
            : 'pointer-events-none opacity-0 w-0 mx-0 px-sm'
        )}
      >
        <XMarkIcon className={classNames('w-xl h-xl')} />
      </div>
    </button>
  )
);
