import { mt, rd, RemoteData } from '@passionware/monads';
import { injectConfig, promiseState } from '@passionware/platform-react';
import { cloneElement, ReactNode, useId, useMemo } from 'react';
import { ItemSelector, ItemSelectorProps } from './ItemSelector';
import { renderSpinnerMutation } from './renderSpinnerMutation';
import { TagGroupInput, TagGroupInputProps } from './TagGroupInput';

export type CollectionFieldProps<T> = Pick<
  TagGroupInputProps<string>,
  'draggable' | 'mode' | 'className' | 'onMove' | 'data'
> & {
  config: ItemSelectorProps['config'] & {
    // resolve selected keys to data items
    useSelectedItems: (keys: string[]) => RemoteData<T[]>;
    useResolveById: () => (id: string) => Promise<T>;
    getId: (data: T) => string;
    rowRenderer: (data: T) => ReactNode;
    promptRenderer: (keys: string[]) => ReactNode;
    // function that should execute actual create request, and resolve once it's done
    useCreatePromise: () => (query: string) => Promise<T>;
    useGetGhostItem: () => (query: string) => T;
  };
  onAdd: (tag: T) => void;
  onRemove: (tag: T, index: number) => void;
  allowCreate?: boolean;
  allowSearch?: boolean;
  createButtonOnNewLine?: boolean;
  placeholder?: string;
  contentAfterCreateButton?: ReactNode;
};

export function CollectionField<T>({
  data = [],
  config,
  onAdd,
  onMove,
  onRemove,
  allowCreate = true,
  allowSearch = true,
  createButtonOnNewLine = false,
  contentAfterCreateButton,
  ...props
}: CollectionFieldProps<T>) {
  const items = config.useSelectedItems(data);
  const createPromise = promiseState.useMutation(config.useCreatePromise());
  const buttonId = useId();
  const getGhostItem = config.useGetGhostItem();
  const resolveById = config.useResolveById();

  /**
   * Component below should be defined outside this component.
   * But the limitation is the generic type we can't achieve using injectConfig approach
   * We just need to make sure we don't use any scope variables in the component below
   */
  const CollectionItemSelector = useMemo(
    () =>
      injectConfig(ItemSelector)
        .fromProps<{
          _config: CollectionFieldProps<T>['config'];
          data: CollectionFieldProps<T>['data'];
        }>(api => ({
          ...api.useProps()._config,
          useOptions: query => {
            const props = api.useProps();
            const tagsMatchingQuery = props._config.useOptions(query);
            const tagsToDisplay = rd.useMemoMap(
              tagsMatchingQuery,
              (x, selectedTagIds) =>
                x.filter(x => !selectedTagIds.includes(x.id)),
              props.data
            );
            return rd.useLastWithPlaceholder(tagsToDisplay);
          }
        }))
        .transformProps(x => x.skipFields('_config', 'data')),
    []
  );

  return (
    <TagGroupInput<T>
      {...props}
      data={[
        ...rd.getOrElse(items, []),
        ...(mt.isInProgress(createPromise.state)
          ? [getGhostItem(createPromise.state.request)]
          : [])
      ]}
      getId={config.getId}
      rowRenderer={config.rowRenderer}
      addLabel={
        <div className="flex gap-1 flex-row items-center">
          {config.promptRenderer(data)}
          {renderSpinnerMutation(createPromise.state, undefined, {
            successClass: 'w-4 h-4'
          })}
        </div>
      }
      onPressDownOnTag={e => {
        document.getElementById(buttonId)?.focus();
        // trigger click event on the button
        const event = new MouseEvent('click', {
          view: window,
          bubbles: true,
          cancelable: true
        });
        document.getElementById(buttonId)?.dispatchEvent(event);
      }}
      renderCreateButton={children => (
        <>
          {createButtonOnNewLine && <div className="h-0 basis-full" />}
          <CollectionItemSelector
            _config={config}
            data={rd.mapOrElse(items, x => x.map(config.getId), [])}
            allowCreate={allowCreate}
            allowSearch={allowSearch}
            closeOnSelect={false}
            placeholder={props.placeholder}
            onSelect={async id => {
              const tagObject = await resolveById(id);
              onAdd(tagObject);
            }}
            onCreate={async query => {
              const tagObject = await createPromise.track(query);
              onAdd(tagObject);
            }}
            onBackspaceWhenEmptyQuery={() => {
              rd.map(
                items,
                x => x.length > 0 && onRemove(x[x.length - 1], x.length - 1)
              );
            }}
          >
            {cloneElement(children, {
              id: buttonId,
              onKeyDownCapture: async e => {
                if (e.key === 'Backspace') {
                  rd.map(
                    items,
                    x => x.length > 0 && onRemove(x[x.length - 1], x.length - 1)
                  );
                } else {
                  children.props.onKeyDown?.(e);
                }
              }
            })}
          </CollectionItemSelector>
          {contentAfterCreateButton}
        </>
      )}
      onMove={onMove}
      onRemove={async (id, item) => {
        const tagObject = await resolveById(id);
        onRemove(tagObject, item);
      }}
    />
  );
}
