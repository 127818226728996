import {
  type CSSProperties,
  type ReactNode,
  type UIEvent,
  useEffect,
  useRef,
  useState
} from 'react';
import { cn } from '../platform/dom/cn';

export interface ScrollShadowWrapperProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  // set it to true if you want to style the shadows yourself using data-has-top-shadow
  renderTopShadow?: boolean;
  // set it to true if you want to style the shadows yourself using  data-has-bottom-shadow
  renderBottomShadow?: boolean;
}

export function ScrollShadowWrapper(props: ScrollShadowWrapperProps) {
  const { children, className = '', style = {} } = props;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const updateSizes = () => {
    const el = wrapperRef.current;
    if (el) {
      setScrollTop(el.scrollTop);
      setScrollHeight(el.scrollHeight);
      setClientHeight(el.clientHeight);
    }
  };

  const onScrollHandler = (event: UIEvent<HTMLDivElement>) => {
    setScrollTop(event.currentTarget.scrollTop);
  };

  useEffect(() => {
    updateSizes();

    const observer = new MutationObserver(() => {
      updateSizes();
    });

    const el = wrapperRef.current;
    if (el) {
      observer.observe(el, {
        childList: true,
        subtree: true,
        characterData: true,
        attributes: true,
        attributeFilter: ['style', 'class']
      });

      el.addEventListener('transitionend', updateSizes);
    }

    window.addEventListener('resize', updateSizes);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', updateSizes);
      if (el) el.removeEventListener('transitionend', updateSizes);
    };
  }, [children]);

  const getVisibleSides = (): { top: boolean; bottom: boolean } => {
    const atBottom = scrollTop + clientHeight >= scrollHeight - 1; // tolerance
    const atTop = scrollTop <= 1; // tolerance

    return {
      top: !atTop,
      bottom: !atBottom
    };
  };

  const visibleSides = getVisibleSides();

  return (
    <div
      ref={wrapperRef}
      style={style}
      className={cn('relative overflow-y-auto', className)}
      onScroll={onScrollHandler}
      data-has-top-shadow={visibleSides.top ? '' : undefined}
      data-has-bottom-shadow={visibleSides.bottom ? '' : undefined}
    >
      {props.renderTopShadow !== false && (
        <div
          className={cn(
            'z-1000 pointer-events-none sticky top-0 -mb-1.5 h-1.5 w-full transition-opacity duration-100 ',
            'border-t border-border-default',
            'bg-gradient-to-b from-black/5 to-transparent',
            visibleSides.top ? 'opacity-100' : 'opacity-0'
          )}
        />
      )}

      {children}

      {props.renderBottomShadow !== false && (
        <div
          className={cn(
            'z-1000 pointer-events-none sticky bottom-0 -mt-1.5 h-1.5 w-full transition-opacity duration-100',
            'border-b border-border-default',
            'bg-gradient-to-t from-black/5 to-transparent',
            visibleSides.bottom ? 'opacity-100' : 'opacity-0'
          )}
        />
      )}
    </div>
  );
}
