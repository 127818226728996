import {
  FeatureFlagValues,
  FeatureGroupDefinition
} from './platform/feature-management/feature-management';
import { FeatureCleanupVersionMap } from './platform/feature-management/sources/localStorage';

/**
 * Definition of all Atellio features and their purpose, despite their origin (server or frontend).
 */
export const featureFlags = {
  groups: [
    {
      name: 'Business',
      entries: [
        {
          id: 'localisation',
          name: 'Localisation',
          description: 'Seamlessly manage global teams and projects',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'new-measurement-field',
          name: 'Measurement Field',
          description:
            'Measurement field with support for imperial and metric units',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'calendar',
          name: 'Calendar',
          description: 'Visualize your creative schedules effortlessly',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'resources',
          name: 'Resources',
          description: 'Efficiently allocate studios, equipment, and more',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'productions',
          name: 'Productions',
          description: 'Coordinate your creative productions with clarity',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'shortlists',
          name: 'Shortlists',
          description: 'Curate your top talent picks seamlessly',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'callouts',
          name: 'Callouts',
          description: 'Broadcast project briefs to talent instantly',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'callsheets',
          name: 'Callsheets',
          description: 'Craft beautiful, detailed callsheets in minutes',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'bookings',
          name: 'Bookings',
          description: 'Manage bookings with simplicity and precision',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'budgeting',
          name: 'Budgeting',
          description: 'Track and control creative project budgets',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'financials',
          name: 'Financials',
          description: 'Insightful financial tracking and reporting',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'reporting',
          name: 'Reporting',
          description: 'Powerful analytics for your creative workflow',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'reconciliations',
          name: 'Reconciliations',
          description: 'Effortlessly reconcile invoices and expenses',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'custom-nikefrontdesk',
          name: 'Nike Custom Front Desk',
          description: 'Tailored front desk experience for Nike projects',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'custom-nikearrivalboard',
          name: 'Nike Custom Arrival Board',
          description: 'Interactive arrival boards designed for Nike',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'custom-nikeqrcode',
          name: 'Nike Custom QR Code',
          description: 'Simplified check-ins with Nike-specific QR codes',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'booking_confirmation',
          name: 'Booking Confirmation',
          description: 'Instant, professional booking confirmations',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'tasks',
          name: 'Tasks',
          description: 'Assign and track creative tasks effortlessly',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'custom-walmart',
          name: 'Custom Walmart',
          description: 'Bespoke workflows crafted for Walmart',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'custom-splashlight',
          name: 'Custom Splashlight',
          description: 'Customized project management for Splashlight',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        },
        {
          id: 'custom-shotflow',
          name: 'Custom Shotflow',
          description: 'Integrated workflow solutions with Shotflow',
          flagSpec: {
            type: 'boolean',
            defaultValue: false
          }
        }
      ]
    },
    {
      name: 'Labs',
      entries: [
        {
          id: 'frontdesk',
          name: 'Frontdesk',
          description:
            'Use new improved frontdesk with improved data management',
          flagSpec: {
            type: 'enum',
            options: ['old', 'new'],
            defaultValue: 'new'
          }
        },
        {
          id: 'production-calendar',
          name: 'Production Calendar',
          description: 'Use new production calendar with improved performance',
          flagSpec: {
            type: 'enum',
            options: ['old', 'new'],
            defaultValue: 'new'
          }
        },
        {
          id: 'locale-switcher',
          name: 'Locale Switcher',
          description:
            'Display language switcher that will override your language regardless of the global settings',
          flagSpec: {
            type: 'enum',
            options: ['none', 'new'],
            defaultValue: 'none'
          }
        },
        {
          id: 'resource-pane',
          name: 'Resource Pane',
          description:
            'New resource pane uses improved data management which solves data stickiness issues',
          flagSpec: {
            type: 'enum',
            options: ['old', 'new'],
            defaultValue: 'new'
          }
        },
        {
          id: 'date-picker',
          name: 'Date Picker',
          description: 'New and accessible date picker',
          flagSpec: {
            type: 'enum',
            options: ['old', 'new'],
            defaultValue: 'old'
          }
        }
      ]
    }
  ]
} as const satisfies FeatureGroupDefinition;

/**
 * Extra mapping for localStorage cleanup.
 * The value is a cleanup ID that will be checked against user's local storage saved feature flag value.
 * If the value is different, the localStorage will be cleaned up.
 * That way we can reset user's choice to use specific version of a feature.
 */
export const localStorageCleanupVersionMapping: FeatureCleanupVersionMap<AtellioFeatureGroupDefinition> =
  {
    frontdesk: '2',
    'production-calendar': '2',
    localisation: '1',
    'locale-switcher': '1',
    'resource-pane': '1',
    'date-picker': '1',
    'new-measurement-field': '1'
  };

export const forcedFeatureFlags: Partial<
  FeatureFlagValues<AtellioFeatureGroupDefinition>
> = {
  frontdesk: 'new',
  'production-calendar': 'new'
};

export type AtellioFeatureGroupDefinition = typeof featureFlags;
