import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { find, get } from 'lodash';

import { Capability } from '__types__';
import { RESOURCE_TYPE_ICONS } from 'v1/helpers/iconSets';
import {
  getDefaultResourceType,
  getResourceTypes
} from 'store/v1/contacts/contacts.selectors.js';
import { cn } from 'v5/platform/dom/cn';

import styles from './ResourceTypeSelectorDisplay.module.scss';
import { RestrictedDropdownV5 } from '../../../buttons/RestrictedDropdown/RestrictedDropdown.jsx';
import { DropdownMenuItem } from 'v5/design-sytem/DropdownMenu';

/**
 * @desc A 'display' version of a selector for resource types, for displaying alongside a resource.
 */
const ResourceTypeSelectorDisplay = ({
  label = null,
  size,
  resourceTypeId,
  disabled,
  onChange,
  /**
   * This flag by default keeps the behavior unchanged, but it shouldn't be used to steal internal state by parent component. Violation of unidirectional data flow can cause unpredictable behavior.
   */
  initialOnChange = true
}) => {
  const resourceTypes = useSelector(state => getResourceTypes(state));
  const defaultResourceType = useSelector(getDefaultResourceType);
  const resourceType =
    find(resourceTypes, r => r.id === resourceTypeId) || defaultResourceType;

  const handleChange = id => {
    onChange && onChange(id);
  };

  // TODO: simulating change events is very bad practice, leads to problems with unnecessary calls that are not related to user interaction
  // if removing this code cause a regression we should track original issue down and fix it properly
  useEffect(() => {
    if (!resourceTypeId) {
      if (initialOnChange) {
        handleChange(get(resourceType, 'id'));
      }
    }
  }, []);

  return (
    <div className={styles.ResourceTypeSelectorDisplay + ' tailwind-root'}>
      <RestrictedDropdownV5
        showLabel
        capabilitiesAtLeastOne={[Capability.RESOURCE_UPDATE]}
        trigger={
          <button
            className={cn(
              styles.Selector,
              {
                [styles[`Selector_${size}`]]: size
              },
              'text-left whitespace-nowrap'
            )}
          >
            {label && `${label} `}
            <span className={styles.ResourceType}>
              {get(resourceType, 'name') || 'Resource Type'}
            </span>
          </button>
        }
        disabled={disabled}
      >
        {resourceTypes.map(resourceType => (
          <DropdownMenuItem
            key={resourceType.id}
            capabilities={Capability.RESOURCE_UPDATE}
            onSelect={() => handleChange(resourceType.id)}
            size="lg"
          >
            <img
              src={`/images/${
                RESOURCE_TYPE_ICONS[resourceType.icon || 'default'].black
              }`}
              alt=""
            />
            <span>{resourceType.name}</span>
          </DropdownMenuItem>
        ))}
      </RestrictedDropdownV5>
    </div>
  );
};

ResourceTypeSelectorDisplay.props = {
  label: PropTypes.string,
  size: PropTypes.oneOf(['S', 'L']),
  resourceTypeId: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

export default ResourceTypeSelectorDisplay;
