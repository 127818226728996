import {
  maybe,
  Maybe,
  mt,
  MutationData,
  rd,
  RemoteData
} from '@passionware/monads';
import { createSimpleStore } from '@passionware/simple-store';
import { useMemo, useState } from 'react';
import useLast from 'use-last';
import { AtellioFeatureGroupDefinition } from 'v5/features';
import { FeatureFlagValues } from 'v5/platform/feature-management/feature-management';
import { useChannelMessage } from '../../../platform/messaging/useChannelMessage';
import { WithServices } from '../../../platform/ts/services';
import { WithFeatureService } from '../../../services/front/FeatureService/FeatureService';
import { WithMessageService } from '../../../services/internal/MessageService/MessageService';
import { CustomFieldConfigDialog } from './CustomFieldConfigDialog';
import { FieldDefinition } from './field-definition';

export function CustomFieldConfigDialogWidget(
  props: WithServices<[WithMessageService, WithFeatureService]>
) {
  const featureFlags = props.services.featureService.useFeatureFlags();
  return rd.tryMap(featureFlags, featureFlags => (
    <CustomFieldConfigDialogWidgetInternal {...props} features={featureFlags} />
  ));
}

function CustomFieldConfigDialogWidgetInternal(
  props: WithServices<[WithMessageService]> & {
    features: FeatureFlagValues<AtellioFeatureGroupDefinition>;
  }
) {
  const initialFieldStore = useMemo(
    () => createSimpleStore<RemoteData<Maybe<FieldDefinition>>>(rd.ofIdle()),
    []
  );
  const channel = useChannelMessage(
    props.services.messageService.createCustomField.subscribeToRequest,
    {
      onConnect: channel => {
        initialFieldStore.setNewValue(
          rd.of(channel.type === 'create' ? null : channel.customField)
        );
      },
      onMessage: async (message, channel) => {
        setMutation(message);
        if (mt.isSuccess(message)) {
          // we auto-close the dialog after some time (we can have very custom logic about who and when closes the dialog)
          // await delay(400);
          // closing channel is automatically hiding the dialog (see conditions below)
          channel.close();
        }
      }
    }
  );

  const [mutation, setMutation] = useState<MutationData<unknown, unknown>>(
    mt.ofIdle()
  );

  const handleSubmit = (data: Maybe<FieldDefinition>) => {
    // not sure if we should already set response with a waiter, or just use some promise factory from the request and the send response...
    channel?.channel.emit(maybe.getOrThrow(data));
    setMutation(mt.ofPending(void 0));
  };

  const lastChannel = useLast(channel, maybe.isPresent(channel));

  return (
    <CustomFieldConfigDialog
      open={maybe.isPresent(channel)}
      mode={lastChannel?.initialPayload.type ?? null}
      features={props.features}
      onSubmit={handleSubmit}
      mutation={mutation}
      prompt={lastChannel?.initialPayload.prompt}
      onCancel={() => channel?.channel.close()}
      resetStore={initialFieldStore}
      disabledFields={
        lastChannel?.initialPayload.type === 'edit' ? ['type'] : undefined
      }
    />
  );
}
