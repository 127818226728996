import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import arrayMove from 'array-move';
import { chain, get, isEmpty, size } from 'lodash';
import v4 from 'uuid/v4';

import { SHORTLISTS_ACTIONS } from 'store/v1/shortlists/shortlists.constants.js';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import {
  getContact,
  getContactAssociations
} from 'store/v1/contacts/contacts.actions.js';
import {
  getShortlist,
  updateShortlistBlock
} from 'store/v1/shortlists/shortlists.actions.js';
import useEvent from 'v1/helpers/hooks/useEvent';
import {
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  ModalWrapper,
  PressStud
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';
import MediaFromProfile from './MediaFromProfile/MediaFromProfile';
import ShortlistGallery from './ShortlistGallery/ShortlistGallery';
import MediaFromUrl from './MediaFromUrl/MediaFromUrl';
import MediaFromFile from './MediaFromFile/MediaFromFile';

import './ShortlistMediaModal.scss';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from 'v5/design-sytem/Select';

const MEDIA_SOURCES_DROPDOWN_OPTIONS = {
  profile: {
    label: 'Resources Pinboard',
    icon: '/images/icon_resource_person_black.svg'
  },
  link: {
    label: 'Add from link (URL)',
    icon: '/images/icon_media_link.svg'
  },
  upload: {
    label: 'Upload from computer',
    icon: '/images/icon_computer_upload.svg'
  }
};

const ShortlistMediaModal = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  const ui = useSelector(state => state.ui);
  const contacts = useSelector(state => state.contacts);
  const shortlists = useSelector(state => state.shortlists);
  const shortlist = get(ui, 'data.shortlist'); // TODO: (revamp) : check state usage here
  const shortlist_block = get(ui, 'data.block'); // TODO: (revamp) : check state usage here
  const [activeMediaTab, setActiveMediaTab] = useState('profile');
  const [gallery, setGallery] = useState(get(ui, 'data.block.pins'));
  const [initialGalleryEmpty, setInitialGalleryEmpty] = useState();

  useEffect(() => {
    setInitialGalleryEmpty(isEmpty(gallery));
  }, []);

  useEffect(() => {
    const contactId = shortlist_block.contact_id;
    const nextContact = contacts.data[contactId];
    if (!nextContact) {
      dispatch(getContact(contactId));
    }
    dispatch(getContactAssociations(contactId));
    ReactTooltip.rebuild(); // TODO: (revamp) : review
  }, [shortlist_block.contact_id, contacts.data, dispatch]);

  useEvent([SHORTLISTS_ACTIONS.UPDATE_SHORTLIST_BLOCK], {
    onSuccess: () => {
      dispatch(displayAlert('success', 'Updated succesfully'));
      onRequestClose();
    },
    onFailure: () =>
      dispatch(displayAlert('error', 'Could not update shortlist'))
  });

  const handleSave = _ => {
    dispatch(getShortlist(get(shortlist, 'id'))).then(() => {
      // migration bridge
      dispatch(
        updateShortlistBlock(
          {
            shortlist_id: get(shortlist, 'id'),
            shortlist_block_id: get(shortlist_block, 'id')
          },
          { ...shortlist_block, pins: gallery }
        )
      );
    });
  };

  const handleMediaSelect = pin => {
    let newPin = {
      ...pin,
      order: size(gallery),
      id: `temp-${v4()}`
    };
    setGallery(prev => [newPin, ...prev]);
  };

  const handleMediaRemove = pin => {
    setGallery(prev => prev.filter(i => i.id !== pin.id));
  };

  // TODO: review
  const handleUpdateOrder = (oldIndex, newIndex) => {
    let nextGallery = chain(gallery)
      .filter(p => !p.archived)
      .orderBy('order', 'asc')
      .value();
    nextGallery = arrayMove(nextGallery, oldIndex, newIndex);
    nextGallery = nextGallery.map((pin, index) => ({
      ...pin,
      order: index
    }));
    setGallery(nextGallery);
  };

  // TODO: (revamp) : review
  const handleMediaTabChange = tab => setActiveMediaTab(tab);

  const renderActiveTab = _ => {
    switch (activeMediaTab) {
      case 'profile':
        return (
          <MediaFromProfile
            contact_id={shortlist_block.contact_id}
            onSelect={handleMediaSelect}
          />
        );
      case 'link':
        return <MediaFromUrl onSelect={handleMediaSelect} />;
      case 'upload':
        return <MediaFromFile onSelect={handleMediaSelect} />;
      default:
        return '';
    }
  };

  return (
    <ModalWrapper size="XL" className="ShortlistMediaModal">
      <ModalHeader title="Manage gallery" />
      <ModalContent>
        <ModalScrollable padding="0" className="ShortlistMediaModal-content">
          <div className="ShortlistMediaModal-sources ">
            <div className="tailwind-root contents">
              <Select
                value={activeMediaTab}
                onValueChange={handleMediaTabChange}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {Object.keys(MEDIA_SOURCES_DROPDOWN_OPTIONS).map(option => (
                    <SelectItem size="lg" value={option} key={option}>
                      <img
                        src={MEDIA_SOURCES_DROPDOWN_OPTIONS[option].icon}
                        alt=""
                      />
                      {MEDIA_SOURCES_DROPDOWN_OPTIONS[option].label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="ShortlistMediaModal-sources-active">
              {renderActiveTab()}
            </div>
          </div>
          <ShortlistGallery
            gallery={gallery}
            onRemove={handleMediaRemove}
            handleUpdateOrder={handleUpdateOrder}
          />
        </ModalScrollable>
        <ModalNavigation>
          <PressStud
            label="Cancel"
            appearance="silent"
            hasMarginRight
            action={onRequestClose}
          />
          <PressStud
            label="Save"
            appearance="primary"
            isDisabled={initialGalleryEmpty && isEmpty(gallery)} // TODO: check
            isLoading={shortlists.loading === 'UPDATE_SHORTLIST_BLOCK'}
            dataCy="button-save"
            action={handleSave}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

ShortlistMediaModal.props = {
  onRequestClose: PropTypes.func
};

ShortlistMediaModal.noOverflow = true; // sorry we have centralized modal monster

export default ShortlistMediaModal;
