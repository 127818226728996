import { ResourceTypeModel } from '__types__/index';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import { Filter, Plus } from 'lucide-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ResourceFilters,
  ResourceFiltersConfig
} from 'v1/components/shared/search/ResourceFilters/ResourceFilters';
import { ListQuery } from 'v4/entities/common/common.crud.types';
import { TodoType } from 'v4/entities/common/TodoType';
import { Button } from 'v5/design-sytem/Button';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'v5/design-sytem/Popover';
import { cn } from 'v5/platform/dom/cn';

import SearchFilters from './SearchFilters/SearchFilters';

interface ResourceAdvancedSearchProps {
  className?: string;
  slimline: boolean;
  slimlineButtonLabel?: string | null;
  showMeta?: boolean;
  query?: ListQuery<TodoType>;
  filterList?: string[];
  onChange: (query: ListQuery<TodoType>) => void;
  lockedFields?: string[];
  resourceTypesModel: ResourceTypeModel; // TODO probably unused
  buttonClassName?: string;
  hideActiveFilters?: boolean;
  config: ResourceAdvancedSearchConfig;
}

export interface ResourceAdvancedSearchConfig {
  resourceFiltersConfig: ResourceFiltersConfig;
}

export function ResourceAdvancedSearch({
  className,
  slimline,
  slimlineButtonLabel = null,
  showMeta,
  query = {},
  filterList,
  onChange,
  lockedFields,
  resourceTypesModel,
  buttonClassName,
  hideActiveFilters,
  config
}: ResourceAdvancedSearchProps) {
  const { t } = useTranslation('v1_shared');

  const ifCustomFiltersExist = () => {
    if (query && query.filters) {
      const _filters = Object.assign({}, query.filters);
      delete _filters.groups;
      delete _filters.resource_type_id;
      return size(_filters) > 1;
    }
    return false;
  };
  return (
    <div className="tailwind-root contents">
      <div className="flex flex-wrap flex-row items-center gap-2 w-full">
        <Popover>
          <PopoverTrigger asChild>
            <Button
              data-cy="add-filters"
              variant="secondary"
              type="button"
              size={slimline ? 'sm' : 'md'}
              layout={
                slimline && !isEmpty(query) && !slimlineButtonLabel
                  ? 'icon'
                  : 'normal'
              }
              onClick={() => {}}
            >
              {slimline ? (
                isEmpty(query) ? (
                  <>
                    <Plus />
                    {t('ResourceAdvancedSearch.addFilters')}{' '}
                  </>
                ) : (
                  <>
                    <Filter />
                    {slimlineButtonLabel && (
                      <>
                        {' '}
                        <span className="left-XS">{slimlineButtonLabel}</span>
                      </>
                    )}
                  </>
                )
              ) : (
                <>
                  <Plus />
                  {t('ResourceAdvancedSearch.addFilters')}
                </>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[450px]" side="bottom" align="start">
            <ResourceFilters
              config={config.resourceFiltersConfig}
              showMeta={showMeta}
              query={query}
              filterList={filterList}
              onChange={onChange}
              resourceTypesModel={resourceTypesModel}
            />
          </PopoverContent>
        </Popover>
        {hideActiveFilters ? null : (
          <>
            <SearchFilters
              slimline={slimline}
              showMeta={showMeta}
              filters={get(query, 'filters') || {}}
              lockedFields={lockedFields}
              onRemove={filters => {
                if (isEmpty(filters)) {
                  delete query.filters;
                  onChange(query);
                } else {
                  onChange({
                    ...query,
                    filters,
                    order_by: !has(filters, 'availability.from_date') // TODO: Make elegant. Need to do this to revert sorting to name
                      ? { field: 'full_name', direction: 'asc' }
                      : { field: 'availability', direction: 'asc' }
                  });
                }
              }}
            />
            {ifCustomFiltersExist() && !slimline && (
              <Button
                data-cy="remove-all-filters"
                variant="ghost"
                onClick={() => {
                  delete query.filters;
                  onChange(query);
                }}
                className={cn('ml-1', buttonClassName)}
              >
                {t('ResourceAdvancedSearch.clearAll')}
              </Button>
            )}
          </>
        )}
      </div>
    </div>
  );
}
