import { maybe, rd } from '@passionware/monads';
import { delay } from '@passionware/platform-js';
import { isEqual, omit } from 'lodash';
import {
  AtellioFeatureGroupDefinition,
  featureFlags,
  localStorageCleanupVersionMapping
} from 'v5/features';
import {
  FeatureFlagValues,
  FeatureFlagWritableSource
} from 'v5/platform/feature-management/feature-management';
import { WithServices } from 'v5/platform/ts/services';
import { create } from 'zustand';
import { FeatureFlagEnum } from '../../../../dtos/constants/feature-flag.enum';
import { WithToastService } from '../ToastService/ToastService';
import { FeatureManagementService } from './FeatureManagementService';
import {
  FeatureFlagConfig,
  FeatureFlagEntry,
  FeatureFlagView,
  FeatureManagementAction
} from './types';

type Store = {
  actions: FeatureManagementAction[];
  addAction: (action: FeatureManagementAction) => void;
};

export function createFeatureManagementService(
  config: WithServices<[WithToastService]> & {
    serverSource: FeatureFlagWritableSource<AtellioFeatureGroupDefinition>;
    localStorageSource: FeatureFlagWritableSource<AtellioFeatureGroupDefinition>;
    enforcedVersions: Partial<FeatureFlagValues<AtellioFeatureGroupDefinition>>;
  }
): FeatureManagementService {
  const useStore = create<Store>(setState => ({
    actions: [],
    addAction: action =>
      setState(state => ({
        actions: [...state.actions, action]
      }))
  }));

  return {
    useFeatureFlagConfigView: () => {
      const backend = config.serverSource.useFeatureFlags();
      const frontend = config.localStorageSource.useFeatureFlags();
      const actions = useStore(state => state.actions);
      return rd.useMemoMap(
        rd.combine({ backend, frontend }),
        (state, actions) => {
          const optimisticState = actions.reduce(
            featureManagementReducer,
            state
          );
          return {
            featureFlagView: computeView(
              optimisticState,
              config.enforcedVersions
            ),
            isDirty: !isEqual(state, optimisticState)
          };
        },
        actions
      );
    },
    scheduleAction: action => {
      useStore.getState().addAction(action);
    },
    saveConfig: async () => {
      const backendChanges: Partial<
        FeatureFlagValues<AtellioFeatureGroupDefinition>
      > = {};
      const frontendChanges: Partial<
        FeatureFlagValues<AtellioFeatureGroupDefinition>
      > = {};
      const actions = useStore.getState().actions;
      actions.forEach(action => {
        switch (action.type) {
          case 'setFeatureFlag':
            if (action.source === 'backend') {
              backendChanges[action.featureId] = action.value;
            } else {
              frontendChanges[action.featureId] = action.value;
            }
            break;
          case 'clearFeatureFlag':
            if (action.source === 'backend') {
              backendChanges[action.featureId] = null;
            } else {
              frontendChanges[action.featureId] = null;
            }
            break;
        }
      });

      let fullReloadRequested = false;

      if (Object.keys(backendChanges).length > 0) {
        const result = await config.serverSource.save(backendChanges);
        if (result.requiresFullReload) {
          fullReloadRequested = true;
        }
      }
      try {
        if (Object.keys(frontendChanges).length > 0) {
          const result = await config.localStorageSource.save(frontendChanges);
          if (result.requiresFullReload) {
            fullReloadRequested = true;
          }
        }
        useStore.setState({ actions: [] });
        if (fullReloadRequested) {
          config.services.toastService.open({
            type: 'success',
            // todo: discuss just updating redux team feature flags, but I am afraid it won't be enough as there are many abusements like denormalized data storage etc
            message: 'Feature flags saved. Reloading application is required.'
          });
          await delay(1000);
          window.location.reload();
        } else {
          config.services.toastService.open({
            type: 'success',
            message: 'Feature flags saved.'
          });
        }
      } catch (e) {
        config.services.toastService.open({
          type: 'error',
          message: 'Feature flags save failed.'
        });
      }
    },
    getEnforcedFeatureFlagConfig: () => config.enforcedVersions
  };
}

type FeatureManagementState = {
  backend: Partial<FeatureFlagValues<AtellioFeatureGroupDefinition>>;
  frontend: Partial<FeatureFlagValues<AtellioFeatureGroupDefinition>>;
};

export function featureManagementReducer(
  view: FeatureManagementState,
  action: FeatureManagementAction
): FeatureManagementState {
  switch (action.type) {
    case 'setFeatureFlag': {
      const { source, featureId, value } = action;
      return {
        ...view,
        [source]: {
          ...view[source],
          [featureId]: value // todo - for non optional fields we should check for equality to default value, and then - remove it from the state
        }
      };
    }
    case 'clearFeatureFlag': {
      const { source, featureId } = action;
      return {
        ...view,
        [source]: omit(view[source], featureId)
      };
    }
  }
}
export function computeView(
  { backend, frontend }: FeatureManagementState,
  enforcedVersions: Partial<FeatureFlagValues<AtellioFeatureGroupDefinition>>
): FeatureFlagView {
  const newData: FeatureFlagView = {
    groups: featureFlags.groups.map(group => ({
      name: group.name,
      entries: group.entries.map<FeatureFlagEntry>(entry => ({
        featureId: entry.id,
        featureName: entry.name,
        description: entry.description,
        isBypassed: maybe.isPresent(enforcedVersions[entry.id]),
        config: {
          backend: Object.values(FeatureFlagEnum).includes(entry.id)
            ? ({
                supported: true,
                optional: false,
                value: {
                  ...entry.flagSpec,
                  current: maybe.getOrNull(
                    backend[entry.id] ?? entry.flagSpec.defaultValue
                  ) // even if backend is missing it, we want to show default (Atellio backend can not have this entry yet in db)
                }
              } satisfies FeatureFlagConfig)
            : ({ supported: false } satisfies FeatureFlagConfig), // frontend-only
          frontend:
            // we agree that localStorage flags must have defined cleanup version mapping
            // otherwise we treat it as backend-only, which when we would override it, it would lead to unpredictable behavior
            entry.id in localStorageCleanupVersionMapping
              ? ({
                  supported: true,
                  optional: true,
                  value: {
                    ...entry.flagSpec,
                    current: maybe.getOrNull(frontend[entry.id]),
                    defaultValue:
                      backend[entry.id] ?? entry.flagSpec.defaultValue
                  }
                } satisfies FeatureFlagConfig)
              : ({ supported: false } satisfies FeatureFlagConfig)
        }
      }))
    }))
  };

  return newData;
}
