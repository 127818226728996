import { useFocusWithin } from '@react-aria/interactions';
import { cva, VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { ComponentProps, FocusEvent } from 'react';
import { cn } from 'v5/platform/dom/cn';
import {
  findFocusableElement,
  getFocusableSelector
} from '../platform/dom/isFocusable';
import { Overwrite } from '../platform/typescript';
import { focusRings } from './_common/focusRing';

export const inputGroupVariants = cva(
  [
    'overflow-hidden', // for borders and radius to work
    'group',
    'cursor-text',
    'flex w-full',
    'file:border-0 file:bg-transparent file:text-base file:font-medium file:text-fg-default',
    'placeholder:text-fg-muted',
    'disabled:cursor-not-allowed disabled:opacity-50',
    '[&_[data-element=input-group-slot]]:px-3'
  ],
  {
    variants: {
      size: {
        xs: 'text-base h-[20px] [&_svg]:size-3 [&_[data-element=input-group-button]]:px-1.5',
        sm: 'text-base h-9 [&_svg]:size-4 [&_[data-element=input-group-button]]:px-2.5',
        md: 'text-base h-12 [&_svg]:size-5 [&_[data-element=input-group-button]]:px-3.5',
        lg: 'text-lg h-15 [&_svg]:size-6 [&_[data-element=input-group-button]]:px-5',
        xl: 'text-xl h-17 [&_svg]:size-7 [&_[data-element=input-group-button]]:px-6'
      },
      variant: {
        regular: [
          'rounded',
          'border border-border-default bg-bg-default text-fg-default',
          'hocus:border-bg-inset',
          '[&_input]:first:pl-2 [&_input]:last:pr-2',
          '[&_[data-element="input-group-input"]]:first:pl-2 [&_[data-element="input-group-input"]]:last:pr-2',
          focusRings.focusWithin.normal
        ],
        inline: [
          'transition-all',
          'bg-bg-default text-fg-default',
          'focus-within:shadow-[inset_0_-2px_0_0_] aria-expanded:shadow-[inset_0_-2px_0_0_]',
          'shadow-[inset_0_-1px_0_0_]',
          'shadow-border-default',
          'hover:shadow-bg-inset',
          'has-focus:shadow-brand-300',
          'hover:focus-within:shadow-brand-400 aria-expanded:shadow-brand-400'
        ],
        plain: [
          'transition-all',
          'bg-bg-default text-fg-default',
          'focus-within:shadow-[inset_0_-2px_0_0_] aria-expanded:shadow-[inset_0_-2px_0_0_]',
          'shadow-[inset_0_-1px_0_0_]',
          'shadow-transparent',
          'hover:shadow-bg-inset',
          'has-focus:shadow-brand-300',
          'hover:focus-within:shadow-brand-400 aria-expanded:shadow-brand-400'
        ]
      }
    },
    defaultVariants: {
      variant: 'regular',
      size: 'md'
    }
  }
);

export type InputGroupProps = Overwrite<
  ComponentProps<'div'>,
  VariantProps<typeof inputGroupVariants> & {
    leftSlot?: React.ReactNode;
    rightSlot?: React.ReactNode;
    onBlur?: (e: FocusEvent) => void;
    onFocus?: (e: FocusEvent) => void;
  }
>;

export type InputGroupVariant = InputGroupProps['variant'];
export type InputGroupSize = InputGroupProps['size'];

export const InputGroup = React.forwardRef<HTMLDivElement, InputGroupProps>(
  (
    {
      leftSlot,
      rightSlot,
      children,
      className,
      variant,
      size,
      onFocus,
      onBlur,
      ...props
    },
    ref
  ) => {
    const { focusWithinProps } = useFocusWithin({
      onFocusWithin: onFocus,
      onBlurWithin: onBlur
    });

    return (
      <div
        className={cn(inputGroupVariants({ variant, size }), className)}
        ref={ref}
        onMouseDownCapture={e => {
          if (
            e.target instanceof Element &&
            e.target.closest(getFocusableSelector())
          ) {
            return;
          }
          findFocusableElement(e.currentTarget)?.focus();
          e.stopPropagation();
          e.preventDefault();
        }}
        {...props}
        {...focusWithinProps}
      >
        {leftSlot}
        {children}
        {rightSlot}
      </div>
    );
  }
);
InputGroup.displayName = 'InputGroup';

export type InputGroupInputProps = React.ComponentProps<'input'> & {
  highlightOnFocus?: boolean;
};

export const InputGroupInput = React.forwardRef<
  HTMLInputElement,
  InputGroupInputProps
>(({ className, onFocus, highlightOnFocus, ...props }, ref) => {
  return (
    <input
      data-element="input-group-input"
      className={cn(
        'w-full border-none bg-transparent outline-none',
        className
      )}
      ref={ref}
      {...props}
      onFocus={e => {
        if (highlightOnFocus) {
          e.target.select();
        }
        onFocus?.(e);
      }}
    />
  );
});
InputGroupInput.displayName = 'InputGroupInput';

export const InputGroupSlot = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<'div'>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      data-element="input-group-slot"
      className={cn('flex items-center', className)}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  );
});

export const InputGroupButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<'button'>
>(({ className, children, ...props }, ref) => {
  return (
    <button
      data-element="input-group-button"
      className={cn(
        'flex items-center xhover:bg-brand-900/10 focus-visible:bg-brand-900/7 transition-colors',
        className
      )}
      ref={ref}
      {...props}
    >
      {children}
    </button>
  );
});
