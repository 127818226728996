import { myServices } from '../../services/services.connected.app';
import { CustomFieldConfigDialogWidget } from '../custom-fields/config-dialog/CustomFieldConfigDialogWidget';
import { FeatureFlagWidget } from '../feature-flag-management/FeatureFlagWidget';

export function ModalContainerV5() {
  return (
    <>
      <CustomFieldConfigDialogWidget services={myServices} />
      <FeatureFlagWidget services={myServices} />
    </>
  );
}
