import { maybe, mt, rd, RemoteData } from '@passionware/monads';
import { promiseState } from '@passionware/platform-react';
import {
  AlertCircle,
  CircleDashed,
  CornerDownLeft,
  EyeClosed,
  Lock,
  Undo2
} from 'lucide-react';
import { motion } from 'motion/react';
import React from 'react';
import { Button } from 'v5/design-sytem/Button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from 'v5/design-sytem/Tabs';
import {
  FeatureFlagConfig,
  FeatureFlagValueConfig
} from 'v5/services/front/FeatureManagementService/types';
import { renderError } from '../../common-widgets/renderError';
import { Alert, AlertDescription, AlertTitle } from '../../design-sytem/Alert';
import {
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '../../design-sytem/Dialog';
import { Kbd } from '../../design-sytem/Kbd';
import { LoadingSpinner } from '../../design-sytem/LoadingSpinner';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger
} from '../../design-sytem/Select';
import { Skeleton } from '../../design-sytem/Skeleton';
import { Switch } from '../../design-sytem/Switch';
import { SimpleTooltip } from '../../design-sytem/Tooltip';
import { cn } from '../../platform/dom/cn';
import { useFormSubmitHotkeys } from '../../platform/react/useFormSubmit';
import { ModifierKey } from '../../primitives/ModifierKey';
import {
  FeatureFlagConfigView,
  FeatureManagementActionHandler
} from '../../services/front/FeatureManagementService/FeatureManagementService';

export interface FeatureFlagViewProps {
  view: RemoteData<FeatureFlagConfigView>;
  onAction: FeatureManagementActionHandler;
  onSave: () => Promise<void>;
  tab: string;
  onTabChange: (tab: string) => void;
}

function BooleanFlagValue({
  value,
  onChange
}: {
  value: Extract<FeatureFlagValueConfig<boolean>, { type: 'boolean' }>;
  onChange: (newValue: boolean) => void;
}) {
  return (
    <Switch
      checked={maybe.getOrElse(value.current, value.defaultValue)}
      onCheckedChange={onChange}
    />
  );
}

function EnumFlagValue({
  value,
  onChange
}: {
  value: Extract<FeatureFlagValueConfig<boolean>, { type: 'enum' }>;
  onChange: (
    newValue: Extract<
      FeatureFlagValueConfig<boolean>,
      { type: 'enum' }
    >['current']
  ) => void;
}) {
  return (
    <Select value={value.current ?? undefined} onValueChange={onChange}>
      <SelectTrigger size="sm" className="min-w-24">
        {value.current ?? value.defaultValue}
      </SelectTrigger>
      <SelectContent>
        {value.options.map(option => (
          <SelectItem size="lg" key={option} value={option}>
            {option}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

function FlagValue({
  config,
  onChange
}: {
  config: FeatureFlagValueConfig<boolean>;
  onChange: (newValue: FeatureFlagValueConfig<boolean>['current']) => void;
}) {
  const value = config;
  switch (value.type) {
    case 'boolean':
      return <BooleanFlagValue value={value} onChange={onChange} />;
    case 'enum':
      return <EnumFlagValue value={value} onChange={onChange} />;
    default:
      throw new Error('Unsupported flag value type');
  }
}

interface FlagSwitchProps {
  config: FeatureFlagConfig;
  onChange: (newValue: FeatureFlagValueConfig<boolean>['current']) => void;
  onClear: () => void;
}

function FlagControl({ config, onClear, onChange }: FlagSwitchProps) {
  if (!config.supported) {
    return (
      <>
        <Lock className="text-fg-subtle size-4 justify-self-end" />
        <div />
      </>
    );
  }

  function getCanBeReset() {
    if (config.supported) {
      if (config.optional) {
        if (config.value.current !== null) {
          return true;
        }
      } else if (config.value.current !== config.value.defaultValue) {
        return true;
      }
    }
    return false;
  }
  const canBeReset = getCanBeReset();

  return (
    <>
      <div
        className={cn(
          'justify-self-end place-self-center flex flex-row items-center justify-center',
          config.value.current === null && 'opacity-50'
        )}
      >
        <FlagValue
          config={config.value as FeatureFlagValueConfig<boolean>}
          onChange={onChange}
        />
      </div>
      <SimpleTooltip
        title={
          canBeReset
            ? config.optional
              ? 'Unset the optional value'
              : 'Reset to default value'
            : config.optional
              ? 'This optional value is not set'
              : 'This is required and has a default value'
        }
      >
        <Button
          variant="ghost"
          className="-ml-4"
          layout="icon"
          size="sm"
          onClick={onClear}
          visuallyDisabled={!canBeReset}
        >
          {canBeReset ? (
            <Undo2 />
          ) : config.optional ? (
            <EyeClosed />
          ) : (
            <CircleDashed />
          )}
        </Button>
      </SimpleTooltip>
    </>
  );
}

export function FeatureFlagView({
  view,
  onAction,
  onSave,
  tab,
  onTabChange
}: FeatureFlagViewProps) {
  const saveMutation = promiseState.useMutation(onSave);

  const ref = useFormSubmitHotkeys(() => {
    if (rd.tryGet(view)?.isDirty) {
      void saveMutation.track(void 0);
    }
  });

  return rd
    .journey(view)
    .wait(
      <DialogContent ref={ref}>
        <DialogTitle>
          <div>Manage features</div>
        </DialogTitle>
        <DialogBody>
          <Skeleton className=" h-4 p-6 m-6" />
          <Skeleton className=" h-4 p-6 m-6" />
          <Skeleton className=" h-4 p-6 m-6" />
        </DialogBody>
      </DialogContent>
    )
    .catch(e => (
      <DialogContent>
        <DialogTitle>
          <div>Manage features</div>
        </DialogTitle>
        <DialogBody>
          <Alert variant="destructive">
            <AlertCircle />
            <AlertTitle>Feature flag error</AlertTitle>
            <AlertDescription>{renderError(e)}</AlertDescription>
          </Alert>
        </DialogBody>
      </DialogContent>
    ))
    .map(view => {
      return (
        <Tabs asChild value={tab} onValueChange={onTabChange}>
          <DialogContent className="min-w-220 h-3/4">
            <DialogHeader>
              <DialogTitle className="flex items-center gap-2 py-2">
                <div>Manage features</div>
                <TabsList className="self-start">
                  {view.featureFlagView.groups.map(group => (
                    <TabsTrigger
                      key={group.name}
                      value={group.name}
                      autoFocus={group.name === tab}
                    >
                      {group.name}
                    </TabsTrigger>
                  ))}
                </TabsList>
              </DialogTitle>
            </DialogHeader>
            <DialogBody
              className="p-0 grid grid-cols-[1fr_repeat(4,auto)] gap-5"
              renderTopShadow={false}
            >
              <DialogDescription className="sr-only">Flag UI</DialogDescription>
              <div className="sticky top-0 grid grid-cols-subgrid rows-subgrid col-span-full px-6 py-3 bg-white z-1 [[data-has-top-shadow]_&]:shadow-sm shadow-none">
                <div className="font-semibold">Feature</div>
                <div className="text-center font-semibold justify-self-end">
                  Backend
                </div>
                <div />
                <div className="text-center font-semibold justify-self-end">
                  Frontend
                </div>
                <div />
              </div>
              {view.featureFlagView.groups.map(group => (
                <TabsContent
                  key={group.name}
                  value={group.name}
                  asChild
                  className="contents"
                >
                  <div className="">
                    {group.entries.map(entry => (
                      <div
                        key={entry.featureId}
                        className="grid grid-cols-subgrid rows-subgrid col-span-full hocus:bg-bg-subtle px-6 py-3 items-center"
                      >
                        <div className={entry.isBypassed ? 'opacity-50' : ''}>
                          <span className="font-bold">
                            {entry.featureName}
                            {entry.isBypassed && (
                              // some badge
                              <SimpleTooltip title="This feature is turned off by code and will be removed soon.">
                                <div className="rounded-full inline-block text-sm bg-bg-subtle text-fg-default px-2 py-0.5 -my-0.5 ml-2">
                                  Bypassed
                                </div>
                              </SimpleTooltip>
                            )}
                          </span>
                          {entry.description && (
                            <p className="text-sm text-muted-foreground">
                              {entry.description}
                            </p>
                          )}
                        </div>

                        <FlagControl
                          config={entry.config.backend}
                          onChange={newValue => {
                            onAction({
                              type: 'setFeatureFlag',
                              source: 'backend',
                              featureId: entry.featureId,
                              value: newValue
                            });
                          }}
                          onClear={() =>
                            onAction({
                              type: 'clearFeatureFlag',
                              source: 'backend',
                              featureId: entry.featureId
                            })
                          }
                        />

                        <FlagControl
                          config={entry.config.frontend}
                          onChange={newValue =>
                            onAction({
                              type: 'setFeatureFlag',
                              source: 'frontend',
                              featureId: entry.featureId,
                              value: newValue
                            })
                          }
                          onClear={() =>
                            onAction({
                              type: 'clearFeatureFlag',
                              source: 'frontend',
                              featureId: entry.featureId
                            })
                          }
                        />
                      </div>
                    ))}
                  </div>
                </TabsContent>
              ))}
            </DialogBody>
            <DialogFooter className="mt-auto">
              {mt.isInError(saveMutation.state) && (
                <Alert
                  variant="destructive"
                  layout="horizontal"
                  className="flex-1"
                >
                  <AlertCircle />
                  <AlertTitle>Save error</AlertTitle>
                  <AlertDescription>
                    {renderError(saveMutation.state.error)}
                  </AlertDescription>
                </Alert>
              )}
              <Button
                className="isolate"
                disabled={!view.isDirty || mt.isInProgress(saveMutation.state)}
                onClick={() => {
                  if (view.isDirty) {
                    void saveMutation.track(void 0);
                  }
                }}
              >
                <motion.div
                  initial={false}
                  exit={{ opacity: 0, width: 0 }}
                  transition={{ duration: 0.2 }}
                  animate={
                    mt.isInProgress(saveMutation.state)
                      ? { width: 'auto' }
                      : { width: 0 }
                  }
                  className="-mr-3 overflow-hidden"
                >
                  <LoadingSpinner className="mr-3" />
                </motion.div>
                <Kbd className="z-1">
                  <ModifierKey />
                  <CornerDownLeft />
                </Kbd>
                Save
              </Button>
            </DialogFooter>
          </DialogContent>
        </Tabs>
      );
    });
}
