import { rd } from '@passionware/monads';
import { injectConfig } from '@passionware/platform-react';
import React, { cloneElement, ReactElement } from 'react';
import { ItemSelector } from 'v5/common-widgets/ItemSelector';
import { Account } from '../../../__types__';

export type UserVM = {
  id: string;
  profileImage?: string;
  name: string;
  initials: string;
};

export interface UserPickerConfig {
  useUsers: (query: string) => UserVM[];
  usePlaceholder?: () => string;
}
export interface UserPickerProps {
  children: ReactElement;
  onSelect: (userId: Account['id']) => void;
  tooltip?: string;
}

export function createUserPicker(config: UserPickerConfig) {
  const UserSelector = injectConfig(ItemSelector).static({
    useOptions: query => {
      const users = config.useUsers(query);
      return rd.of(
        users.map(user => ({
          id: user.id,
          label: user.name,
          disabled: false
        }))
      );
    }
  });
  return function UserPicker(props: UserPickerProps) {
    const placeholder = config.usePlaceholder?.() ?? 'Search…';
    return (
      <UserSelector placeholder={placeholder} onSelect={props.onSelect}>
        {cloneElement(props.children, {
          'data-tip': props.tooltip
        })}
      </UserSelector>
    );
  };
}
