'use client';

import * as SelectPrimitive from '@radix-ui/react-select';
import { SelectTriggerProps } from '@radix-ui/react-select';
import { cva, VariantProps } from 'class-variance-authority';
import { Check, ChevronDown, ChevronUp } from 'lucide-react';
import * as React from 'react';
import { cn } from 'v5/platform/dom/cn';
import { focusRings } from './_common/focusRing';

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectItemText = SelectPrimitive.ItemText;

export const selectTriggerVariants = cva(
  [
    'group is-trigger',
    'flex w-full items-center justify-between  text-fg-default data-[placeholder]:text-fg-muted disabled:cursor-not-allowed disabled:opacity-50'
  ],
  {
    variants: {
      variant: {
        regular: [
          focusRings.focus.visible,
          'rounded border border-border-default bg-bg-default',
          'hocus:border-bg-inset'
        ],
        inline: [
          'transition-all',
          'bg-bg-default',
          'bg-transparent text-fg-default',
          //
          'focus-within:shadow-[inset_0_-2px_0_0_]',
          'aria-expanded:shadow-[inset_0_-2px_0_0_]',
          'shadow-[inset_0_-1px_0_0_]',
          'shadow-border-default',
          'hover:shadow-bg-inset',
          'focus:shadow-brand-300',
          'hover:focus-within:shadow-brand-400 aria-expanded:shadow-brand-400'
        ],
        plain: [
          'transition-all',
          'bg-bg-default',
          'bg-transparent text-fg-default',
          //
          'focus-within:shadow-[inset_0_-2px_0_0_]',
          'aria-expanded:shadow-[inset_0_-2px_0_0_]',
          'shadow-[inset_0_-1px_0_0_]',
          'shadow-transparent',
          'hover:shadow-bg-inset',
          'focus:shadow-brand-300',
          'hover:focus-within:shadow-brand-400 aria-expanded:shadow-brand-400'
        ]
      },
      size: {
        xs: 'h-[20px] py-1 gap-2 text-base [&_.value-container>*]:flex [&_.value-container>*]:gap-2 [&_.value-container>*]:items-center [&_.value-container_svg]:size-4',
        sm: 'h-9 py-1.5 gap-2 text-base [&_.value-container>*]:flex [&_.value-container>*]:gap-2 [&_.value-container>*]:items-center [&_.value-container_svg]:size-4',
        md: 'h-12 py-2 gap-2 text-base [&_.value-container>*]:flex [&_.value-container>*]:gap-2 [&_.value-container>*]:items-center [&_.value-container_svg]:size-5',
        lg: 'h-15 py-3 gap-2 text-lg [&_.value-container>*]:flex [&_.value-container>*]:gap-2 [&_.value-container>*]:items-center [&_.value-container_svg]:size-6',
        xl: 'h-17 py-4 gap-2 text-xl [&_.value-container>*]:flex [&_.value-container>*]:gap-2 [&_.value-container>*]:items-center [&_.value-container_svg]:size-7'
      }
    },
    compoundVariants: [
      {
        variant: 'regular',
        size: 'xs',
        className: 'px-3'
      },
      {
        variant: 'regular',
        size: 'sm',
        className: 'px-3'
      },
      {
        variant: 'regular',
        size: 'md',
        className: 'px-3'
      },
      {
        variant: 'regular',
        size: 'lg',
        className: 'px-3'
      },
      {
        variant: 'regular',
        size: 'xl',
        className: 'px-3'
      }
    ],
    defaultVariants: {
      size: 'md',
      variant: 'regular'
    }
  }
);

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  SelectTriggerProps & VariantProps<typeof selectTriggerVariants>
>(({ className, size, variant, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(selectTriggerVariants({ size, variant }), className)}
    {...props}
  >
    <div className="value-container w-full *:contents truncate">{children}</div>
    <SelectPrimitive.Icon asChild>
      <ChevronDown className="size-5 shrink-0  opacity-50" />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={cn(
      'flex cursor-default items-center justify-center py-1',
      className
    )}
    {...props}
  >
    <ChevronUp className="size-4" />
  </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={cn(
      'flex cursor-default items-center justify-center py-1',
      className
    )}
    {...props}
  >
    <ChevronDown className="size-4" />
  </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName =
  SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = 'popper', ...props }, ref) => (
  <SelectPrimitive.Portal
    container={document.getElementById('tailwind-portals')}
  >
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        'relative z-50 max-h-96 overflow-hidden rounded-md border border-border-default bg-bg-default text-fg-default shadow-md data-[state=open]:animate-zoomin data-[state=closed]:animate-zoomoutup origin-(--radix-select-content-transform-origin)',
        'w-auto',
        'min-w-[max(var(--radix-select-trigger-width),120px)]',
        'max-w-[max(var(--radix-select-trigger-width),min(500px,var(--radix-select-content-available-width)))]',
        position === 'popper' &&
          'data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1',
        className
      )}
      position={position}
      {...props}
    >
      <SelectScrollUpButton />
      <SelectPrimitive.Viewport
        className={cn(
          'p-1',
          position === 'popper' &&
            'h-[var(--radix-select-trigger-height)] w-full'
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
      <SelectScrollDownButton />
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn('py-1.5 pl-4 pr-2 text-base font-semibold', className)}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const selectItemVariants = cva(
  [
    'transition-colors',
    'relative flex cursor-default select-none items-center rounded-sm pr-8  text-base outline-none focus:bg-bg-subtle focus:text-default data-[disabled]:pointer-events-none data-[disabled]:opacity-50'
  ],
  {
    variants: {
      size: {
        md: 'text-base [&_[data-item-text]]:flex [&_[data-item-text]]:gap-2 [&_[data-item-text]]:p-2 [&_[data-item-text]]:leading-tight [&_[data-item-text]]:items-center [&_[data-item-text]_svg,img]:size-5',
        lg: 'text-base [&_[data-item-text]]:flex [&_[data-item-text]]:gap-4 [&_[data-item-text]]:p-4 [&_[data-item-text]]:leading-tight [&_[data-item-text]]:items-center [&_[data-item-text]_svg,img]:size-5'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
);

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item> &
    VariantProps<typeof selectItemVariants> & { renderCheckmark?: boolean }
>(({ className, children, size, renderCheckmark = true, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      selectItemVariants({ size }),
      renderCheckmark ? '' : 'pr-0',
      className
    )}
    {...props}
  >
    <SelectPrimitive.ItemText data-item-text="">
      {children}
    </SelectPrimitive.ItemText>
    {renderCheckmark && (
      <span className="absolute right-2 flex h-3.5 w-3.5 items-center justify-center">
        <SelectPrimitive.ItemIndicator>
          <Check className="h-4 w-4" />
        </SelectPrimitive.ItemIndicator>
      </span>
    )}
  </SelectPrimitive.Item>
));

const SelectEmpty = React.forwardRef<
  React.ElementRef<'div'>,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, children, ...props }, ref) => (
  <div
    ref={ref}
    className={cn('py-1.5 pl-2 pr-2 text-base text-fg-muted', className)}
    {...props}
  >
    {children ?? 'No options available'}
  </div>
));

SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn('-mx-1 my-1 h-px bg-border-muted', className)}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectItemText,
  SelectEmpty,
  SelectSeparator,
  SelectScrollUpButton,
  SelectScrollDownButton
};
