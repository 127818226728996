import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from 'v5/design-sytem/DropdownMenu.js';
import { Button } from 'v5/design-sytem/Button.js';
import { ColorDot } from 'v5/common-widgets/ColorDot.js';

const StatusSelectorMultiple = ({
  statuses,
  onStatusChange,
  activeStatuses
}) => {
  const { t } = useTranslation('v1_shared');

  const onStatusClick = statusId => {
    statusId
      ? activeStatuses.find(s => s === statusId)
        ? onStatusChange(prev => prev.filter(s => s !== statusId))
        : onStatusChange(prev => [...prev, statusId])
      : onStatusChange(statuses.map(s => s.id));
  };
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="secondary"
            size="sm"
            className="w-full justify-start"
          >
            {t('StatusSelectorMultiple.filterStatus')}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            inset
            size="lg"
            key={`availability-all`}
            onSelect={() => onStatusClick(null)}
          >
            {t('StatusSelectorMultiple.showAll')}
          </DropdownMenuItem>
          <DropdownMenuSeparator />
          {statuses
            .filter(s => !s.archived)
            .map(status => (
              <DropdownMenuCheckboxItem
                size="lg"
                key={`status-${status.id}`}
                checked={activeStatuses.includes(status.id)}
                onSelect={e => {
                  e.preventDefault();
                  onStatusClick(status.id);
                }}
              >
                <ColorDot color={status.colour} />
                <span>{status.name}</span>
              </DropdownMenuCheckboxItem>
            ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

StatusSelectorMultiple.propTypes = {
  statuses: PropTypes.array.isRequired,
  onStatusClick: PropTypes.func.isRequired,
  activeStatuses: PropTypes.array
};

export default StatusSelectorMultiple;
