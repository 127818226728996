import { ResourceType } from '__types__/index';
import classnames from 'classnames';
import { get, omit, set } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SearchAvailabilityInput } from 'v1/components/shared';
import { ListQuery } from 'v4/entities/common/common.crud.types';
import { TodoType } from 'v4/entities/common/TodoType';

import SelectResourceType from './SelectResourceType/SelectResourceType';

interface ResourceMetaFiltersProps {
  className?: string;
  query: ListQuery<TodoType>;
  resourceTypes: Array<ResourceType>;
  activeResourceType: ResourceType;
  setActiveResourceType?: (resourceType: ResourceType) => void;
  onChange: (query: ListQuery<TodoType>) => void;
}

export function createResourceMetaFilters() {
  return function ResourceMetaFilters({
    className,
    query = {},
    resourceTypes,
    activeResourceType,
    setActiveResourceType,
    onChange
  }: ResourceMetaFiltersProps) {
    const { t } = useTranslation('v1_shared');
    const onTypeChange = (resourceType: ResourceType) => {
      let newQuery: ListQuery<TodoType> = {};
      if (!resourceType) {
        newQuery = omit(query, 'filters.resource_type_id');
      } else {
        newQuery = {
          query: get(query, 'query'),
          order_by: get(query, 'order_by'),
          filters: {
            resource_type_id: { eq: resourceType.id }
          }
        };
      }
      // TODO: mutating lodash function
      get(query, 'filters.availability') &&
        // @ts-expect-error todo after migration, some confusing code has to be cleaned up
        set(newQuery.filters.availability, get(query, 'filters.availability'));
      onChange(newQuery);
      setActiveResourceType && setActiveResourceType(resourceType);
    };

    const onAvailabilityChange = (dates: TodoType) => {
      if (!dates) {
        delete query.filters?.availability;
        onChange({
          ...query,
          order_by: { field: 'full_name', direction: 'asc' }
        });
      } else {
        const filters = get(query, 'filters', {});
        onChange({
          ...query,
          filters: { ...filters, availability: dates },
          order_by: { field: 'availability', direction: 'asc' }
        });
      }
    };

    return (
      <div className={classnames(['ResourceMetaFilters', className])}>
        <SelectResourceType
          resourceTypes={resourceTypes}
          activeResourceType={activeResourceType}
          onSelect={onTypeChange}
        />
        <SearchAvailabilityInput
          label={t('ResourceMetaFilters.available')}
          dates={get(query, 'filters.availability', {})}
          onChange={onAvailabilityChange}
        />
      </div>
    );
  };
}
