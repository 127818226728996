import { rd } from '@passionware/monads';
import { AtellioFeatureGroupDefinition } from 'v5/features';
import { FeatureFlagFinalSource } from 'v5/platform/feature-management/feature-management';
import { FeatureService } from './FeatureService';

export function createFeatureService(
  flagSource: FeatureFlagFinalSource<AtellioFeatureGroupDefinition>
): FeatureService {
  return {
    useFeatureFlags: flagSource.useFeatureFlags,
    useFeature: featureId => {
      return rd.useMemoMap(
        flagSource.useFeatureFlags(),
        (flags, featureId) => flags[featureId],
        featureId
      );
    }
  };
}
