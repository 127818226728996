import React from 'react';
import { TextInputProps } from 'v1/components/shared/byType/text/TextInput/__types__/TextInput';
import { Input } from 'v5/design-sytem/Input';
import { InlineField } from 'v5/platform/react/InlineField';
import { getV5Props } from '../../text/GetV5Props';

interface CurrencyAmountInputProps
  extends Omit<TextInputProps, 'value' | 'onChange' | 'onBlur'> {
  value?: number | null;
  onChange?(value: number | null): void;
  onBlur?(value: number | null): void;
  role?: string;
}

const noop = () => {};

function CurrencyAmountInput(props: CurrencyAmountInputProps) {
  const { value, onChange, onBlur, placeholder, ...rest } = props;

  // Converts the number (in cents) to a formatted string (e.g., 12345 -> "123.45")
  const parseValue = (v: number | null | undefined): string => {
    if (v === null || v === undefined) {
      return '';
    }
    const str = v.toString().padStart(2, '0');
    return (
      str.substring(0, str.length - 2) +
      '.' +
      str.substring(str.length - 2)
    ).padStart(4, '0');
  };

  // Converts the string back to a number (in cents)
  const formatValue = (v: string): number | null => {
    return v === '' ? null : parseInt((Number(v) * 100).toFixed(0));
  };

  return (
    <InlineField<string>
      value={parseValue(value)}
      onChange={newString => {
        const newNumber = formatValue(newString);
        onChange && onChange(newNumber);
        onBlur && onBlur(newNumber);
      }}
      changeOnDebounce={false}
    >
      {({ value, onChange, onBlur }) => (
        <Input
          type="number"
          step="0.01"
          placeholder={placeholder !== undefined ? placeholder : '0.00'}
          {...getV5Props(rest)}
          value={value}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          data-order={props['data-order']}
          role={props.role}
        />
      )}
    </InlineField>
  );
}

export default CurrencyAmountInput;
