import { cva } from 'class-variance-authority';
import React from 'react';
import { inputVariants } from 'v5/design-sytem/Input';
import { cn } from 'v5/platform/dom/cn';
import { getV5Props, V1Appearance, V1Size } from '../../GetV5Props';
import TextInputGroupOptions from './TextInputGroupOptions/TextInputGroupOptions';

export interface PendType {
  icon?: string;
  text?: string;
  valueKey?: string;
  options?: Array<string | Record<string, any>>;
  onClick?: () => void;
}

export interface TextInputGroupPendProps {
  type: 'prepend' | 'append';
  appearance?: V1Appearance;
  size?: V1Size;
  value?: Record<string, any>;
  disabled?: boolean;
  pend: PendType;
  onChange?: (valueKey: string, val: any) => void;
  className?: string;
}

const contentSlotVariants = cva(['leading-base w-fit items-center shrink-0'], {
  variants: {
    size: {
      XS: 'px-1.5 [&_svg,img]:size-3',
      S: 'px-2.5 [&_svg,img]:size-4',
      M: 'px-3 [&_svg,img]:size-5',
      L: 'px-4 [&_svg,img]:size-6',
      XL: 'px-4.5 [&_svg,img]:size-7'
    },
    appearance: {
      outline: '',
      underline: 'pl-0',
      silent: 'pl-0'
    }
  }
});

export function TextInputGroupPend({
  type,
  appearance,
  size,
  value,
  pend,
  disabled,
  onChange,
  className,
  ...rest
}: TextInputGroupPendProps) {
  if (pend.options) {
    return (
      <TextInputGroupOptions
        className={cn('pr-1', className)}
        aria-label={pend.valueKey}
        appearance={appearance}
        size={size}
        type={type}
        value={value?.[pend.valueKey || ''] || ''}
        // @ts-expect-error ignore for now
        options={pend.options}
        disabled={disabled}
        onChange={val =>
          pend.valueKey && onChange && onChange(pend.valueKey, val)
        }
        {...rest}
      />
    );
  }

  return (
    <div
      className={cn([
        'px-2',
        inputVariants({
          ...getV5Props({ appearance, size })
        }),
        contentSlotVariants({ appearance, size }),
        className
      ])}
      onClick={!disabled ? pend.onClick : undefined}
      {...rest}
    >
      {pend.icon && <img src={pend.icon} alt="" />}
      <span>{pend.text}</span>
    </div>
  );
}
