import { maybe, rd } from '@passionware/monads';
import { createSimpleStore, useSimpleStore } from '@passionware/simple-store';
import {
  FeatureFlagValues,
  FeatureFlagWritableSource,
  FeatureGroupDefinition,
  FeatureName
} from '../feature-management';

export type FeatureCleanupVersionMap<
  Definition extends FeatureGroupDefinition
> = Partial<Record<FeatureName<Definition>, string>>;

export function createLocalStorageFeatureFlags<
  Definition extends FeatureGroupDefinition
>(
  baseKey: string,
  definition: Definition,
  resetCleanupMap: FeatureCleanupVersionMap<Definition>
): FeatureFlagWritableSource<Definition> {
  /**
   * for each flag, we check if the stored version matches the default version
   */
  function initialize() {
    const definedFlags: Partial<FeatureFlagValues<Definition>> = {};
    definition.groups.forEach(group => {
      group.entries.forEach(entry => {
        const storedVersion = localStorage.getItem(`${baseKey}.${entry.id}`);
        if (typeof storedVersion === 'string') {
          const [cleanupId, version] = storedVersion.split(':');
          if (
            entry.id in resetCleanupMap &&
            cleanupId !== resetCleanupMap[entry.id]
          ) {
            // we clean the flag as the cleanupId is different, and from now, this source does not define the flag
            localStorage.removeItem(`${baseKey}.${entry.id}`);
          } else {
            function getValue() {
              switch (entry.flagSpec.type) {
                case 'boolean':
                  return version === 'true';
                case 'enum':
                  return version;
              }
            }
            definedFlags[entry.id] = getValue();
          }
        }
      });
    });
    return rd.of(definedFlags);
  }

  const flags = createSimpleStore(initialize());

  return {
    useFeatureFlags: () => useSimpleStore(flags),
    save: async config => {
      Object.entries(config).forEach(([featureId, value]) => {
        const cleanupId = resetCleanupMap[featureId as FeatureName<Definition>];
        if (cleanupId) {
          if (maybe.isAbsent(value)) {
            localStorage.removeItem(`${baseKey}.${featureId}`);
          } else {
            localStorage.setItem(
              `${baseKey}.${featureId}`,
              `${cleanupId}:${value}`
            );
          }
        } else {
          throw new Error(
            `No cleanupId found for ${featureId} - cannot save local storage flag`
          );
        }
      });
      flags.setNewValue(initialize());
      return {
        requiresFullReload: false
      };
    }
  };
}
