import { rd } from '@passionware/monads';
import { keyBy, mapValues } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ApiClient } from '../../../../../lib/api/ApiClient.types';
import {
  selectActiveTeam,
  selectTeamsStore,
  TEAMS_ACTIONS
} from '../../../../../store/teams';
import { selectAppId } from '../../../../../store/v1/auth/auth.selectors';
import { AtellioFeatureGroupDefinition } from '../../../../features';
import { FeatureFlagWritableSource } from '../../../../platform/feature-management/feature-management';
import { assert } from '../../../../platform/lang/assert';

export function createServerFeatureFlagSource(
  client: ApiClient,
  store: typeof import('../../../../../store').default
): FeatureFlagWritableSource<AtellioFeatureGroupDefinition> {
  return {
    useFeatureFlags: () => {
      const team = useSelector(selectActiveTeam);
      const appId = useSelector(selectAppId);
      const teamsStore = useSelector(selectTeamsStore);
      return useMemo(() => {
        if (teamsStore.error) {
          return rd.ofError(teamsStore.error);
        }
        if (teamsStore.loading === 'teams/getAccountTeams') {
          return rd.ofPending();
        }
        if (!teamsStore.fetched) {
          return rd.ofIdle();
        }
        if (!appId) {
          return rd.ofIdle();
        }
        if (!team) {
          return rd.ofError(new Error('No active team found'));
        }
        return rd.of(mapValues(keyBy(team.featureFlags), () => true));
      }, [team, teamsStore]);
    },
    save: async valuesToOverride => {
      const team = selectActiveTeam(store.getState());
      if (!team) {
        throw new Error('No active team found');
      }

      let allFlags: string[] = [...team.featureFlags];

      Object.entries(valuesToOverride).forEach(([key, value]) => {
        if (value) {
          // it means we want to keep the flag
          if (!allFlags.includes(key)) {
            allFlags.push(key);
          }
        } else {
          // it means we want to remove the flag
          allFlags = allFlags.filter(existingFlag => existingFlag !== key);
        }
      });

      await client.put(`/auth/tenant/teams/${team.dbSchema}/feature-flags`, {
        token: true,
        isV2: true,
        data: {
          feature_flags: allFlags
        }
      });

      store.dispatch({
        type: TEAMS_ACTIONS.updateFeatureFlags,
        payload: {
          teamId: team.id,
          featureFlags: allFlags
        }
      });

      return {
        requiresFullReload: false // todo check if we need to reload
      };
    }
  };
}
