import React from 'react';
import { rd } from '@passionware/monads';
import { createMatcher } from '@passionware/query-toolkit';
import { ItemSelector } from '../../../../../../v5/common-widgets/ItemSelector';
import { useSelector } from 'react-redux';
import { injectConfig } from '@passionware/platform-react';
import { selectAppId } from '../../../../../../store/v1/auth/auth.selectors';
import { withinSettings } from '../../../../../../v4/core/appRoutes';
import { Link } from 'react-router-dom';

export const SlotCategoryDropdownField = injectConfig(ItemSelector)
  .fromProps<{
    options: {
      id: number;
      name: string;
      order: number | null;
      legacy_user_id: string | null;
      created_mechanism: string | null;
      created_at: string;
      updated_at: string;
      archived: boolean;
      slot_category_type: 'RESOURCE_SLOT' | 'EXPANSE';
      user_id: string;
    }[];
  }>(api => ({
    useOptions: query => {
      const { options } = api.useProps();
      return rd.of(
        options
          .filter(createMatcher(query, x => x.name))
          .map(x => ({ id: x.id.toString(), label: x.name }))
      );
    },
    useEmptyContent: query => {
      const app_id = useSelector(selectAppId);
      const to = withinSettings(app_id);
      return (
        <div className="p-5">
          <h5 className="text-md font-semibold mb-4">
            No categories named "{query}"
          </h5>
          <p>You can configure your categories within</p>
          {/* @ts-ignore */}
          <Link
            to={to.settings.team.slotCategories()}
            className="outline-none border-0 p-0 font-semibold inline-flex items-center cursor-pointer transition-all duration-300 bg-transparent hover:underline group"
            target="_blank"
          >
            Settings
            <img
              className="ml-2 opacity-50 transition-all duration-200 group-hover:ml-[10px] group-hover:opacity-80"
              src="/images/arrow_right.svg"
              width="10px"
              alt=""
            />
          </Link>
        </div>
      );
    }
  }))
  .transformProps(x => x.skipFields('options'));
