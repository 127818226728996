import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { v4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import EventDateRangeInput, {
  TypeEvent
} from './EventDateRangeInput/EventDateRangeInput';

// TODO: would be good to review these sizes & bring them in line with our other components
const EventDateRange = ({
  className,
  isReadOnly,
  appearance = 'silent',
  size = 'L',
  data,
  onChange,
  eventType,
  labelStart,
  labelEnd,
  limits,
  filterDate,
  allowEmpty = true,
  allowMultiple = true,
  highlightDates
}) => {
  // useEffect(_ => {
  //   Array.isArray(data) &&
  //     onChange &&
  //     onChange(data.map(date => ({ id: date.id || `temp-${v4()}`, ...date })));
  // }, []);

  const { t } = useTranslation('v1_shared');

  const effectiveLabelStart =
    labelStart || t('EventDateRange.labelStart', { defaultValue: 'Starts' });
  const effectiveLabelEnd =
    labelEnd || t('EventDateRange.labelEnd', { defaultValue: 'Ends' });

  const handleAdd = _ => {
    const nextDate = data.length
      ? moment(data[data.length - 1].end_date)
          .add(1, 'd')
          .format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
    const nextTimestamp =
      data.length && data[data.length - 1].end_timestamp
        ? moment(data[data.length - 1].end_timestamp)
            .add(1, 'd')
            .format()
        : moment().format();
    const nextEvent = {
      id: `temp-${v4()}`,
      start_date: nextDate,
      end_date: nextDate,
      estimated_start_time: '09:00:00',
      estimated_end_time: '17:00:00',
      date_type: data.length
        ? data[data.length - 1].date_type
        : 'RECURRING_DEFAULT',
      start_timestamp: nextTimestamp,
      end_timestamp: nextTimestamp,
      event_type: eventType
    };
    onChange([...data, nextEvent]);
  };

  const handleChange = event => {
    Array.isArray(data)
      ? onChange(data.map(d => (d.id === event.id ? event : d)))
      : onChange(event);
  };

  const handleRemove = id => {
    !isReadOnly && onChange(data.filter(d => d.id !== id));
  };

  const allowRemove = () => {
    if (!allowEmpty && data.length <= 1) {
      return false;
    }
    return true;
  };

  const renderRow = event => (
    <EventDateRangeInput
      key={event && event.id}
      event={event}
      size={size}
      appearance={appearance}
      isReadOnly={isReadOnly}
      onChange={value => handleChange(value)}
      onRemove={
        Array.isArray(data) && allowRemove()
          ? () => handleRemove(event.id)
          : undefined
      }
      labelStart={effectiveLabelStart}
      labelEnd={effectiveLabelEnd}
      limits={limits}
      filterDate={filterDate}
      highlightDates={highlightDates}
    />
  );

  return (
    <div className={classnames(['EventDateRange', className])}>
      {Array.isArray(data) ? (
        <>
          {data.map(event => renderRow(event))}
          {!isReadOnly && allowMultiple && (
            <span
              className="EventDateRange-addAnotherBtn btn-small-link link-with-icon"
              onClick={handleAdd}
            >
              <img src="/images/icon_add_gray.svg" alt="" />{' '}
              {t('EventDateRange.addAnotherDate', {
                defaultValue: 'Add another date'
              })}
            </span>
          )}
        </>
      ) : (
        renderRow(data)
      )}
    </div>
  );
};

EventDateRange.propTypes = {
  className: PropTypes.string,
  isReadOnly: PropTypes.bool,
  size: PropTypes.oneOf(['XS', 'S', 'M']),
  eventType: PropTypes.string, // 'event_type' - E.g. 'PRODUCTION'
  data: PropTypes.oneOfType([
    // Pass object for single, array for multiple
    TypeEvent,
    PropTypes.arrayOf(TypeEvent)
  ]),
  labelStart: PropTypes.string,
  labelEnd: PropTypes.string,
  onChange: PropTypes.func,
  limits: PropTypes.shape({
    minDate: PropTypes.object,
    maxDate: PropTypes.object
  }),
  allowEmpty: PropTypes.any,
  allowMultiple: PropTypes.bool,
  filterDate: PropTypes.func,
  highlightDates: PropTypes.array
};

export default EventDateRange;
