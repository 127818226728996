import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  FileUploader,
  GoogleDriveUploader,
  LinkUploader
} from 'v1/components/shared';

import classnames from 'classnames';

import './MultiMediaUploader.scss';
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'v5/design-sytem/Popover';
import { Button } from 'v5/design-sytem/Button';
import { Plus } from 'lucide-react';
import { inputVariants } from 'v5/design-sytem/Input.js';

const UPLOAD_METHODS = {
  computer: {
    text: 'Computer',
    icon: '/images/icon_computer_upload.svg'
  },
  url: {
    text: 'Link (URL)',
    icon: '/images/icon_share_link.svg'
  },
  google: {
    text: 'Google Drive',
    icon: '/images/icon_upload_gdrive.svg'
  },
  dropbox: {
    text: 'Dropbox',
    icon: '/images/icon_upload_gdrive.svg'
  }
};

export class MultiMediaUploader extends Component {
  static propTypes = {
    secure: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      uploadType: props.uploadMethods[0]
    };
  }
  renderUploadTab = () => {
    const { uploadMethods } = this.props;

    const uploadTabs = uploadMethods.map(method => {
      return (
        <div
          key={method}
          onClick={() => {
            this.setState({ uploadType: method });
          }}
          className={classnames({ active: this.state.uploadType === method }, [
            'MultiMediaUploader-tab'
          ])}
        >
          <span className="MultiMediaUploader-tab-icon">
            <img src={UPLOAD_METHODS[method].icon} width="15px" alt="" />
          </span>
          <span className="MultiMediaUploader-tab-label">
            {UPLOAD_METHODS[method].text}
          </span>
        </div>
      );
    });
    return <div className="MultiMediaUploader-tabs">{uploadTabs}</div>;
  };
  renderUploadType = () => {
    let uploader;
    switch (this.state.uploadType) {
      case 'url':
        uploader = (
          <LinkUploader
            uploading={this.props.uploading}
            label="Paste a link to upload"
            placeholder="http://"
            className="MultiMediaUploader-url"
            onLinkUpload={({ provider, files }) =>
              this.props.onUpload('URL', {
                url: files && files[0],
                provider: provider
              })
            }
            inputClassName={inputVariants({ variant: 'regular', size: 'md' })}
          />
        );
        break;
      case 'computer':
        uploader = (
          <FileUploader
            className="MultiMediaUploader-computer"
            onDrop={this.props.onFileUpload}
            onChange={this.props.onFileUpload}
            onFileCompleted={this.props.onFileCompleted}
            secure={this.props.secure}
            multiple
          />
        );
        break;
      case 'google':
        uploader = (
          <GoogleDriveUploader
            onSelect={file => {
              this.props.onUpload('GOOGLE', file);
            }}
            className="MultiMediaUploader-provider"
            initiateOnMount
          />
        );
        break;
      default:
        uploader = <span />;
    }
    return <div className="MultiMediaUploader-uploadType">{uploader}</div>;
  };
  renderToggle = () => {
    const { buttonLabel, buttonClass, showIcon } = this.props;
    return (
      <Button
        variant="success"
        size="sm"
        dataCy="add-media"
        className={buttonClass}
      >
        {showIcon && <Plus />}
        <div>{buttonLabel || 'Add'}</div>
      </Button>
    );
  };
  renderHeader = () => {
    const { title } = this.props;

    if (title) {
      return (
        <div className="p-2 pl-4 flex justify-between items-center mb-2 border-b border-border-default">
          <h5 className="">{title}</h5>
          <div className="">
            <Button
              variant="primaryAlt"
              size="sm"
              data-cy="button-uploader-done"
              onClick={() => {
                this.setState({
                  showMediaUpload: !this.state.showMediaUpload
                });
              }}
            >
              Done
            </Button>
          </div>
        </div>
      );
    }
  };
  render() {
    return (
      <div className="MultiMediaUploader tailwind-root">
        <Popover
          open={this.state.showMediaUpload}
          onOpenChange={open => {
            this.setState({ showMediaUpload: open });
          }}
        >
          <PopoverTrigger asChild>{this.renderToggle()}</PopoverTrigger>
          <PopoverContent className="p-0 min-w-96" align="start">
            {this.renderHeader()}
            {this.renderUploadTab()}
            <div className="">{this.renderUploadType()}</div>
          </PopoverContent>
        </Popover>
      </div>
    );
  }
}

export default MultiMediaUploader;
