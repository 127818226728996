import React, { ComponentProps } from 'react';

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from 'v5/design-sytem/Select';
import { cn } from 'v5/platform/dom/cn';
import { Overwrite } from 'v5/platform/typescript';
import { getV5Props, V1Appearance, V1Size } from '../../../GetV5Props';

interface OptionObject {
  label: string;
  value: any;
}

type Option = string | OptionObject;

type TextInputGroupOptionsProps = Overwrite<
  ComponentProps<'button'>,
  {
    appearance?: V1Appearance;
    size?: V1Size;
    type: 'prepend' | 'append'; // Whether the dropdown is before or after the text input
    options?: Option[];
    onChange: (value: any) => void;
  }
>;

function TextInputGroupOptions({
  type,
  options = [],
  onChange,
  value,
  className,
  ...rest
}: TextInputGroupOptionsProps) {
  return (
    <Select onValueChange={onChange} value={String(value)}>
      <SelectTrigger
        {...rest}
        {...getV5Props(rest)}
        className={cn('w-40', className)}
      >
        <SelectValue placeholder="Select" />
      </SelectTrigger>
      <SelectContent>
        {options.map((option, index) => {
          const optionValue =
            typeof option === 'object' ? option.value : option;
          const optionLabel =
            typeof option === 'object' ? option.label : option;
          return (
            <SelectItem key={optionValue || index} value={String(optionValue)}>
              {optionLabel}
            </SelectItem>
          );
        })}
      </SelectContent>
    </Select>
  );
}

export default TextInputGroupOptions;
